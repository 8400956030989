import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import JsFileDownloader from "js-file-downloader";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";
interface WorkOrder {
  id: number;
  work_needed: string;
  description: string;
  status: string;
  requested_by: string;
}

interface InvoiceDetail {
  id: number;
  invoice_date: string;
  due_date: string;
  price: string;
  taxes: string;
  property_name: string;
  work_order: WorkOrder;
}

interface InvoiceAttributes {
  invoice_detail: InvoiceDetail;
  invoice_pdf_url: string;
}

interface Invoice {
  id: string;
  type: string;
  attributes: InvoiceAttributes;
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  activeVendorItem: string;
  openVendorDrawer: boolean;
  startDate: Date | null;
  endDate: Date | null;
  startDateOpen: boolean;
  endDateOpen: boolean;
  searchInput: string;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  invoices: Invoice[];
  startDateError: string,
  endDateError: string, 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  invoiceApiCallId: string | Message = ""; 
  invoiceSearchApiCallId: string|Message="";

  async componentDidMount() { 
    this.getInvoicelist();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      activeVendorItem: "InvoiceBilling",
      openVendorDrawer: false,
      startDateOpen:false,
      endDateOpen:false,
      startDate:null,
      endDate:null,
      searchInput: "",
      invoices:[],
      messageOpen: false,
      messageType: "success",
      message: "",
      startDateError: "", 
      endDateError: "", 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let errorResponse1 = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.invoiceApiCallId) {
      if (!responseJson.errors && responseJson) {
        this.setState({
          invoices: responseJson.data, 
        });
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
        });
      }
      };
      if (apiRequestCallId === this.invoiceSearchApiCallId) {
        if (!responseJson.errors && responseJson) {
          this.setState({
            invoices: responseJson.data, 
          });
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors
          });
        }
        };
      
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pdfApiCallId !== null &&
      this.pdfApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
    
      if (responseJson && responseJson.invoice) {
        this.setState({
          invoicePdf: responseJson.invoice,
          loading: false,
        });
        window.open(responseJson.invoice);
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse1);
    }
    // Customizable Area End
  }

  // Customizable Area Start

      onChangeInvoice = (text: string) => {
          if (text === "") {
            this.setState({
              invoice: text,
              invoiceError: configJSON.invoiceValidation,
            });
          } else {
            this.setState({ invoice: text, invoiceError: "" });
          }

        };

       
        handleVendoritemClick = (vendorKey: string) => {
          this.setState({ activeVendorItem: vendorKey }, () => {
            this.handleNavigationMenu();
          });
        };
        handleNavigationMenu = () => {
          const { activeVendorItem } = this.state;
          const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
          toMsgWork.addData(
            getName(MessageEnum.NavigationTargetMessage),
            activeVendorItem
          );
          toMsgWork.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          this.send(toMsgWork);
        };
        handleVendorDrawer = () => {
            this.setState({
                openVendorDrawer: !this.state.openVendorDrawer
            });
        };
        handleSearch = async() => {
          const token = await getStorageData("authToken")
          const header = {
            token: token,
          };
          const url = `${configJSON.getInvoiceListAPIEndPoint}?property_name=${this.state.searchInput}`;

          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
          );
          this.invoiceSearchApiCallId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
         
          runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      handleFilter = async () => {
        const { startDate, endDate, searchInput } = this.state;
        const formatDate = (date:Date) => {
          if (!date) return null;
          const d = new Date(date);
          const day = ('0' + d.getDate()).slice(-2);
          const month = ('0' + (d.getMonth() + 1)).slice(-2);
          const year = d.getFullYear();
          return `${day}-${month}-${year}`;
        };
        const formattedStartDate = startDate ? formatDate(startDate) : null;
        const formattedEndDate = endDate ? formatDate(endDate) : null;
        const searchUrl = `${configJSON.getInvoiceListAPIEndPoint}?property_name=${searchInput || ''}&start_date=${formattedStartDate || ''}&end_date=${formattedEndDate || ''}`;
        const token = await getStorageData("authToken");
        const header = {
          token: token,
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.invoiceSearchApiCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          searchUrl
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      
      handleOpen = (field: string) => {
        if (field === "startDate") {
            this.setState({ startDateOpen: true, endDateOpen: false });
        } else if (field === "endDate") {
            this.setState({ startDateOpen: false, endDateOpen: true });
        }
    };
    handleDateChange = (newDate: Date | null, field: string) => {
      if (newDate) {
        if (field === "startDate") {
          this.setState({
            startDate: newDate,
            endDateError: "", 
          });
        } else if (field === "endDate") {
          if (this.state.startDate && newDate <= this.state.startDate) {
            this.setState({
              endDate: newDate,
              endDateError: "End date should be greater than start date",
            });
          } else {
            this.setState({
              endDate: newDate,
              endDateError: "",
            });
          }
        }
      }
    };
    handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
     this.setState({
      searchInput:event.target.value
     })
     this.handleSearch();  
    };
    handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
      const sortedInvoices = [...this.state.invoices].sort((a: any, b: any) => {
        let valueA, valueB;
        if (property === "invoice_id") {
          valueA = parseInt(a.id);  
          valueB = parseInt(b.id);  
        } else if (property === "work_needed") {
          valueA = a.attributes.invoice_detail.work_order.work_needed;
          valueB = b.attributes.invoice_detail.work_order.work_needed;
        } else if (property === "category") {
          valueA = a.attributes.invoice_detail.work_order.description ;
          valueB = b.attributes.invoice_detail.work_order.description ;
        } else if (property === "price") {
          valueA = parseFloat(a.attributes.invoice_detail.price) ;
          valueB = parseFloat(b.attributes.invoice_detail.price) ;
        } else if (property === "invoice_date") {
          valueA = new Date(a.attributes.invoice_detail.invoice_date).getTime();
          valueB = new Date(b.attributes.invoice_detail.invoice_date).getTime();
        } else if (property === "due_date") {
          valueA = new Date(a.attributes.invoice_detail.due_date).getTime();
          valueB = new Date(b.attributes.invoice_detail.due_date).getTime();
        }  else {
          valueA = a.attributes.invoice_detail[property];
          valueB = b.attributes.invoice_detail[property];
        }
        if (typeof valueA === "string" && typeof valueB === "string") {
          if (direction === "asc") {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        }
        if (direction === "asc") {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      });
      this.setState({
        invoices: sortedInvoices,
      });
    };
     getInvoicelist = async () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.invoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInvoiceListAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   };
   handleDownload = async (pdfUrl: string, filename: string) => { 
    try {
      await new JsFileDownloader({
        url: pdfUrl,
        filename
      });
    } catch (error) {
      alert("Failed to download file");
    }
  };  
  // Customizable Area End
}
