// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import JsFileDownloader from "js-file-downloader";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisibleModal: boolean;
  downloadData: { [keyData: string]: string }[];
  activeTenantItem:string;
  openTenantDrawer: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  numberOfOccupants: number;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  leaseStartDate:string,
  leaseEndDate:string,
  leaseaddress:string,
  landlordName :string,
  landlordAddress:string,
  landlordNumber:string,
  rentalFirstPayment:string,
  rentalPayPeriod:string,
  rentalAmount:string,
  rentalDeposit:string,
  rentalPaymentMethod:string,
  collectedBy:string,
  propertyName:string,
  propertyUnit:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadOptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApicallData: string = "";
  getLeaseDataApiCallId:string="";
  downloadPdfApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVisibleModal: false,
      downloadData: [],
      activeTenantItem:"DownloadOptions",
      openTenantDrawer: false,
      name: "",
      email: "",
      phoneNumber: "",
      numberOfOccupants: 0,
      messageOpen: false,
      messageType: "success",
      message: "",
      leaseStartDate:"",
      leaseEndDate:"",
      leaseaddress:"",
      landlordName :"",
      landlordAddress:"",
      landlordNumber:"",
      rentalFirstPayment:"",
      rentalPayPeriod:"",
      rentalAmount:"",
      rentalDeposit:"",
      rentalPaymentMethod:"",
      collectedBy:"",
      propertyName:"",
      propertyUnit:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getUserDocumentsData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getApicallData) {
        if (!responseJson.errors) {
        }        
      }
      if(apiRequestCallId == this.getLeaseDataApiCallId){
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        if (!responseJson.errors && responseJson) {
          const tenantInfo = responseJson.data.attributes.tenant_information;
          const landlordInformation =responseJson.data.attributes.landlord_information;
          const rentalInformation =responseJson.data.attributes.rental_information;
          const leaseDetail =responseJson.data.attributes.lease_detail;
          const property =responseJson.data.attributes.property;
        
          this.setState({
            leaseStartDate:leaseDetail.lease_start_date ||"",
            leaseEndDate:leaseDetail.lease_end_date || "",
            leaseaddress:leaseDetail.address || "",
            landlordName :landlordInformation.name|| "",
            landlordAddress:landlordInformation.address|| "",
            landlordNumber:landlordInformation.phone|| "",
            name: tenantInfo.name || "",
            email: tenantInfo.email || "",
            phoneNumber: tenantInfo.phone || "",
            numberOfOccupants: tenantInfo.number_of_occupants || 0,
            rentalFirstPayment:rentalInformation.date_of_first_payment_due||"",
            rentalPayPeriod:rentalInformation.pay_period||"",
            rentalAmount:rentalInformation.rent_amount_per_month||"",
            rentalDeposit:rentalInformation.security_deposit||"",
            rentalPaymentMethod:rentalInformation.payment_method||"",
            collectedBy:rentalInformation.collected_by||"",
            propertyName:property.name||"",
            propertyUnit:property.unit||"",
          }); 
       
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors
          });
        }
      }
      if(apiRequestCallId == this.downloadPdfApiCallId){
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        this.handleDownload(responseJson.tenant_lease_pdf_url)
       if(!!responseJson){
        
       }
      }
    }

    // Customizable Area End
  }

  // web events
  // Customizable Area Start

  navigateToChat = () => {
    this.props.navigation.navigate("Chat");
  };

  getUserDocumentsData() {
    this.getLeaseData();
  }

  async handleDownload(imageUrl: string) {
    try {
      new JsFileDownloader({
        url: imageUrl,
      });
    } catch (error) {}
  }
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeTenantItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeTenantItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeTenantItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleTenantDrawer = () => {
    this.setState({
        openTenantDrawer: !this.state.openTenantDrawer
    });
};
  getLeaseData = async  () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLeaseDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLeaseDataApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }; 
   handleDownload1 = async  () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadPdfApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDownloadPdfApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
