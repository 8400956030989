import React from "react";
// Customizable Area Start
import { ReactNode } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputBase,
  FormControl,
  Stepper,
  Select,
  MenuItem,
  Step,
  Grid,
  Autocomplete,
  Chip,
  StepLabel,
  OutlinedInput,
  IconButton,
  InputLabel,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import FileUpload from "../../accountgroups/src/FileUpload";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarToday } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import PopupMessage from "../../../components/src/PopupMessage.web";
import {documentActive,documents } from '../../automaticreminders/src/assets';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

import { styled } from "@mui/system";
const searchSearch = require("../../portfoliomanagement/assets/Search.png");
const DefaultIcon= require("../../settings2/assets/user.png");
const deleteIcon = require("../../settings2/assets/cross.svg");
const filterIcon = require("../../settings2/assets/filter.svg");
const removeIcon= require("../../settings2/assets/remove.svg");
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeProjects = require("../../customform/assets/activeprojects.png");
export const serviceInactive4 = require("../../appointmentmanagement/assets/serviceRequest.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
export const serviceActive4 = require("../../appointmentmanagement/assets/service.png");
import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

const steps = [
  "Account settings",
  "Bank information",
];
interface StepIconProps {
  active: boolean;
  completed: boolean;
  icon: React.ReactNode;
}

const MainBox = ({ left, right }: { left: ReactNode; right: ReactNode }) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #D1CBC7",
          marginBottom:  "30px",
          marginTop:  "40px",
          "@media(max-width: 992px)": {
            marginBottom: "15px",
            marginTop: "20px",
          }
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "row",
       "@media(max-width: 992px)": {
        flexDirection: "column",
      }
     }}>
        <Box
          sx={{
            width: "35%",
            marginLeft: "50px",
            height: "100%",
            marginBottom: "8px" ,
            "@media(max-width: 992px)": {
              width: "100%",
              marginLeft: "0px",
              marginBottom: "24px",
          }
          }}
        >
          {left}
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            height: "100%",
          }}
        >
          {right}
        </Box>
      </Box>
    </>
  );
};
const CustomStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: ownerState.active ? "#CC9200" : "#eaeaf0",
  color: ownerState.active ? "#fff" : "#000",
  display: "flex",
  height: 32,
  width: 32,
  marginRight: 10,
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Outfit",
  ...(ownerState.completed && {
    backgroundColor: "#00e676",
    color: "#fff",
  }),
}));
function CustomStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {completed ? <Check /> : icon}
    </CustomStepIconRoot>
  );
}
const InputHead = {
  color: "#000000",
  display: "inline",
  fontWeight: "700",
  fontSize: "14px",
  fontFamily: 'Outfit',
};
const communicationOptions = [
  "Email",
  "Phone Call",
  "Text",
  "App Notification",
];
const leftBoxStyles = {
  width: "35%" ,
  marginLeft: "50px",
  height: "100%",
  marginBottom: "8px",
  "@media(max-width: 992px)": {
    width: "100%",
    marginLeft: "0px",
    marginBottom: "24px",
  }
};

const rightBoxStyles = {
  width: "60%",
  height: "100%",
  "@media(max-width: 992px)": {
    width: "100%",
  }
};

const line = {
  display: "flex",
  flexDirection: "row",
  "@media(max-width: 992px)": {
    flexDirection: "column",
  }
};
const options = [
  { title: '12431 BLV' },
  { title: 'HILL TOP' },
  { title: 'Option 3' },
  { title: 'Option 4' },
];
const CustomStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    fontWeight: 700,
    fontSize: "16px",
    "&.Mui-active": {
      fontfamily:"Outfit",
      fontWeight: 700,
      fontSize: "16px",
    },
    "&.Mui-completed": {
      fontWeight: 700,
      fontfamily:"Outfit",
      fontSize: "16px",
    },
  },
});

// Customizable Area End



export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  fileInput: HTMLInputElement | null = null;
  screenProps = {
    navigation: this.props.navigation,
    id: "/Settings2",
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const selectedValues = this.state.selectedOptions.map((option: { title: string }) => option.title);
    const profileImg = this.state.photo[0]?.preview || this.state.photoState || DefaultIcon;
    const countryOptionStyle = this.state.isCountryDropdownActive? "block" : "none";
    const countryValueStyle = this.state.isCountryDropdownActive? "none" : "block";
    return (
      <WorkOrderStyle>
        <NavArea className="landlordSettingsNavArea" sx={{"@media(max-width: 992px)": { display: "flex", "paddingTop": "24px",}}}>
         <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeMainItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.serviceOrder5, labelKey: configJSON.serviceLable5, icon: serviceInactive4, activeIcon:serviceActive4 , pathName: configJSON.serviceLable5 },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.document, labelKey: configJSON.documentlabel, icon: documents, activeIcon: documentActive, pathName: configJSON.documentlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
          ]}
          onClickSidebar={this.handleMainItemClick}
          keasylogo={keasyIcon}
          openDrawer={this.state.openLandlordDrawer}
          onClickDrawer={this.handleLandlordDrawer}
        />
        <Box className="landlordSettingsMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
        <CustomNavbar navigation={this.props.navigation} id="landlordSettingsNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>
        </NavArea>
            <Box
              sx={{
                width: "calc(100% - 120px)",
                marginLeft: "120px",
                paddingLeft: "30px",
                "@media (max-width: 992px)": {
                   width: "calc(100% - 32px)",
                   marginLeft: 0,
                   paddingLeft: "16px",
                   paddingRight: "16px",
                   },
              }}
            >
              <Box className="landlordSettingsNavbar" sx={{width:"100%", display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>

          <CustomNavbar 
              {...this.screenProps}
              showChatButton={true}   
              showPropertiesButton={true} 
            />
          </Box>
           
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              height: "auto",
              margin:  "10px auto 70px 0",
              "@media (max-width: 992px)": {
                margin: "0 0 24px 0"
              },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              fontWeight="700"
              color="#CC9200"
              sx={{ alignSelf: "flex-start", textAlign: "left", fontFamily: 'Outfit',marginTop:"1rem",
              width: { xs: '90%', sm: '46%' }}}
            >
              We are happy to have you as our Keasy member
            </Typography>

            <Box sx={{ textAlign: "left" }}>
              <Typography
                color="#70645C"
                fontSize="18px"
                sx={{ fontFamily: 'Outfit', width: { xs: '85%', sm: '46%' } }}
              >
                Please make sure all your details are correct and up to date so it will be keasier for us to reach you and let you know on any needed attention regarding your properties, vendors and tenets
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: '1rem',
                marginTop: '2.5rem',
                flexDirection: { xs: 'column', sm: 'row' }
              }} >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: 'column',
                  border: "1px solid #FFE299",
                  borderRadius: "0px, 0px, 14px, 0px",
                  padding: "20px",
                  maxWidth: "300px",
                  textAlign: "center",
                  gap: '1rem'
                }}>
                <Typography
                  color="#70645C"
                  fontSize="18px"
                  fontWeight="700"
                  sx={{ fontFamily: 'Outfit' }} >
                  Why isn’t my info shown here?
                </Typography>
                <Typography
                  color="#70645C"
                  fontWeight="400"
                  fontSize="18px"
                  sx={{ fontFamily: 'Outfit' }}>
                  We’re hiding some account details to protect your identity.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: 'column',
                  border: "1px solid #FFE299",
                  borderRadius: "0px, 0px, 14px, 0px",
                  padding: "20px",
                  maxWidth: "300px",
                  textAlign: "center",
                  gap: '1rem'
                }}>
                <Typography
                  color="#70645C"
                  fontSize="18px"
                  fontWeight="700"
                  sx={{ fontFamily: 'Outfit' }} >
                  Which details can be edited?
                </Typography>
                <Typography
                  color="#70645C"
                  fontWeight="400"
                  fontSize="18px"
                  sx={{ fontFamily: 'Outfit' }}>
                  Details Airbnb uses to verify your identity can’t be changed. Contact info and some personal details can be edited, but we may ask.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: 'column',
                  border: "1px solid #FFE299",
                  borderRadius: "0px, 0px, 14px, 0px",
                  padding: "20px",
                  maxWidth: "300px",
                  textAlign: "center",
                  gap: '1rem',
                }}>
                <Typography
                  color="#70645C"
                  fontSize="18px"
                  fontWeight="700"
                  sx={{ fontFamily: 'Outfit' }} >
                  What info is shared with others?
                </Typography>
                <Typography
                  color="#70645C"
                  fontWeight="400"
                  fontSize="18px"
                  sx={{ fontFamily: 'Outfit' }}>
                  Airbnb only releases contact information for Hosts and guests after a reservation is confirmed.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ 
              width: {
                // xs: "85%",
                sm: "100%",
              },
              marginTop: {
                xs: "1rem",
                sm: "0", 
              },
          }}>
            <Stepper
              data-test-id="stepper"
              activeStep={this.state.activeStep}
              orientation="horizontal"
              sx={{
                display: "flex",
                marginLeft: "78px",
                marginRight: "48px",
                gap: "8rem",
                "@media (max-width: 992px)": {
                  marginLeft: 0,
                  marginRight: 0,
                },
                "& .MuiStepConnector-root": {
                  display: "none",
                },
                "@media (max-width: 600px)": {
                  gap: "1.5rem", 
                  "& .MuiStep-root": {
                    display: "flex", 
                    margin: "0", 
                    padding: "0",
                  },
                },
                "& .MuiStep-root": {
                  position: "relative",
                  "@media (min-width: 600px)": {
                    "&:not(:last-child)::after": {
                      content: '""',
                      position: "absolute",
                      right: "-4rem", 
                      top: "50%",
                      transform: "translateY(-50%)",
                      height: "4rem", 
                      borderRight: "1px solid #A3978F",
                    },
                  },
                },
              }}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};

                const steprwords = label.split(" ");
                return (
                  <Step key={label} {...stepProps}
                  sx={{
                    position: "relative",
                    "@media (min-width: 600px)": {
                      "&:not(:last-child)::after": {
                        content: '""',
                        position: "absolute",
                        right: "-4rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        height: "4rem", 
                        borderRight: "1px solid #A3978F",
                      },
                    },
                  }}
                  >
                    <CustomStepLabel
                      StepIconComponent={(props) => (
                        <CustomStepIcon {...props} />
                      )}
                    >
                      {steprwords.map((steprwords, i) => (
                        <span key={i} style={{ display: "block", fontFamily: "Outfit"}}>
                          {steprwords}
                        </span>
                      ))}
                    </CustomStepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box
              data-test-id="forms"
              component="form"
              onSubmit={
                this.state.activeStep === steps.length - 1
                  ? this.handleUpdate
                  : this.handleNext
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width:  "80%",
                // padding:  "0 70px",
                marginTop: "20px",
                marginBottom: "40px",
                // marginLeft: "70px",
                "@media (max-width: 992px)": {
                  width: "100%",
                  marginRight: 0,
                  padding: 0,
                  marginLeft: 0,
                },
                "& .MuiInputBase-root" : { "@media (max-width: 992px)": {height: "42px !important",},}
              }}
            >
              {this.state.activeStep === 0 && (
                <>
                 <MainBox
                  data-test-id="profile-upload-block"
                    left={
                      <InputLabel
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "bold",
                        }}
                      >
                        Your photo
                      </InputLabel>
                    }
                    right={
                      <Grid
                        xs={8}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                         <Box
                         sx={{
                          display:"flex",
                          width:"100%",
                          height:"100%",
                          justifyContent:"space-between"
                         }}>
                          <Box
                           sx={{
                            borderRadius: "50%", 
                            height:   "156px",
                            width:   "156px",
                            overflow:"hidden",
                            border: "4px solid #FFF1CC",
                            "@media (max-width: 992px)": {
                            width: "61px",
                            height: "61px",
                          },
                           }}>
                            <img src={profileImg}
                              id="profileImgPreview"
                              style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover", 
                              }}></img>
                          </Box>
                          <Box
                          sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"end",
                            flexDirection:"column"
                          }}>
                            <Box> <Button
                            data-test-id="profile-update"
                            sx={{
                                color:"#CC9200",
                                fontWeight: "700", 
                                fontSize: "16px",
                                fontFamily: 'Outfit',
                                textTransform: "Capitalize",
                                marginRight: 3,
                            }}
                            onClick={() => this.fileInput?.click()}
                            >Update
                            </Button>
                            <input
                                data-test-id="fileInput"
                                type="file"
                                multiple
                                accept=".pdf,.png,.jpeg,.jpg"
                                style={{ display: "none" }}
                                ref={(input) => (this.fileInput = input)}
                                onChange={(event) => {
                                  const selectedFiles = Array.from(event.target.files || []);
                                  const acceptedFileTypes = [
                                    "application/pdf",
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                  ];
                                  const acceptedFiles: File[] = selectedFiles.filter((file) =>
                                    acceptedFileTypes.includes(file.type)
                                  );
                                  const fileRejections = selectedFiles.filter(
                                    (file) =>
                                      !acceptedFileTypes.includes(file.type) ||
                                      file.size > 5242880
                                  );

                                  if (fileRejections.length > 0) {
                                    this.setState({
                                      error:null,
                                    });
                                  } else {
                                    this.setState({ error: null });
                                    const newFiles = acceptedFiles.map((file) =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                      })
                                    );
                                    this.setState({ photo: newFiles });
                                  }
                                }}
                              />
                            <Button
                              data-test-id="remove-button"
                              startIcon={<img src={removeIcon} alt="delete icon" />}
                              sx={{
                                color:"#CC9200",
                                fontWeight: "700", 
                                fontSize: "16px",
                                fontFamily: 'Outfit',
                                textTransform: "Capitalize",
                            }}
                            onClick={this.handleRemove}
                            >remove</Button>
                            </Box>
                          </Box>
                         </Box>
                         
                        </Grid>
                      </Grid>
                    }
                  />
                  <MainBox
                    data-test-id="full-Name-Block"
                    left={
                      <InputLabel
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "bold",
                        }}
                      >
                        Personal details
                      </InputLabel>
                    }
                    right={
                      <Grid
                        xs={8}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography sx={webStyles.header}>
                            Full Name
                          </Typography>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "1rem" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.full_name}
                            onChange={(e) =>
                              this.handleChange(
                                "full_name",
                                e.target.value
                              )
                            }
                            error={this.state.errors.fullName}
                            helperText={this.getHelperText("fullName")}
                            data-test-id="full_name"
                          />
                         
                        </Grid>
                        <Grid item xs={10}>
                          <Typography sx={webStyles.header}>
                           Birth Date 
                          </Typography>
                          <Box
                          sx={{
                            width:"100%",
                            marginBottom:"1rem",
                          }}>
                           <ClickAwayListener onClickAway={() => this.setState({ open: false })} mouseEvent="onMouseDown"  data-test-id="date_of_birth">
                            <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                              <CalendarToday
                                style={{
                                  position: "absolute",
                                  left: "10px",
                                  top: "50%", // Center vertically
                                  transform: "translateY(-50%)", // Adjust to center
                                  pointerEvents: "none",
                                  color: "#757575",
                                }}
                              />
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                 data-test-id="date_picker"
                                  value={this.state.date_of_birth}
                                  onChange={this.handleDateChange("date_of_birth")}
                                  open={this.state.open}
                                  maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                  disableFuture
                                  onClose={() => this.setState({ open: false })}
                                  renderInput={(params) => (
                                    <TextField
                                      data-test-id="date_picker_textfield"
                                      required={true}
                                      fullWidth
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { 
                                          borderRadius: 8, 
                                          paddingLeft: '40px' 
                                        },
                                      }}
                                      onClick={this.handleOpen}
                                      onTouchStart={this.handleOpen}
                                      inputProps={{
                                        ...params.inputProps,
                                        style: { 
                                          paddingLeft: '0px' 
                                        }
                                      }}
                                    />
                                  )}
                                  inputFormat="MM-dd-yyyy"
                                />
                              </LocalizationProvider>
                              <IconButton
                                onClick={this.handleIconClick}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  color: "#757575",
                                  backgroundColor: "white",
                                  height: "38px"
                                }}
                                data-test-id="icon_button"
                              >
                                <KeyboardArrowDownIcon/>
                              </IconButton>
                            </div>
                          </ClickAwayListener>
                          </Box>
                        
                        </Grid>
                        <Grid item xs={10}>
                          
                          <Typography sx={webStyles.header}>
                          Upload your government picture ID
                          </Typography>
                          <Box>
                          <FileUpload
                              label=""
                              name="government_picture"
                              files={this.state.government_picture}
                              onFilesChange={this.handleFilesChange}
                              data-test-id="government_picture"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  />
                  <MainBox
                   data-test-id="Contact-Name-Block"
                    left={
                      <InputLabel
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "700", 
                          fontSize: "18px",
                          fontFamily: 'Outfit',
                        }}
                      >
                        Contact information
                      </InputLabel>
                    }
                    right={
                      <Grid
                        xs={8}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography sx={webStyles.header}>
                           Email
                          </Typography>
                          <TextField
                            variant="outlined"
                            name="primary_contact_email"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "1rem" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.primary_contact_email}
                            onChange={(e) =>
                              this.handleChange(
                                "primary_contact_email",
                                e.target.value
                              )
                            }
                            error={this.state.errors.primary_contact_email}
                            helperText={this.getHelperText("email")}
                            data-test-id="primary_contact_email"
                          />
                         
                        </Grid>
                        <Grid  xs={10}
                        sx={{
                          display:"flex",
                          flexDirection:"column"
                        }}>
                          <Typography  sx={webStyles.header}>
                          Phone Number
                          </Typography>
                          <Box
                           sx={{
                            display:"flex",
                            gap:"1rem",
                            marginBottom: "1rem",
                            height: "60px" 
                          }}>
                              <Select
                                size="small"
                                value={this.state.countryCode}
                                onOpen={this.handleCountryCodeDropdown}
                                name="countryCode"
                                onClose={() => {this.setState({ isCountryDropdownActive: false })}}
                                id="countryCode"
                                sx={{
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "4px",
                                  height: "60px" 
                                }}
                                variant="outlined"
                                onChange={(e) =>
                                  this.handleCountryChange("countryCode", e.target.value)
                                }
                                data-test-id="countryCode"
                              >
                                <MenuItem value="">Select a country</MenuItem>

                                {this.state.countries?.map((country) => (
                                  <MenuItem key={country.name} value={country.country_code}>
                                    <label style={{ display: countryOptionStyle }}>
                                      {country.emoji_flag} {country.name} (+{country.country_code})

                                      </label>
                                    <label style={{ display: countryValueStyle }}>+{country.country_code}</label>
                                  </MenuItem>
                                ))}
                            </Select>
                              <TextField
                                 data-test-id="primary_contact_number"
                                  required
                                  InputProps={{
                                  style: {
                                    borderRadius: "8px",
                                  },
                                }}
                                variant="outlined"
                                value={this.state.primary_contact_number}
                                error={this.state.errors.primary_contact_number}
                                helperText={this.getHelperText("primary_contact_number")}
                                onChange={(e) =>
                                  this.handleChange(
                                    "primary_contact_number",
                                    e.target.value
                                  )
                                }
                                sx={{ height: "60px",
                                  "@media (max-width: 992px)": { width: '100%' }
                                 }}
                                />
                          </Box>
                            
                        </Grid>

                        <Grid item xs={10}>
                          <Typography  sx={webStyles.header}>
                          Address
                          </Typography>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.primary_contact_address}
                            onChange={(e) =>
                              this.handleChange(
                                "primary_contact_address",
                                e.target.value
                              )
                            }
                            data-test-id="primary_contact_address"
                          />
                         
                        </Grid>
                      </Grid>
                    }
                  />
                 <MainBox
                   data-test-id="Emergency-Name-Block"

                    left={
                      <InputLabel
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "700", 
                          fontSize: "18px",
                          fontFamily: 'Outfit',
                        }}
                      >
                        Emergency contact
                      </InputLabel>
                    }
                    right={
                      <Grid
                        xs={8}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography  sx={webStyles.header}>
                           Full Name
                          </Typography>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "1rem" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.secondary_contact_name}
                            onChange={(e) =>
                              this.handleChange(
                                "secondary_contact_name",
                                e.target.value
                              )
                            }
                            error={this.state.errors.EmergencyName}
                            helperText={this.getHelperText("EmergencyName")}
                            data-test-id="secondary_contact_name"
                          />
                         
                        </Grid>
                        <Grid item xs={10}>
                          <Typography  sx={webStyles.header}>
                           Email
                          </Typography>
                          <TextField
                            variant="outlined"
                            name="secondary_contact_email"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "1rem" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            error={this.state.errors.secondary_contact_email}
                            helperText={this.getHelperText("secondary_email")}
                            value={this.state.secondary_contact_email}
                            onChange={(e) =>
                              this.handleChange(
                                "secondary_contact_email",
                                e.target.value
                              )
                            }
                            data-test-id="secondary_contact_email"
                          />

                        </Grid>
                        <Grid item xs={10}>
                          <Typography  sx={webStyles.header}>
                          Phone Number
                          </Typography>
                             <Box
                           sx={{
                            display:"flex",
                            gap:"1rem",
                            marginBottom: "1rem",
                            height:"60px",
                          }}>
                              <Select
                                size="small"
                                required
                                name="secondary_countryCode"
                                onChange={(e) =>
                                  this.handleCountryChange("secondary_countryCode", e.target.value)
                                }
                                variant="outlined"
                                value={this.state.secondary_countryCode}
                                onOpen={this.handleCountryCodeDropdown}
                                onClose={() => {this.setState({ isCountryDropdownActive: false })}}
                               
                                sx={{
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "4px",
                                  height:"60px",
                                }}
                                id="secondary_countryCode"
                                data-test-id="secondary_countryCode"
                              >
                                <MenuItem value="">Select a country</MenuItem>
                                {this.state.countries?.map((country) => (
                                  <MenuItem key={country.name} value={country.country_code}>
                                    <span style={{ display: countryOptionStyle }}>
                                      {country.emoji_flag} {country.name} (+{country.country_code})
                                      </span>
                                    <span style={{ display: countryValueStyle }}>+{country.country_code}</span>
                                  </MenuItem>
                                ))}
                            </Select>
                              <TextField
                                 data-test-id="secondary_contact_number"
                                  required
                                  InputProps={{
                                  style: {
                                    borderRadius: "8px",
                                  },
                                }}
                                variant="outlined"
                                value={this.state.secondary_contact_number}
                                error={this.state.errors.secondary_contact_number}
                                helperText={this.getHelperText("secondary_contact_number")}
                                sx={{
                                 height:"60px",  '@media (max-width: 992px)': { width: '100%' }
                                }}
                                onChange={(e) =>
                                  this.handleChange(
                                    "secondary_contact_number",
                                    e.target.value
                                  )
                                }/>
                          </Box>
                        </Grid>

                        <Grid item xs={10}>
                          <Typography  sx={webStyles.header}>
                          Address
                          </Typography>
                          <TextField
                            variant="outlined"
                            name="secondary_contact_address"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "1rem" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.secondary_contact_address}
                            onChange={(e) =>
                              this.handleChange(
                                "secondary_contact_address",
                                e.target.value
                              )
                            }
                            data-test-id="secondary_contact_address"
                          />
                         
                        </Grid>
                       
                      </Grid>
                    }
                  />
                    <MainBox
                    data-test-id="communication-preferences-box"
                    left={
                      <Box
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "700", 
                          fontSize: "18px",
                          fontFamily: 'Outfit',
                          width:"35%"
                        }}
                      >
                        Preferred way of communication*
                      </Box>
                    }
                    right={
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                          flexDirection: "column",
                          alignSelf: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            marginRight: "8px",
                          }}
                        >
                          Contact me via:
                        </Typography>
                        <Typography>Need to select two</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {communicationOptions.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={this.state.primary_way_of_communication.includes(
                                  option
                                )}
                                onChange={
                                  this.handlePreferredCommunicationChange
                                }
                                value={option}
                                disabled={this.isCheckboxDisabled(option)}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#CC9200",
                                  },
                                }}
                              />
                            }
                            label={option}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        ))}
                      </Box>
                      <Typography
                       sx={{
                        color: "#000000",
                        display: "inline",
                        fontWeight: "400",
                        fontSize: "14px",
                        fontFamily: 'Outfit',
                      }}
                      >You are opting in to receive communications from us. We may use your provided contact information for emergencies, updates and promotions</Typography>
                      
                    </Box>
                    }
                  />
                </>
              )}

              {this.state.activeStep === 1 && (
                <Box data-test-id="step-outer">
                  <React.Fragment>
                    <MainBox
                    data-test-id="bank_name-box"
                      left={
                        <InputLabel
                          sx={{
                            color: "#BAB1AB",
                            fontWeight: "bold",
                          }}
                        >
                          Bank name
                        </InputLabel>
                      }
                      right={
                        <Grid
                          xs={8}
                          sx={{
                            "@media (max-width: 992px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <Grid item xs={10}>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.bank_name}
                              onChange={(e) =>
                                this.handleChange(
                                  "bank_name",
                                  e.target.value
                                )
                              }
                              data-test-id="bank_name"
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <MainBox
                    data-test-id="bank_routing-box"
                      left={
                        <InputLabel
                          sx={{
                            color: "#BAB1AB",
                            fontWeight: "bold",
                          }}
                        >
                          Bank Routing Number
                        </InputLabel>
                      }
                      right={
                        <Grid
                          xs={8}
                          sx={{
                            "@media (max-width: 992px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <Grid item xs={10}>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.bank_routing_number}
                              onChange={(e) =>
                                this.handleChange(
                                  "bank_routing_number",
                                  e.target.value
                                )
                              }
                              data-test-id="bank_routing_number"
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <MainBox
                      data-test-id="bank_account-box"
                      left={
                        <InputLabel
                          sx={{
                            color: "#BAB1AB",
                            fontWeight: "bold",
                          }}
                        >
                          Bank Account Number
                        </InputLabel>
                      }
                      right={
                        <Grid
                          xs={8}
                          sx={{
                            "@media (max-width: 992px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <Grid item xs={10}>
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.bank_account_number}
                              onChange={(e) =>
                                this.handleChange(
                                  "bank_account_number",
                                  e.target.value
                                )
                              }
                              data-test-id="bank_account_number"
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <MainBox
                      left={
                        <Box
                          sx={{
                            color: "#BAB1AB",
                            fontWeight: "700",
                            fontSize: "18px",
                            fontFamily: 'Outfit',
                            width: "45%"
                          }}
                        >
                          Please Upload a picture of the voided check
                        </Box>
                      }
                      right={
                        <Grid
                          xs={8}
                          sx={{
                            "@media (max-width: 992px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <Grid item xs={10}>
                            <FileUpload
                              label=""
                              name="voided_check"
                              files={this.state.voided_check}
                              onFilesChange={this.handleFilesChange}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <Box  sx={webStyles.header}/>
                    <Box sx={line}>
                      <Box sx={leftBoxStyles}>
                        <Typography
                          sx={{
                            color: "#BAB1AB",
                            fontWeight: "bold",
                            minWidth: "120px",
                          }}
                        >
                          Please accept
                        </Typography>
                      </Box>
                      <Box sx={rightBoxStyles}>
                        <FormControl data-test-id="confirmBankAccountDetails">
                          <FormControlLabel
                          data-test-id="confirmBankAccountlabel"
                            control={
                              <Checkbox
                                data-test-id="confirmBankAccountDetailsCheckbox"
                                checked={this.state.accept}
                                onChange={(event) => {
                                  this.setState({
                                    accept: event.target.checked,
                                  });
                                }}
                                sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                              />
                            }
                            label={
                              <div
                                style={{
                                  marginLeft: "23px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "I confirm that the details of bank account provided above are accurate and correct, and I authorize DANRON LLC (DBA – ARAMAIP) and / or AppFolio, Inc. to activate the above listed bank accounts in order to process payments through AppFolio Payment Services.",
                                }}
                              />
                            }
                            sx={{ alignItems: "flex-start" }}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                        marginTop: "54px",
                        justifyContent: "flex-end",
                        "@media (max-width: 992px)": {
                          marginTop:  "27px",
                          justifyContent:  "flex-start",
                        },
                      }}
                    >
                  <Box />
              </Box>
                  </React.Fragment>
                </Box>
              )}
              <Box
                 data-test-id="button-box"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  marginTop: "54px", 
                  justifyContent: "flex-end",
                  "@media (max-width: 992px)": {
                     marginTop:  "27px",
                     justifyContent:  "flex-start",
                  },
                }}
              >
             <Box />
                  {this.state.activeStep === 1 && (
                    <Button
                      type="button"
                      onClick={this.handleBack}
                      sx={{
                        backgroundColor: "#FFF1CC",
                        color: "#000000",
                        fontWeight: "700",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontFamily: 'Outfit',
                        padding: "10px 16px 10px 16px",
                        textTransform: "Capitalize",
                        marginRight: "1rem",
                        "&:hover": {
                          backgroundColor: "#FFF1CC",
                        },
                      }}
                      data-test-id="back-button"
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#FFF1CC",
                      color: "#000000",
                      fontWeight: "700",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontFamily: 'Outfit',
                      padding: "10px 16px 10px 16px",
                      textTransform: "Capitalize",
                      "&:hover": {
                        backgroundColor: "#FFF1CC",
                      },
                    }}
                  disabled={this.disableNextButton(this.state.activeStep)}
                  data-test-id="update-button"
                  >
                    Update Changes
                  </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <PopupMessage
          data-test-id="popupId"
          open={this.state.messagePopup}
          type={configJSON.successMessage}
          message={this.state.messageForPopup}
          handleClose={this.handlePopupClose}
        />
      </WorkOrderStyle>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const webStyles = {
  welcome_container: {
    paddingLeft: "48px",
    paddingTop: "14px",
    paddingBottom: "55px",
    '@media (max-width: 600px)': {
        paddingLeft: "10px",
      },
      '@media (max-width: 960px)': {
        paddingLeft: "10px",
      }
  },
  header:{
    color: "#000000",
    display: "inline",
    fontWeight: "700",
    fontSize: "14px",
    fontFamily: 'Outfit',
  }
};

const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
});
// Customizable Area End