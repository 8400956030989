import { Box, TextField, Typography, styled } from "@mui/material";
import React, { Component } from "react";
type MyProps = {
    label?: string,
    type: string,
    style?: any,
    placeholder?: string,
    className?: string,
    value?: string | number,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    iconChangeState?: boolean,
    name?: string,
    InputProps?: any
    validationMsg?: string,
    testID?: string;
    ref?: any;
    isHideMsgError?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
};
export default class CustomInputField extends Component<MyProps> {
    constructor(props: any) {
        super(props);
        this.state = {
        };
    }
    render(): React.ReactNode {
        return (
            <TextFieldStyle>
                <Box>
                    <Typography className="lableStyle">{this.props.label}</Typography>
                    <CustomTextField
                        className={this.props.className}
                        value={this.props.value}
                        data-test-id={this.props.testID}
                        name={this.props.name}
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        defaultValue={this.props.value}
                        onChange={this.props.onChange}
                        InputProps={this.props.InputProps}
                        // variant="outlined" 
                        ref={this.props.ref}
                        autoComplete={this.props.autoComplete}
                        disabled={this.props.disabled}
                        autoFocus={this.props.autoFocus}
                    />
                </Box>
                <Box style={{ display: this.props.isHideMsgError ? "none" : "block" }}>
                    {<span className="validationMsg" style={{ visibility: this.props.validationMsg ? "visible" : "hidden" }}>{this.props.validationMsg ? this.props.validationMsg : "error"}</span>}
                </Box>
            </TextFieldStyle>
        )
    }
}
const TextFieldStyle = styled(Box)({
    "& .input-box": {
        width: '360px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid #BAB1AB',
        backgroundColor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "15px",
        paddingRight: "15px",
        boxSizing: "border-box",
        "@media(max-width:600px)": {
            width: '100%',
            height: '44px',
          }
    },
    '& .validationMsg': {
        fontFamily: "Outfit, sans-serif",
        color: '#FF1919',
        float: 'left',
        fontWeight: 400,
        fontSize: '12px',
        margin: '6px 0'
    },
    '& .lableStyle': {
        fontFamily: "Outfit, sans-serif",
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '17px',
        color: '#544B45',
        marginBottom: '8px',
        width: 'fit-content',
    },
})

const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none" ,
      },
      "&:hover fieldset": {
        border: "none" 
      },
      padding: 0,
    },
      "& .MuiInputBase-input": {
        padding: 0, 
      },
  });