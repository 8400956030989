import React, { ReactNode } from "react";

import {
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  InputLabel,
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import LandlordFirstLoginController, {
  S,
  webConfigJSON,
} from "./LandlordFirstLoginController";
import { Props } from "./CustomFormController.web";
const UploadIcon = require("../assets/UploadIcon.svg");
import Check from "@mui/icons-material/Check";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  TextareaAutosize,
} from "@mui/base/TextareaAutosize";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { FileWithPreview } from "./LandlordFirstLoginController";
import FileUpload from "../../accountgroups/src/FileUpload";

import {
  accountsMain,
  activeKeyMain,
  activeMainAccount,
  activeMainDashboard,
  activeMainFinancial,
  activeMainOrder,
  activeMainReminder,
  activeMainReport,
  activeMainSetting,
  activeMainVendor,
  activePropertyMain,
  bottomImageIcon,
  dashboardLandLogo,
  financialMain,
  keasyIcon,
  keyMainChain,
  ordersMain,
  propertyMain,
  remindersMain,
  reportMain,
  settingsMain,
  vendorMain,
} from "./assets";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

const steps = [
  "Property information",
  "Owner information",
  "Contact information",
  "Banking information",
  "Compliance",
  "Preferred Vendors",
];

const communicationOptions = [
  "Email",
  "Phone Call",
  "Text",
  "App Notification",
];

interface StepIconProps {
  active: boolean;
  completed: boolean;
  icon: React.ReactNode;
}
interface OwnerState {
  active?: boolean;
  completed?: boolean;
}

const CustomStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: ownerState.active ? "#CC9200" : "#eaeaf0",
  color: ownerState.active ? "#fff" : "#000",
  display: "flex",
  height: 32,
  width: 32,
  marginRight: 10,
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.completed && {
    backgroundColor: "#00e676",
    color: "#fff",
  }),
}));

export function CustomStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ active, completed }} sx={{ fontWeight: 700, fontSize: "16px", fontFamily: "Outfit" }}>
      {completed ? <Check /> : icon}
    </CustomStepIconRoot>
  );
}

const CustomStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Outfit",
    "&.Mui-active": {
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "Outfit",
    },
    "&.Mui-completed": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
});

const InputHead = {
  color: "#000000",
  display: "inline",
  fontWeight: "700",
  fontSize: "14px",
};

type InputLabelProps = React.ComponentProps<typeof InputLabel>;


const StyledInputLabel = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  color: "#BAB1AB",
  fontWeight: "bold",
}));

const styles = {
  borderBottom: "1px solid #D1CBC7",
  marginBottom: { xs: "15px", md: "30px" },
  marginTop: { xs: "20px", md: "40px" },
};

const leftBoxStyles = {
  width: { xs: "100%", md: "28%" },
  marginLeft: { xs: "0px", md: "50px" },
  height: "100%",
  marginBottom: { xs: "24px", md: "8px" },
};

const rightBoxStyles = {
  width: { xs: "100%", md: "67%" },
  height: "100%",
};

const line = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
};

const navbarItem = {
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000,
  "@media(max-width: 992px)": {display: "flex", marginTop: "24px",
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  },
  scrollbarWidth: "none", 
  msOverflowStyle: "none",
  '&::-webkit-scrollbar': {
    display: 'none',
  }
};

export const MainBox = ({
  left,
  right,
}: {
  left: ReactNode;
  right: ReactNode;
}) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #D1CBC7",
          marginBottom: { xs: "15px", md: "30px" },
          marginTop: { xs: "20px", md: "40px" },
        }}
      />
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <Box
          sx={{
            width: { xs: "100%", md: "28%" },
            marginLeft: { xs: "0px", md: "50px" },
            marginBottom: { xs: "24px", md: "8px" },
          }}
        >
          {left}
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "67%" },
            height: "100%",
          }}
        >
          {right}
        </Box>
      </Box>
    </>
  );
};

const upperBox = {
  display: "flex",
  alignItems: "flex-start",
  flexDirection: { xs: "column", md: "row" },
  width: "100%",
}

const nextBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: { xs: "100%", md: "auto" },
  marginRight: { xs: "0px", md: "16px" },
  marginBottom: { xs: "20px", md: "0px" },
}

const areaCode = {
  width: { xs: "100%", md: "182px" },
  height: "54px",
  borderRadius: "8px",
  borderColor: "#BAB1AB",
  fontSize: "14px",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#BAB1AB",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#BAB1AB",
  },
}

const CustomSpan = styled('span')({
  "&.blockId": {
    display: "block"
  },
  "&.noneId": {
    display: "none"
  }
});

const contactNo = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: { xs: "100%", md: "auto" },
}

const bankAcc = {
  width: { xs: "100%", md: "184px" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BAB1AB",
    },
    "&:hover fieldset": {
      borderColor: "#BAB1AB",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BAB1AB",
    },
  },
}

const fieldStyle = {
  "@media (max-width: 992px)": {
    width: "100%",
  },
  height: "44px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#BAB1AB",
    },
    "&:hover fieldset": {
      borderColor: "#BAB1AB",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#BAB1AB",
    },
  },
}


export default class LandlordFirstLogin extends LandlordFirstLoginController {
  constructor(props: Props) {
    super(props);
  }

  renderFileUploadSection = (
    title: string,
    description: string,
    stateKey: keyof S
  ) => {
    return (
      <MainBox
        left={
          <Typography
            sx={{
              color: "#BAB1AB",
              fontWeight: "bold",
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></Typography>
        }
        right={
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {description && (
              <Typography
                sx={{
                  color: "#70645C",
                  fontSize: "16px",
                  marginBottom: "8px",
                  width: { xs: "100%", md: "80%" },
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              ></Typography>
            )}
              <Box sx={{ 
                "@media (max-width: 992px)": {
                            width: "inherit",
                          },
            }}>
              <FileUpload
                label=""
                name={stateKey}
                files={this.state[stateKey] as FileWithPreview[] || []}
                onFilesChange={this.handleFileChange}
              />
              {this.validateField(stateKey) && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  Please attach a file
                </Alert>
              )}
              </Box>
          </Box>
        }
      />
    );
  };

  render() {
    const { activeStep } = this.state;

    return (
      <Box
        sx={{
          position: 'relative',
          display: "flex",
          flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" },
        }}
      >
        <Grid item sx={navbarItem}>
          <NavigationSidebar
            data-test-id = "navigationId"
            activeItem={this.state.activeMainItem}
            sidebarItems={[
              {
                label: webConfigJSON.dashboardLandloard,
                labelKey: webConfigJSON.dashboardLandlabel,
                icon: dashboardLandLogo,
                activeIcon: activeMainDashboard,
                pathName: webConfigJSON.dashboardLandlabel,
              },
              {
                label: webConfigJSON.myAccountlandloard,
                labelKey: webConfigJSON.myAccountlandlabel,
                icon: accountsMain,
                activeIcon: activeMainAccount,
                pathName: webConfigJSON.myAccountlandlabel,
              },
              {
                label: webConfigJSON.worklandloard,
                labelKey: webConfigJSON.workOrderslandlabel,
                icon: ordersMain,
                activeIcon: activeMainOrder,
                pathName: webConfigJSON.workOrderslandlabel,
              },
              {
                label: webConfigJSON.preferredlandloard,
                labelKey: webConfigJSON.prevendorlandlabel,
                icon: vendorMain,
                activeIcon: activeMainVendor,
                pathName: webConfigJSON.prevendorlandlabel,
              },
              {
                label: webConfigJSON.reminderslandloard,
                labelKey: webConfigJSON.reminlandlabel,
                icon: remindersMain,
                activeIcon: activeMainReminder,
                pathName: webConfigJSON.reminlandlabel,
              },
              {
                label: webConfigJSON.myproplandloard,
                labelKey: webConfigJSON.myproplandlabel,
                icon: propertyMain,
                activeIcon: activePropertyMain,
                pathName: webConfigJSON.myproplandlabel,
              },
              {
                label: webConfigJSON.financialandloard,
                labelKey: webConfigJSON.financlandlabel,
                icon: financialMain,
                activeIcon: activeMainFinancial,
                pathName: webConfigJSON.financlandlabel,
              },
              {
                label: webConfigJSON.reportlandloard,
                labelKey: webConfigJSON.reportlandlabel,
                icon: reportMain,
                activeIcon: activeMainReport,
                pathName: webConfigJSON.reportlandlabel,
              },
              {
                label: webConfigJSON.chatLandloard,
                labelKey: webConfigJSON.chatlandlabel,
                icon: keyMainChain,
                activeIcon: activeKeyMain,
                pathName: webConfigJSON.chatlandlabel,
              },
              {
                label: webConfigJSON.settingLandlord,
                labelKey: webConfigJSON.settinglandlabel,
                icon: settingsMain,
                activeIcon: activeMainSetting,
                pathName: webConfigJSON.settinglandlabel,
              },
            ]}
            onClickSidebar={this.handleMainItemClick}
            keasylogo={keasyIcon}
            openDrawer={this.state.openLandlordDrawer}
            onClickDrawer={this.handleLandlordDrawer}
          />
           <Box className="landlordFirstLoginMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
           <CustomNavbar navigation={this.props.navigation} id="landlordFirstLoginNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>
        </Grid>
        <Box
          sx={{
            paddingLeft: "30px",
            paddingRight: "0px",
            flexGrow: 1,
            borderLeft: "1px solid rgb(208,203,199)",
            marginLeft: "120px",
            width: "calc(100% - 150px)",
            "@media (max-width: 992px)": {
              width: "calc(100% - 32px)",
              marginLeft: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            },
          }}
        >
          <Box className="landlordFirstLoginNavbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>
          <CustomNavbar 
              navigation={this.props.navigation}
              id="CustomForm"
              showChatButton={true}   
              showPropertiesButton={false} 
            />
          </Box><Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "90%",
              "@media (max-width: 992px)": {
              width: "100%",
              },
              height: "auto",
            }}
          >
            <Typography
              gutterBottom
              fontWeight="700"
              color="#CC9200"
              fontSize="30px"
              fontFamily="Outfit"
              sx={{ alignSelf: "flex-start", textAlign: "left", marginTop: "50px" }}
            >
              Welcome to Keasy
            </Typography>

            <Box sx={{ textAlign: "left" }}>
              <Typography
                paragraph
                color="#70645C"
                fontWeight="700"
                fontSize="18px"
                fontFamily="Outfit"
              >
                Before we start we need you to complete few details...
              </Typography>
              <Typography paragraph fontSize="18px" fontFamily="Outfit" fontWeight="400" color="#70645C" >
                Once you finish all the mandatory details, you may be able to
                see your dashboard.
              </Typography>
              <Typography paragraph fontSize="18px" fontFamily="Outfit" fontWeight="400" color="#70645C" >
                Thank you for signing up for our property management concierge
                subscription. We sincerely appreciate your trust and look
                forward to the opportunity of working with you. To ensure a
                smooth account setup, we kindly request that you provide the
                requested information on this form.
              </Typography>
              <Typography paragraph fontSize="18px" fontFamily="Outfit" fontWeight="400" color="#70645C" >
                The estimated time to complete this form is approximately 15
                minutes. You have the option to save your progress and continue
                at a later time if needed. We encourage you to provide as much
                detailed information as possible, as it will enable us to
                provide you with the best service tailored to your needs. Please
                feel free to reach out to us with any questions you may have
                while completing the form. We are here to assist you every step
                of the way. Once your form is submitted, please allow us up to 7
                business days to review your information, set up your account,
                and initiate the provision of our service.
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography
              gutterBottom
              fontSize="24px"
              fontFamily="Outfit"
              fontWeight="700"
              sx={{
                marginLeft: { xs: "0", md: "70px" }, // Adjust marginLeft for small screens
                marginTop: { xs: "20px", md: "33px" }, // Adjust marginTop for small screens
                marginBottom: { xs: "20px", md: "30px" }, // Adjust marginBottom for small screens
              }}
            >
              Mandatory fields to get started with Keasy
            </Typography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Stepper
              data-test-id="stepper"
              activeStep={activeStep}
              orientation="horizontal"
              sx={{
                marginLeft: { xs: "0px", md: "78px" },
                marginRight: { xs: "0px", md: "48px" },
                "& .MuiStepConnector-line": {
                  borderRight: "2px solid #bdbdbd",
                  borderTop: "none", // Remove top border for horizontal line
                  height: "4em",
                  width: "80%",
                },
                "@media (max-width: 1220px)": {
                  flexDirection: "row",
                  flexWrap: "wrap",
                  "& .MuiStepConnector-line": {
                    borderRight: "none",
                    borderTop: "none",
                    height: "auto",
                    width: "100%",
                  },
                  "& .MuiStepConnector-root": {
                    flex: "0",
                  },
                },
              }}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};

                const steprwords = label.split(" ");
                return (
                  <Step key={label} {...stepProps}>
                    <CustomStepLabel
                      StepIconComponent={(props) => (
                        <CustomStepIcon {...props} />
                      )}
                    >
                      {steprwords.map((steprwords, i) => (
                        <span key={i} style={{ display: "block" }}>
                          {steprwords}
                        </span>
                      ))}
                    </CustomStepLabel>
                  </Step>
                );
              })}
            </Stepper>

            
            <Box
              data-test-id="forms"
              component="form"
              onSubmit={
                activeStep === steps.length - 1
                  ? this.onAddTask
                  : this.handleNext
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: { xs: "100%", md: "80%" },
                padding: { xs: "0", md: "0 70px" },
                marginTop: "20px",
                marginBottom: "40px",
                marginLeft: { xs: "0px", md: "70px" },
                "& .MuiOutlinedInput-root" : {
                  "@media (max-width: 992px)": {
                    height: "42px !important",
                  },
                }
              }}
            >
              
              {activeStep === 0 && (
                <Box data-test-id="form1">
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                      >
                        Property Address*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <Grid
                        xs={8}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography style={InputHead}>
                            Street Address
                          </Typography>
                          <TextField
                            data-test-id="StreetAddress"
                            id="pStreetAddress"
                            name="pStreetAddress"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.pStreetAddress}
                            onChange={(e) =>
                              this.handleChange(
                                "pStreetAddress",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography style={InputHead}>
                            Street Address Line 2
                          </Typography>
                          <TextField
                            data-test-id="StreetAddress2"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.pStreetAddress2}
                            onChange={(e) =>
                              this.handleChange(
                                "pStreetAddress2",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={InputHead}> City</Typography>
                            <TextField
                              data-test-id="city"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.pCity}
                              onChange={(e) =>
                                this.handleChange("pCity", e.target.value)
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={InputHead}>
                              State/Province
                            </Typography>
                            <TextField
                              data-test-id="state"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.pState}
                              onChange={(e) =>
                                this.handleChange("pState", e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={InputHead}>
                            Postal / Zip Code
                          </Typography>
                          <TextField
                            data-test-id="zip"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            InputProps={{
                              sx: {
                                width: { xs: "100%", md: "50%" }, // 100% width on small screens, 50% on medium screens
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.pZip}
                            onChange={(e) =>
                              this.handleChange("pZip", e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  

                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                       
                      >
                        Number of Rental Units*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        data-test-id="rentalUnit"
                        variant="outlined"
                        required
                        size="medium"
                        placeholder="e.g., 23"
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: { fontSize: "14px", borderRadius: "8px" },
                          disableUnderline: true,
                        }}
                        sx={{
                          width: { xs: "100%", md: "236px" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#BAB1AB",
                            },
                            "&:hover fieldset": {
                              borderColor: "#BAB1AB",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#BAB1AB",
                            },
                          },
                          "& ::placeholder": {
                            fontWeight: 400,
                            color: "#332500",
                            fontSize: "16px",
                          },
                        }}
                        value={this.state.rentalUnit}
                        onChange={(e) =>{
                          if(this.validateNumber(e.target.value)){
                            this.handleChange("rentalUnit", e.target.value)
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <Typography
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "bold",
                          minWidth: "120px",
                        }}
                      >
                        {webConfigJSON.utilities}
                      </Typography>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <FormGroup
                        data-test-id="utilties1"
                        sx={{
                          flexDirection: { xs: "row", md: "column" },
                        }}
                      >
                        {[
                          "Water - provided by municipality",
                          "Water - by well",
                          "Sewer",
                          "Septic",
                          "Power",
                          "Garbage",
                          "Gas",
                          "HOA",
                          "Internet",
                        ].map((label) => (
                          <FormControlLabel
                            key={label}
                            control={
                              <Checkbox
                                name={label}
                                checked={this.state.utilities.includes(label)}
                                onChange={this.handleCheckboxChange(
                                  "utilities"
                                )}
                                sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                              />
                            }
                            label={label}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <Typography
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "bold",
                          minWidth: "120px",
                        }}
                      >
                        {webConfigJSON.utilities}
                      </Typography>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={this.state.utilities.find((item) =>
                            [
                              "Laundry room on-site managed by landlord",
                              "Laundry room on-site managed by vendor",
                              "Washer and dryer in unit owned by tenant",
                              "Washer and dryer in unit owned by landlord",
                            ].includes(item)
                          )}
                          name="radio-buttons-group"
                          onChange={this.handleRadioChange}
                        >
                          {[
                            "Laundry room on-site managed by landlord",
                            "Laundry room on-site managed by vendor",
                            "Washer and dryer in unit owned by tenant",
                            "Washer and dryer in unit owned by landlord",
                          ].map((label) => (
                            <FormControlLabel
                              key={label}
                              value={label}
                              control={
                                <Radio
                                  sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                                />
                              }
                              label={label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>



                  {this.renderFileUploadSection(
                    "Current Tenant(s)*",
                    "Please provide the current lease for each unit in this property, alternatively, a rent roll that includes information about all units",
                    "currentTenant"
                  )}

                  

                  {this.renderFileUploadSection(
                    "Move-in Inspection(s)",
                    "While move-in inspection paperwork is not mandatory, uploading it now would help us with future move-out procedures for your tenants.",
                    "moveInInspection"
                  )}
                </Box>
              )}

              
              {activeStep === 1 && (
                <Box data-test-id="inside1">
                  
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                    
                      >
                        Owner's name*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        data-test-id="ownerName"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                          height: "56px",
                          marginBottom: "16px",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            borderRadius: "8px",
                          },
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                        value={this.state.ownerName}
                        onChange={(e) =>
                          this.handleChange("ownerName", e.target.value)
                        }
                      />
                    </Box>
                  </Box>
                 
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
    
                      >
                        Owner Address*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <Grid
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography style={{ fontWeight: "bold" }}>
                            Street Address
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="oStreetAddress"
                            required
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.oStreetAddress}
                            onChange={(e) =>
                              this.handleChange(
                                "oStreetAddress",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography style={{ fontWeight: "bold" }}>
                            Street Address Line 2
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="oStreetAddress2"
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={this.state.oStreetAddress2}
                            onChange={(e) =>
                              this.handleChange(
                                "oStreetAddress2",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              City
                            </Typography>
                            <TextField
                              data-test-id="oCity"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.oCity}
                              onChange={(e) =>
                                this.handleChange("oCity", e.target.value)
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              State/Province
                            </Typography>
                            <TextField
                              data-test-id="oState"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.oState}
                              onChange={(e) =>
                                this.handleChange("oState", e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              Postal / Zip Code
                            </Typography>
                            <TextField
                              data-test-id="oZip"
                              variant="outlined"
                              required
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                              value={this.state.oZip}
                              onChange={(e) =>
                                this.handleChange("oZip", e.target.value)
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              Country
                            </Typography>
                              <TextField
                                required
                                fullWidth
                                variant="outlined"
                                data-test-id="oCountry"
                                select
                                SelectProps={{ native: true }}
                                value={this.state.oCountry}
                                onChange={(event) =>
                                  this.setState({
                                    oCountry: event.target.value,
                                  })
                                }
                                sx={{
                                  '.MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                  }
                                }}
                              >
                                <option value="">Please select</option>
                                {this.state.countries?.map((country) => (
                                  <option key={country.country_code} value={country.name}>{country.emoji_flag} {country.name}</option>
                                ))}
                              </TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  

                  {this.renderFileUploadSection(
                    "Proof of Ownership*",
                    "A deed of trust, property tax bill, or mortgage statement will suffice.",
                    "proofOfOwnership"
                  )}
                </Box>
              )}

              
              {activeStep === 2 && (
                <Box data-test-id="inside2">
                 

                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                        
                      >
                        Primary Contact Name*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        data-test-id="primaryContactName"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: { fontSize: "14px", borderRadius: "8px" },
                          disableUnderline: true,
                        }}
                        sx={fieldStyle}
                        value={this.state.primaryContactName}
                        onChange={(e) =>
                          this.handleChange(
                            "primaryContactName",
                            e.target.value
                          )
                        }
                      />
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                      
                      >
                        Primary Contact Email*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        data-test-id="primaryContactEmail"
                        variant="outlined"
                        required
                        fullWidth
                        error={!this.state.isPrimaryEmailValid}
                        helperText={this.getHelperText("primaryEmail")}
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: { fontSize: "14px", borderRadius: "8px" },
                          disableUnderline: true,
                        }}
                        sx={{
                          width: { xs: "100%" },
                          height: "44px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: this.state.isPrimaryEmailValid
                                ? "#BAB1AB"
                                : "red",
                            },
                            "&:hover fieldset": {
                              borderColor: this.state.isPrimaryEmailValid
                                ? "#BAB1AB"
                                : "red",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: this.state.isPrimaryEmailValid
                                ? "#BAB1AB"
                                : "red",
                            },
                          },
                        }}
                        value={this.state.primaryContactEmail}
                        onChange={(e) =>
                          this.handleChange(
                            "primaryContactEmail",
                            e.target.value
                          )
                        }
                      />
                      {/* <Typography
                        sx={{
                          color: "#A3978F",
                          fontWeight: "700",
                          fontSize: "14px",
                          marginTop: "18px",
                        }}
                      >
                        example@example.com
                      </Typography> */}
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                        
                      >
                        Primary Contact Phone*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <Box
                        sx={upperBox}
                      >
                        <Box
                          sx={nextBox}
                        >
                          <FormControl variant="outlined" fullWidth>
                          <Typography
                            style={InputHead}
                          >
                            Area Code
                          </Typography>
                          <Select
                            data-test-id="primaryContactNumberAreaCode"
                            required
                            value={this.state.primaryContactNumberAreaCode}
                            onChange={(areaEvent) =>
                              this.handleChange(
                                "primaryContactNumberAreaCode",
                                  areaEvent.target.value
                                )
                              }
                              sx={areaCode}
                              onOpen={this.handleCountryCodeDropdown}
                              onClose={this.handleCloseCountry}
                            >
                             {this.state.countries.map((country) => (
                                  <MenuItem key={country.name} value={country.country_code}>
                                    <CustomSpan className={this.handleCodeArea()}>
                                      {country.emoji_flag} {country.name} (+{country.country_code})
                                      </CustomSpan>
                                    <CustomSpan className={this.handleAreaCode()}>+{country.country_code}</CustomSpan>
                                  </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Box>
                        <Box
                          sx={contactNo}
                        >
                          <Typography
                            style={InputHead}
                          >
                            Phone Number
                          </Typography>
                          <TextField
                            data-test-id="primaryContactNumberPhone"
                            variant="outlined"
                            inputProps={{ maxLength: 10 , minLength: 10}}
                            required
                            value={this.state.primaryContactNumberPhone}
                            onChange={(e) =>
                              this.handleChange(
                                "primaryContactNumberPhone",
                                e.target.value
                              )
                            }
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            InputProps={{
                              style: { fontSize: "14px", borderRadius: "8px" },
                              disableUnderline: true,
                            }}
                            sx={{
                              width: { xs: "100%" },
                              height: "44px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: this.state.isPrimaryNumberValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                                "&:hover fieldset": {
                                  borderColor: this.state.isPrimaryNumberValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: this.state.isPrimaryNumberValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                    
                      >
                        Primary Contact Address*
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <Grid
                        xs={8}
                        sx={{
                          "@media (max-width: 992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography style={InputHead}>
                            Street Address
                          </Typography>
                          <TextField
                            data-test-id="primaryStreetAddress"
                            variant="outlined"
                            required
                            value={this.state.primaryStreetAddress}
                            onChange={(e) =>
                              this.handleChange(
                                "primaryStreetAddress",
                                e.target.value
                              )
                            }
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography style={InputHead}>
                            Street Address Line 2
                          </Typography>
                          <TextField
                            data-test-id="primaryStreetAddress2"
                            variant="outlined"
                            value={this.state.primaryStreetAddress2}
                            onChange={(e) =>
                              this.handleChange(
                                "primaryStreetAddress2",
                                e.target.value
                              )
                            }
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={InputHead}> City</Typography>
                            <TextField
                              data-test-id="primaryCity"
                              variant="outlined"
                              required
                              value={this.state.primaryCity}
                              onChange={(e) =>
                                this.handleChange("primaryCity", e.target.value)
                              }
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                              flexBasis: { xs: "100%", md: "50%" },
                            }}
                          >
                            <Typography style={InputHead}>
                              State/Province
                            </Typography>
                            <TextField
                              data-test-id="primaryState"
                              variant="outlined"
                              required
                              value={this.state.primaryState}
                              onChange={(e) =>
                                this.handleChange(
                                  "primaryState",
                                  e.target.value
                                )
                              }
                              fullWidth
                              InputLabelProps={{ style: { fontSize: "14px" } }}
                              sx={{ marginBottom: "16px" }}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={InputHead}>
                            Postal / Zip Code
                          </Typography>
                          <TextField
                            data-test-id="primaryZip"
                            variant="outlined"
                            required
                            value={this.state.primaryZip}
                            onChange={(e) =>
                              this.handleChange("primaryZip", e.target.value)
                            }
                            fullWidth
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            InputProps={{
                              sx: {
                                width: { xs: "100%", md: "50%" }, // 100% width on small screens, 50% on medium screens
                                borderRadius: "8px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  
                  <MainBox
                    left={
                      <InputLabel
                        sx={line}
                      >
                        <StyledInputLabel>
                          Preferred way of <br style={{marginBottom: "12px"}}/>communication
                        </StyledInputLabel>
                      </InputLabel>
                    }
                    right={
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box
                          sx={{
                            display: "flex",
                            marginBottom: "8px",
                            flexDirection: "column",
                            alignSelf: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              marginRight: "8px",
                            }}
                          >
                            Contact me via:
                          </Typography>
                          <Typography>Need to select two</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {communicationOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              control={
                                <Checkbox
                                  checked={this.state.preferredCommunicationMethod.includes(
                                    option
                                  )}
                                  onChange={
                                    this.handlePreferredCommunicationChange
                                  }
                                  value={option}
                                  disabled={this.isCheckboxDisabled(option)}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#CC9200",
                                    },
                                  }}
                                />
                              }
                              label={option}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "14px",
                                },
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    }
                  />
                  
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                      
                      >
                        {webConfigJSON.secondName}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        variant="outlined"
                        data-test-id="secondaryContactName"
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: { fontSize: "14px", borderRadius: "8px" },
                          disableUnderline: true,
                        }}
                        sx={fieldStyle}
                        value={this.state.secondaryContactName}
                        onChange={(e) =>
                          this.handleChange(
                            "secondaryContactName",
                            e.target.value
                          )
                        }
                      />
                    </Box>
                  </Box>

                 
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                      
                      >
                        {webConfigJSON.secondEmail}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <TextField
                            data-test-id="secondaryContactEmail"
                            variant="outlined"
                            fullWidth
                            error={!this.state.isSecondaryEmailValid}
                            helperText={this.getHelperText("secondaryEmail")}
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            InputProps={{
                              style: { fontSize: "14px", borderRadius: "8px" },
                              disableUnderline: true
                            }}
                            sx={{
                              width: { xs: "100%" },
                              height: "44px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: this.state.isSecondaryEmailValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                                "&:hover fieldset": {
                                  borderColor: this.state.isSecondaryEmailValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: this.state.isSecondaryEmailValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                              },
                            }}
                            value={this.state.secondaryContactEmail}
                            onChange={(e) =>
                              this.handleChange(
                                "secondaryContactEmail",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <Typography
                          sx={{
                            color: "#A3978F",
                            fontWeight: "700",
                            display: "none",
                            fontSize: "14px",
                            marginTop: "18px",
                          }}
                        >
                          example@example.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box sx={line}>
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                        
                      >
                        {webConfigJSON.secondNumber}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <Box
                        sx={upperBox}
                      >
                        <Box
                          sx={nextBox}
                        >
                        <FormControl fullWidth variant="outlined">
                          <Typography
                            style={InputHead}
                          >
                            Area Code
                          </Typography>
                          <Select
                            data-test-id="secondaryContactNumberAreaCode"
                            value={this.state.secondaryContactNumberAreaCode}
                            onChange={(areaEvent) =>
                              this.handleChange(
                                "secondaryContactNumberAreaCode",
                                areaEvent.target.value
                              )
                            }
                            onOpen={this.handleCountryCodeDropdown}
                            onClose={this.handleCloseCountry}
                            sx={areaCode}

                          >
                            {this.state.countries.map((country) => (
                              <MenuItem key={country.name} value={country.country_code}>
                                <CustomSpan className={this.handleCodeArea()}>
                                  {country.emoji_flag} {country.name} (+{country.country_code})
                                </CustomSpan>
                                <CustomSpan className={this.handleAreaCode()}>+{country.country_code}</CustomSpan>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        </Box>
                        <Box
                          sx={contactNo}
                        >
                          <Typography
                            style={InputHead}
                          >
                            Phone Number
                          </Typography>
                          <TextField
                            data-test-id="secondaryContactNumberPhone"
                            variant="outlined"
                            inputProps={{ maxLength: 10 , minLength: 10}}
                            value={this.state.secondaryContactNumberPhone}
                            onChange={(e) =>
                              this.handleChange(
                                "secondaryContactNumberPhone",
                                e.target.value
                              )
                            }
                            InputLabelProps={{ style: { fontSize: "14px" } }}
                            InputProps={{
                              style: { fontSize: "14px", borderRadius: "8px" },
                              disableUnderline: true,
                            }}
                            sx={{
                              width: { xs: "100%" },
                              height: "44px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: this.state.isSecondaryNumberValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                                "&:hover fieldset": {
                                  borderColor: this.state.isSecondaryNumberValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: this.state.isSecondaryNumberValid
                                    ? "#BAB1AB"
                                    : "red",
                                },
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              
              {activeStep === 3 && this.state.showBankInfo && (
                <Box data-test-id="inside3">
                  
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                       
                      >
                        {webConfigJSON.bankName}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        data-test-id="bankName"
                        variant="outlined"
                        value={this.state.bankName}
                        onChange={(e) =>
                          this.handleChange("bankName", e.target.value)
                        }
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: { fontSize: "14px", borderRadius: "8px" },
                          disableUnderline: true,
                        }}
                        sx={fieldStyle}
                      />
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                      
                      >
                        Bank Routing Number{" "}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        inputProps={{ maxLength: 9 , minLength: 9}}
                        data-test-id="bankRoutingNumber"
                        variant="outlined"
                        value={this.state.bankRoutingNumber}
                        onChange={(e) =>
                          this.handleChange("bankRoutingNumber", e.target.value)
                        }
                        size="medium"
                        placeholder="e.g., 23"
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            borderRadius: "8px",
                            marginRight: "204px",
                          },
                          disableUnderline: true,
                        }}
                        sx={bankAcc}
                      />
                    </Box>
                  </Box>

                  
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                      
                      >
                        Bank Account Number{" "}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        inputProps={{ maxLength: 14 , minLength: 7}}
                        data-test-id="bankAccountNumber"
                        variant="outlined"
                        value={this.state.bankAccountNumber}
                        onChange={(e) =>
                          this.handleChange("bankAccountNumber", e.target.value)
                        }
                        size="medium"
                        placeholder="e.g., 23"
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: {
                            fontSize: "14px",
                            borderRadius: "8px",
                          },
                          disableUnderline: true,
                        }}
                        sx={bankAcc}
                      />
                    </Box>
                  </Box>

                  
                  {this.renderFileUploadSection(
                    "Please Upload a picture <br/> of the voided check",
                    "",
                    "voidedCheck"
                  )}

                 
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <Typography
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "bold",
                          minWidth: "120px",
                        }}
                      >
                        Please accept:
                      </Typography>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <FormControl data-test-id="confirmBankAccountDetails">
                        <FormControlLabel
                          control={
                            <Radio
                              data-test-id="confirmBankAccountDetails"
                              checked={this.state.confirmBankAccountDetails}
                              onChange={(event) => {
                                this.setState({
                                  confirmBankAccountDetails:
                                    event.target.checked,
                                });
                              }}
                              sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                            />
                          }
                          label={
                            <div
                              style={{
                                marginLeft: "23px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  "I confirm that the details of bank account provided above are accurate and correct, and I authorize DANRON LLC (DBA – ARAMAIP) and / or AppFolio, Inc. to activate the above listed bank accounts in order to process payments through AppFolio Payment Services.",
                              }}
                            />
                          }
                          sx={{ alignItems: "flex-start" }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              )}

             

              {activeStep === 3 && this.state.mendatInfo && (
                <Box data-test-id="inside4">
                  <Box
                    sx={{
                      marginTop: { xs: "50px", md: "104px" },
                      borderRadius: 2,
                      padding: { md: "56px", xs: "47px" },
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      flexDirection: "row",
                      border: "1px solid #BAB1AB",
                      height: "100%",
                      backgroundColor: "#ffffff",
                      "@media (max-width: 600px)": {
                        justifyContent: "center",
                        textAlign: "center",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#70645C",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "1.5",
                        maxWidth: { md: "714px", xs: "100%" },
                        marginBottom: { xs: "20px", md: "0px" },
                        marginRight: { xs: "0px", md: "25px" },
                        maxHeight: { md: "174px", xs: "100%" },
                      }}
                    >
                      You've already completed the mandatory information. For a{" "}
                      better experience with Keasy, please also fill in the
                      following:
                    </Typography>
                    <Button
                      type="submit"
                      onClick={this.handleNext2}
                      variant="contained"
                      sx={{
                        bgcolor: "#FFC123",
                        width: "181px",
                        height: "56px",
                        color: "black",
                        "&:hover": { bgcolor: "#FFC300" },
                        padding: "10px 20px", // Adjust padding for better button size
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        Start
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}


              {activeStep === 4 && (
                <>
                  <Typography
                    sx={{
                      marginTop: "75px",
                      marginBottom: "-15px",
                      fontWeight: "700",
                      color: "#70645C",
                      fontSize: "18px",
                    }}
                  >
                    To receive reminders for standard annual compliance tasks
                    from our concierge service, please fill out the section
                    below.
                  </Typography>

                 
                  <MainBox
                    left={
                      <StyledInputLabel
                       
                      >
                        Insurance Renewal Date
                      </StyledInputLabel>
                    }
                    right={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          style={InputHead}
                          sx={{ marginBottom: "8px" }}
                        >
                          Date
                        </Typography>
                        
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={this.state.insuranceRenewalDate}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  width: { xs: "100%", md: "auto" },
                                }}
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { borderRadius: 8 },
                                }}
                              />
                            )}
                            disablePast
                            onChange={this.handleDateChange(
                              "insuranceRenewalDate"
                            )}
                            inputFormat="MM-dd-yyyy"
                          />
                        </LocalizationProvider>
                      </Box>
                    }
                  />

                 
                  {this.renderFileUploadSection(
                    "Copy of Current Insurance",
                    "",
                    "copyOfCurrentInsurance"
                  )}
                  <MainBox
                    left={
                      <StyledInputLabel
                        
                      >
                        Rental License Renewal Date{" "}
                      </StyledInputLabel>
                    }
                    right={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={InputHead}
                          sx={{ marginBottom: "8px" }}
                        >
                          Date
                        </Typography>
                        
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            onChange={this.handleDateChange(
                              "rentalLicenceRenewalDate"
                            )}
                            value={this.state.rentalLicenceRenewalDate}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  width: { xs: "100%", md: "auto" },
                                }}
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { borderRadius: 8 }, // Add border radius here
                                }}
                              />
                            )}
                            disablePast
                            inputFormat="MM-dd-yyyy"
                          />
                        </LocalizationProvider>
                      </Box>
                    }
                  />

                 
                  {this.renderFileUploadSection(
                    "Copy of Local Rental License",
                    "",
                    "copyOfLocalRentalLicenece"
                  )}

                  
                  <MainBox
                    left={
                      <InputLabel>
                        <Typography
                          sx={{
                            color: "#BAB1AB",
                            fontWeight: "bold",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: "Fire Safety Next <br /> Inspection Date",
                          }}
                        />
                      </InputLabel>
                    }
                    right={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          style={InputHead}
                          sx={{ marginBottom: "8px" }}
                        >
                          Date
                        </Typography>
                       
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={this.state.fireSafetyNextInspectionDate}
                            onChange={this.handleDateChange(
                              "fireSafetyNextInspectionDate"
                            )}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  width: { xs: "100%", md: "auto" },
                                }}
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { borderRadius: 8 }, // Add border radius here
                                }}
                              />
                            )}
                            inputFormat="MM-dd-yyyy"
                            disablePast
                          />
                        </LocalizationProvider>
                      </Box>
                    }
                  />

                  
                  {this.renderFileUploadSection(
                    "Fire Safety Inspection",
                    "",
                    "fireSafetyInspection"
                  )}

                 
                  {this.renderFileUploadSection(
                    "Other Compliance Documents",
                    "Please upload any other compliance-related documents that you would like us to review here.",
                    "otherComplianceDocuments"
                  )}

                  
                  <Box sx={styles} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel
                       
                      >
                        Where are the keys?{" "}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextField
                        data-test-id="keys"
                        variant="outlined"
                        value={this.state.keys}
                        onChange={(e) =>
                          this.handleChange("keys", e.target.value)
                        }
                        fullWidth
                        InputLabelProps={{ style: { fontSize: "14px" } }}
                        InputProps={{
                          style: { fontSize: "14px", borderRadius: "8px" },
                          disableUnderline: true,
                        }}
                        sx={{
                          width: { xs: "100%" },
                          height: "44px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#BAB1AB",
                            },
                            "&:hover fieldset": {
                              borderColor: "#BAB1AB",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#BAB1AB",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel>
                        <Typography
                         
                          dangerouslySetInnerHTML={{
                            __html: "Additional info we <br/>should know?",
                          }}
                        />{" "}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextareaAutosize
                        data-test-id="additionalInfo"
                        minRows={4}
                        maxRows={8}
                        value={this.state.addtionalInfo}
                        onChange={(e) =>
                          this.handleChange("addtionalInfo", e.target.value)
                        }
                        style={{
                          maxWidth: "692px",
                          width: "100%",
                          height: "100px",
                          fontSize: "14px",
                          borderRadius: "8px",
                          resize: "none",
                          borderColor: "#BAB1AB",
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}

             

              {activeStep === 5 && (
                <Box data-test-id="inside5">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "83px",
                      padding: "16px",
                      width: "80%",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#70645C",
                        fontSize: "18px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      Please let us know if you have any preferred vendors that
                      you would like us to engage with on your behalf. Please
                      provide the name and contact information for these vendors
                      below. We will always prioritize reaching out to your
                      preferred vendors first.
                    </Typography>
                  </Box>

                  

                  <MainBox
                    left={
                      <Typography
                        sx={{
                          color: "#BAB1AB",
                          fontWeight: "bold",

                          minWidth: "120px",
                          width: "300px",
                        }}
                      >
                        Please Use My Preferred Vendor For:
                      </Typography>
                    }
                    right={
                      <FormGroup>
                        {[
                          "General Repairs",
                          "Plumbing",
                          "Electric",
                          "Landscaping",
                          "Roofing",
                          "Other",
                        ].map((label) => (
                          <FormControlLabel
                            key={label}
                            control={
                              <Checkbox
                                name={label}
                                checked={this.state.preferredVendorFor.includes(
                                  label
                                )}
                                onChange={this.handleCheckboxChange(
                                  "preferredVendorFor"
                                )}
                                sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                              />
                            }
                            label={label}
                          />
                        ))}
                      </FormGroup>
                    }
                  />
                 
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel>
                        <Typography
                          sx={{
                           
                            marginRight: "140px", // Add space between label and input
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              "Please provide any<br/>additional comments<br/>or specific requests in<br/>the space provided below",
                          }}
                        />
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextareaAutosize
                        data-test-id="additionalComments"
                        minRows={4}
                        maxRows={8}
                        value={this.state.additionalComments}
                        onChange={(e) =>
                          this.handleChange(
                            "additionalComments",
                            e.target.value
                          )
                        }
                        style={{
                          maxWidth: "600px",
                          width: "100%",
                          height: "100px",
                          fontSize: "14px",
                          borderRadius: "8px",
                          resize: "none",
                          borderColor: "#BAB1AB",
                        }}
                      />
                    </Box>
                  </Box>

                 
                  <Box sx={styles} />
                  <Box
                    sx={line}
                  >
                    <Box sx={leftBoxStyles}>
                      <StyledInputLabel>
                        <Typography
                        
                          dangerouslySetInnerHTML={{
                            __html: "signature",
                          }}
                        />{" "}
                      </StyledInputLabel>
                    </Box>
                    <Box sx={rightBoxStyles}>
                      <TextareaAutosize
                        data-test-id="signature"
                        minRows={4}
                        maxRows={8}
                        value={this.state.signature}
                        onChange={(e) =>
                          this.handleChange("signature", e.target.value)
                        }
                        style={{
                          maxWidth: "600px",
                          width: "100%",
                          height: "100px",
                          fontSize: "14px",
                          borderRadius: "8px",
                          resize: "none",
                          borderColor: "#BAB1AB",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                  sx={{
                    display: "flex",
                    float: "inline-end",
                    marginRight: {md: "120px", xs: "0px"}
                  }}>
                  <Button
                    onClick={this.clearSignature}
                    sx={{
                      color: "#CC9200",
                      marginTop: "8px",
                      alignSelf: "end",
                      width: "50px",
                    }}
                  >
                    Clear
                  </Button>
                  </Box>
                </Box>
              )}

              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    marginTop: { md: "54px", xs: "0px" },
                    justifyContent: { md: "flex-end", xs: "flex-start" },
                    "& .MuiButtonBase-root": { borderRadius: "8px !important" }
                  }}
                >
                  {activeStep !== 0 && (
                    <Button
                      onClick={this.handleBack}
                      sx={{
                        mr: 1,
                        backgroundColor: "#FFF1CC",
                        width: "184px",
                        height: "44px",
                        color: "#0F172A",
                        fontWeight: "bold",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#FFF1CC",
                        },
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Box />
                  {activeStep === 3 && this.state.mendatInfo ? (
                    <Button
                      onClick={this.handleGoToDashboard}
                      data-test-id="dashboard"
                      sx={{
                        backgroundColor: "#FFD700",
                        width: "184px",
                        height: "44px",
                        marginBottom: "175px",
                        color: "#0F172A",
                        fontWeight: "bold",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#FFD700",
                        },
                      }}
                    >
                      Go to Dashboard
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={this.disableNextButton(this.state.activeStep)}
                      sx={{
                        backgroundColor: "#FFD700",
                        width: "184px",
                        height: "44px",
                        marginBottom: "175px",
                        color: "#0F172A",
                        fontWeight: "bold",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#FFD700",
                        },
                      }}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  )}
                </Box>
              </React.Fragment>
              
            </Box>
          </Box>
        </Box>
          <img
            src={bottomImageIcon}
            alt="Description"
            style={{
                position: "absolute",
                bottom: "-15px",
                right: "0"
            }}
          />
      </Box>
    );
  }
}
