import React from "react";
// Customizable Area Start
import { Box, Card, Grid, CardContent, Menu, Modal, MenuItem, Divider, ListItemIcon, ListItemText, styled, Typography, Button, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Pagination } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import {ArrowForwardIos, ArrowDropUp, ArrowDropDown, MoreVert, Close } from '@mui/icons-material';
import { DelinquencyData, ExistingVendorDetails, PreferredVendorDetails, RemindersDetails, WorkOrder } from "./DashboardController.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
import { AddPlusIcon } from "../../tasks/src/assets";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import { doneIcon, transferIcon, editIcon, declineIcon, workIcon } from "../../appointmentmanagement/src/assets";
import {documentActive,documents } from '../../automaticreminders/src/assets';
import { activeMainDashboard,serviceInactive, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, keasyIcon, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain, bottomImageIcon, plus, SearchBarIcon, eyeIcon, colPaid, colLate, colPaidPlus,Service } from "./assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEmptyDetails = (status: string) => {
    return (
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"100%"} gap={2}>
        <NothingSee>{webConfigJSON.nothing}</NothingSee>
        <EmptyDetails>{webConfigJSON.nothingText}</EmptyDetails>
        <StartBtn data-test-id = "startId" onClick={()=> this.handleDashboardRoutes(status)}>{webConfigJSON.startButton}</StartBtn>
      </Box>
    );
  }
  renderEmptyTable = (message: string) => {
    return (
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"100%"} gap={2}>
        <EmptyDetails>{message}</EmptyDetails>
      </Box>
    );
  }

  renderWorkOrder = () => {
    return (
      <Box>
        <Grid container spacing={1} display={"flex"} alignItems={"center"} mt={"1rem"}>
          <Grid item xs={12} md={4} justifyContent="flex-start">
            <Box className="search-main-content">
              <Box data-test-id="navigationsId" className="new-property" onClick={()=> this.handleDashboardRoutes("AddAppointment")}>
                <img
                  className="icon"
                  src={plus} alt="plus" />
                <Typography className="new-data">{webConfigJSON.newWorks}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className="search-contents">
              <img
                className="search-icons"
                src={SearchBarIcon} alt="location" />
              <InputBase
                data-test-id="workSearchId"
                placeholder="Search"
                className="search-inputs"
                inputProps={{ "aria-label": "search" }}
                value={this.state.searchWorkOrder}
                onChange={(searchEvent) => this.handleWorkSearchBar(searchEvent)}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          data-test-id="vendorsItem" mt={"1rem"}>
            {this.state.searchErrorWorkOrder ? (
                this.renderEmptyTable("No Work Order Found")
              ) : (
                <>
          <CustomTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <DetailsCell>{webConfigJSON.locations}</DetailsCell>
                  <DetailsCell>{webConfigJSON.category}</DetailsCell>
                  <DetailsCell>{webConfigJSON.workNeeded}</DetailsCell>
                  <DetailsCells>{webConfigJSON.status}</DetailsCells>
                  <TableCell />
                </TableRow>
              </TableHead>
              <CustomContainer>
                {this.state.workOrders.map((works: WorkOrder)=>{
                  return(
                    <React.Fragment>
                      <CustomTableRow>
                        <LocationCells data-test-id="sortId">{this.handleLargeDetails(works.location)}</LocationCells>
                        <StatusCell>{works.category}</StatusCell>
                        <StatusCell>{this.handleLargeDetails(works.work_needed)}</StatusCell>
                        <StatusCells>
                          <OrderStatus data-test-id="statusId" sx={this.statusHandler(works.status)}>
                          {works.status}
                          </OrderStatus>
                        </StatusCells>
                        <ViewCell data-test-id = "openWorksId" onClick={() => this.handleOpenDashboardWork(works.id, "OpenWorkOrder")}>
                          <ArrowForwardIos />
                        </ViewCell>
                      </CustomTableRow>
                    </React.Fragment>
                  )
                })}
              </CustomContainer>
            </Table>
          </CustomTableContainer>
          {this.state.totalWorkOrder >= 2 && (
            <Box display= {"flex"} justifyContent= {"space-between"} alignItems= {"center"} width={"100%"} m={"1rem 0rem 2rem 0rem"}>
              <CustomPagination count={this.state.totalWorkOrder} page={this.state.currentWorks} onChange={this.handleChangeWorkPagination} />
              <CustomPage variant="body1" data-test-id="attachLengthId">{this.state.currentWorks}-{this.state.totalWorkOrder} {webConfigJSON.totalOf} {this.state.countWorks} {webConfigJSON.results}</CustomPage>
            </Box>)}

            </>)}
        </Box>
      </Box>
    )
  }

  renderSortableCells = (reminder: string) => {
    return (
      <CustomCells key={reminder}>
        <Box display="flex" flexDirection="column" marginLeft={"10px"}>
          <CustomButtonsUp
            data-test-id="sortUpId"
          >
            <ArrowDropUp
            />
          </CustomButtonsUp>
          <CustomButtonsDown
            data-test-id="downId"
          >
            <ArrowDropDown
            />
          </CustomButtonsDown>
        </Box>
      </CustomCells>
    );
  };

  renderReminders = () => {
    return (
      <Box>
        <Grid container spacing={1} display={"flex"} alignItems={"center"} mt={"1rem"}>
          <Grid item xs={12} md={4} justifyContent="flex-start">
            <Box className="search-main-content">
              <Box data-test-id="reminderId" className="new-property" onClick={this.navigateToNewReminder}>
                <img
                  className="icon"
                  src={plus} alt="plusIcon" />
                <Typography className="new-data">{webConfigJSON.newReminder}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className="search-contents">
              <img
                className="search-icons"
                src={SearchBarIcon} alt="locations" />
              <InputBase
                data-test-id="reminderSearchsId"
                inputProps={{ "aria-label": "search" }}
                value={this.state.reminderSearch}
                onChange={(searchEvent) => this.handleReminderSearchBar(searchEvent)}
                placeholder="Search"
                className="search-inputs"
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          data-test-id="vendorsItemId" mt={"1rem"}>
            {this.state.searchErrorReminder ? (
                this.renderEmptyTable("No Reminders Found")
              ) : (
                <>
          <CustomTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <DetailsCell>{webConfigJSON.locations}</DetailsCell>
                  <DetailsCell>{webConfigJSON.category}</DetailsCell>
                  <DetailsCell>{webConfigJSON.dueDate}</DetailsCell>
                  <DetailsCells>{webConfigJSON.status}</DetailsCells>
                  <DetailsCells>{webConfigJSON.assignee}</DetailsCells>
                  {this.renderSortableCells("location")}
                </TableRow>
              </TableHead>
              <CustomContainer>
                {this.sortRemindersData(this.state.reminders).map((reminderDetails: RemindersDetails, workIndex: number) => {
                  return (
                    <React.Fragment>
                      <CustomTableRow key={workIndex}>
                        <LocationCells data-test-id="sortId">{this.handleLargeDetails(reminderDetails.location)}</LocationCells>
                        <CategoryCell>{this.handleLargeDetails(reminderDetails.category)}</CategoryCell>
                        <StatusCell>
                          <ColoringStatus data-test-id="colorsId" sx={{ color: this.handleColoring(reminderDetails.due_date) }}>
                            {reminderDetails.due_date}
                          </ColoringStatus>
                        </StatusCell>
                        <StatusCell>
                          {this.handleLargeDetails(reminderDetails.status)}
                        </StatusCell>
                        <StatusCell>{this.handleLargeDetails(reminderDetails.assignee)}</StatusCell>
                        <ViewCell>
                          <IconButton data-test-id="menuIconsId" onClick={(menus) => { this.handleMenuIconClick(menus, reminderDetails.id) }}>
                            <MoreVert />
                          </IconButton>
                        </ViewCell>
                      </CustomTableRow>
                      <Menu
                        data-test-id="menuIconCloseId"
                        anchorEl={this.state.anchorsElement[reminderDetails.id]}
                        keepMounted
                        open={Boolean(this.state.anchorsElement[reminderDetails.id])}
                        onClose={() => this.handleMenuClose(reminderDetails.id)}
                        elevation={0}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                      >
                        <CustomNewMenusItem data-test-id="completedId" onClick={() => this.handleCompleted(reminderDetails.id, "Completed")}>
                          <ListItemIcon>
                            <img src={doneIcon} alt="editorDoneIcon" />
                          </ListItemIcon>
                          <ListItemText primaryTypographyProps={{ fontSize: "14px" }} primary={webConfigJSON.done} />
                        </CustomNewMenusItem>
                        <CustomNewMenusItem data-test-id="openEditWorkId" onClick={() => this.handleReminderRouting(reminderDetails.id, "OpenReminders")} >
                          <ListItemIcon>
                            <img src={workIcon} alt="editorWorkIcon" />
                          </ListItemIcon>
                          <ListItemText data-test-id="openWorkId" primaryTypographyProps={{ fontSize: "14px" }} primary={webConfigJSON.openOrder}/>
                        </CustomNewMenusItem>
                        <Divider />
                        <CustomNewMenusItem data-test-id="udatesWorkId" onClick={() => this.handleReminderRouting(reminderDetails.id, "EditReminders")} >
                          <ListItemIcon>
                            <img src={editIcon} alt="editorsIcon" />
                          </ListItemIcon>
                          <ListItemText data-test-id="updatesWorksId" primaryTypographyProps={{ fontSize: "14px" }} primary={webConfigJSON.editWorks} />
                        </CustomNewMenusItem>
                        <CustomNewMenusItem data-test-id="declinesStatusId"  onClick={() => this.handleCompleted(reminderDetails.id, "Rejected")} >
                          <ListItemIcon>
                            <img src={declineIcon} alt="editorsIcon" />
                          </ListItemIcon>
                          <ListItemText primaryTypographyProps={{ fontSize: "14px" }} primary={webConfigJSON.rejected}/>
                        </CustomNewMenusItem>
                      </Menu>
                    </React.Fragment>
                  )
                })}
              </CustomContainer>
            </Table>
          </CustomTableContainer>
          {this.state.totalReminder >= 2 && (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} m={"1rem 0rem 2rem 0rem"}>
              <CustomPagination count={this.state.totalReminder} page={this.state.currentReminder} onChange={this.handleChangeReminderPagination} />
              <CustomPage variant="body1" data-test-id="attachLengthId">{this.state.currentReminder}-{this.state.totalReminder} {webConfigJSON.totalOf} {this.state.countReminder} {webConfigJSON.results}</CustomPage>
        </Box>
          )}
          </>)}
        </Box>
      </Box>
    );
  };

  renderPreferredVendor = () => {
    return (
      <Box>
        <Grid container spacing={1} display={"flex"} alignItems={"center"} mt={"1rem"}>
          <Grid item md={4} xs={12} justifyContent="flex-start">
            <Box className="search-main-content">
            <Box data-test-id="remindersId" className="new-property" onClick={this.handleClosePreferredVendors}>
                <img
                  className="icon"
                  src={plus} alt="plusIcon" />
                <Typography className="new-data">{webConfigJSON.addVendors}</Typography>
               </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className="search-contents">
              <img
                className="search-icons"
                src={SearchBarIcon} alt="locationsSearch" />
              <InputBase
                data-test-id="vendorSearchsId"
                value={this.state.searchVendor}
                onChange={(searchEvent) => this.handleVendorSearchBar(searchEvent)}
                inputProps={{ "aria-label": "search" }}
                placeholder="Search"
                className="search-inputs"
              />
            </Box>
          </Grid>
        </Grid>
        <PreferredBox>
          <Box width= {"100%"}  mt={"1rem"}>
          {this.state.searchVendor !== "" && this.state.preferredVendor.length === 0 ? (
                this.renderEmptyTable("No Preferred Vendor Found")
              ) : (
                <>
            <CustomTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <DetailsCell>{webConfigJSON.companyName}</DetailsCell>
                    <DetailsCell>{webConfigJSON.vendorType}</DetailsCell>
                    <DetailsCell>{webConfigJSON.ownerName}</DetailsCell>
                    <DetailsCells>{webConfigJSON.email}</DetailsCells>
                  </TableRow>
                </TableHead>
                <CustomContainer>
                  {this.state.preferredVendor.map((vendors: PreferredVendorDetails, vendorIndex: number) => {
                    return (
                      <React.Fragment key={vendorIndex}>
                        <CustomTableRow>
                        <LocationCells data-test-id="sortId">{this.handleLargeDetails(vendors.company_name)}</LocationCells>
                        <StatusCell>{this.handleLargeDetails(vendors.vendor_type)}</StatusCell>
                        <StatusCell>{this.handleLargeDetails(vendors.name)}</StatusCell>
                        <StatusCell>{this.handleLargeDetails(vendors.email)}</StatusCell>
                      </CustomTableRow>
                    </React.Fragment>
                    )
                  })}
                </CustomContainer>
              </Table>
            </CustomTableContainer>
            {this.state.totalVendor >= 2 && (
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} m={"1rem 0rem 2rem 0rem"}>
                <CustomPagination count={this.state.totalVendor} page={this.state.currentVendor} onChange={this.handleChangeVendorPagination} />
                <CustomPage variant="body1" data-test-id="attachLengthId">{this.state.currentVendor}-{this.state.totalVendor} {webConfigJSON.totalOf} {this.state.countVendor} {webConfigJSON.results}</CustomPage>
              </Box>)}
              </>)}
          <Box data-test-id="remindersId" className="new-properties" onClick={()=> this.handleDashboardRoutes(webConfigJSON.prevendorlandlabel)}>
              <img
                className="icon"
                src={eyeIcon} alt="plusIcon" />
              <Typography className="new-data">{webConfigJSON.viewAll}</Typography>
            </Box>
          </Box>
        </PreferredBox>
        <CustomModal
          data-test-id="venListModalsId"
          open={this.state.preferredVendorsModal}
        >
          <ParagraphContainers>
            {this.renderPopup()}
            <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"} width={"100%"}>
              <CloseModals onClick={this.handleClosePreferredVendors}>
                <Close />
              </CloseModals>
              <KeyContainers>
                <KeyHeading data-test-id="addNewVendorId" onClick={this.handleAddNewVendors}>
                  <img
                    className="icon"
                    src={AddPlusIcon} alt="plus" />
                  {webConfigJSON.addNewVendors}
                </KeyHeading>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"} alignItems={"center"} width={"100%"}>
                  <CustomChildTableContainers>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <DetailsCell>{webConfigJSON.companyName}</DetailsCell>
                          <DetailsCell>{webConfigJSON.types}</DetailsCell>
                          <DetailsCell>{webConfigJSON.ownerName}</DetailsCell>
                          <DetailsCell>{webConfigJSON.email}</DetailsCell>
                          <DetailsCell>{webConfigJSON.vendorPhoneNumber}</DetailsCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <CustomContainer>
                        {this.state.existingOldVendors.map((oldVendors: ExistingVendorDetails, oldVendorsIndex: number) => {
                          return (
                            <React.Fragment key={oldVendorsIndex}>
                              <TableRow>
                                <StatusCell data-test-id="sortId">{this.handleLargeDetails(oldVendors.company_name)}</StatusCell>
                                <StatusCell>{this.handleLargeDetails(oldVendors.vendor_type)}</StatusCell>
                                <StatusCell>{this.handleLargeDetails(oldVendors.name)}</StatusCell>
                                <StatusCell>{this.handleLargeDetails(oldVendors.email_address)}</StatusCell>
                                <StatusCell>{this.handleLargeDetails(oldVendors.phone_number)}</StatusCell>
                                <ViewCell>
                                  <AddButtons disabled={this.state.disabledVendorsStates[oldVendors.id] || false} data-test-id="addButtonsId" onClick={() => this.handleAddExistingVendor(oldVendors.id)}>
                                    {webConfigJSON.addExistingButtons}
                                  </AddButtons>
                                </ViewCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </CustomContainer>
                    </Table>
                  </CustomChildTableContainers>
                </Box>
              </KeyContainers>
            </Box>
          </ParagraphContainers>
        </CustomModal>
      </Box>
    );
  };

  renderDeliquencyReport = () => {
    return (
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"} mt={"2rem"}>
        <TableContainer
          sx={{
            maxWidth: '100%',
            maxHeight: "400px",
            overflow: "auto",
            textAlign: "center"
          }}>
          <Table
           sx={{ borderCollapse: 'separate' }}  size="small">
            <TableHead>
              <TableRow>
                <PropertyTableCell align='left'>{webConfigJSON.propertiesName}</PropertyTableCell>
                <PaidTableCell align='center'><PaidTypography>{webConfigJSON.paid}</PaidTypography></PaidTableCell>
                <PaidTableCell align='center'><PaidOver>{webConfigJSON.latePaid}<br />{webConfigJSON.lateSecond}</PaidOver></PaidTableCell>
                <PaidTableCell align='center'><PaidLate>{webConfigJSON.sixtyLate}<br />{webConfigJSON.lateSecond}</PaidLate></PaidTableCell>
                <VacantTableCell align='center'>{webConfigJSON.vacant}</VacantTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.delinquencyData.map((reports: DelinquencyData, reportsIndex: number) => (
                <>
                <TableRow
                  key={reportsIndex}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    '& > *': {
                      fontSize: '12px',
                      textAlign: 'center',
                      borderTop: '1px solid #D1CBC7',
                      borderBottom: '1px solid #D1CBC7'
                    },
                  }}
                >
                  <NameTableCell align='left'>{this.handleLargeDetails(reports.property_name)}</NameTableCell>
                  <TablePadding align='center'><PaidColor>{reports.paid_count}</PaidColor></TablePadding>
                  <TablePadding align='center'><LatePaid>{reports.payment_0_to_30_days_late}</LatePaid></TablePadding>
                  <TablePadding align='center'><PaidOvers>{reports.payment_over_0_to_30_days_late}</PaidOvers></TablePadding>
                  <CountTableCell align='center'>{reports.vacant_count}</CountTableCell>
              </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}></TableCell>
                    <PaidTablesCell><PaidBlank></PaidBlank></PaidTablesCell>
                    <PaidTablesCell><LateBlank></LateBlank></PaidTablesCell>
                    <PaidTablesCell><LatePlusBlank></LatePlusBlank></PaidTablesCell>
                  </TableRow>
                </>
              ))}
              <TableRow
               sx={{
                background: "#FFF1CC66",
                '& > *': {
                  fontWeight: 'bold',
                  background: "#FFF1CC66",
                  fontSize: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #D1CBC7',
                  borderTop: '1px solid #D1CBC7'
                },
              }}>
                <TableCell align='center' sx={{ padding: 0, fontWeight: 700,borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" , fontFamily: "Outfit", fontSize: "12px", width: "97px",borderLeft: "1px solid #D1CBC7", whiteSpace: "nowrap"}}>{webConfigJSON.totalPaid}</TableCell>
                <TableCell align='center' sx={{ padding: 0, fontWeight: 'bold'}}><TopHeading>{this.state.total.paid_count}/{this.state.total.total_count}</TopHeading></TableCell>
                <TableCell align='center' sx={{ padding: 0, fontWeight: 'bold' }}><TopSecondHeading>{this.state.total.payment_0_to_30_days_late}/{this.state.total.total_count}</TopSecondHeading></TableCell>
                <TableCell align='center' sx={{ padding: 0, borderTopRightRadius: "8px", borderBottomRightRadius: "8px", borderRight: "1px solid #D1CBC7"}}><TopThirdHeading>{this.state.total.payment_over_0_to_30_days_late}/{this.state.total.total_count}</TopThirdHeading></TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell ></TableCell>
                <TablePadding><BelowBlank></BelowBlank></TablePadding>
                <TablePadding><PaidBelow></PaidBelow></TablePadding>
                <TablePadding><OverBelow></OverBelow></TablePadding>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Headings textAlign={"left"} width={"100%"} mt={"5rem"}>{webConfigJSON.renewalOutlook}</Headings>
        <Grid container width={"90%"} spacing={1} mt={1}>
          <Grid item lg={4} md={4} xs={12}>
            <CustomCard>
              <CustomCarContent>
                <CustomTypography>
                  {webConfigJSON.lessThan}
                </CustomTypography>
                <CustomUser>
                  {this.state.renewalOutLook.less_than_30}
                </CustomUser>
              </CustomCarContent>
            </CustomCard>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CustomCard>
              <CustomCarContent>
                <CustomTypography>
                  {webConfigJSON.betweenMore}
                </CustomTypography>
                <CustomUser>
                {this.state.renewalOutLook.between_30_to_60}
                </CustomUser>
              </CustomCarContent>
            </CustomCard>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CustomCard>
              <CustomCarContent>
                <CustomTypography>
                  {webConfigJSON.betweenLate}
                </CustomTypography>
                <CustomUser>
                {this.state.renewalOutLook.between_60_to_90}
                </CustomUser>
              </CustomCarContent>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
    );
  };

  renderPopup = () => {
    return (
      <PopupMessage
      data-test-id="popupId"
      open={this.state.messagesPopup}
      type={this.state.messagesType}
      message={this.state.messagesForPopup}
      handleClose={this.handleMessagesPopupClose}
    />
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <WorkOrderStyle>
        <NavBarArea>
            <NavigationSidebar
              data-test-id={"sidebarMenuId"}
              activeItem={this.state.activeMainItem}
              sidebarItems={[
                { label: webConfigJSON.dashboardLandloard, labelKey: webConfigJSON.dashboardLandlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: webConfigJSON.dashboardLandlabel },
                { label: webConfigJSON.myAccountlandloard, labelKey: webConfigJSON.myAccountlandlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: webConfigJSON.myAccountlandlabel },
                { label: webConfigJSON.worklandloard, labelKey: webConfigJSON.workOrderslandlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: webConfigJSON.workOrderslandlabel },
                { label: webConfigJSON.preferredlandloard, labelKey: webConfigJSON.prevendorlandlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: webConfigJSON.prevendorlandlabel },
                { label: webConfigJSON.serviceOrder, labelKey: webConfigJSON.serviceOrderlabel, icon: serviceInactive, activeIcon:Service , pathName: webConfigJSON.serviceOrderlabel },
                { label: webConfigJSON.reminderslandloard, labelKey: webConfigJSON.reminlandlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: webConfigJSON.reminlandlabel },
                { label: webConfigJSON.myproplandloard, labelKey: webConfigJSON.myproplandlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: webConfigJSON.myproplandlabel },
                { label: webConfigJSON.financialandloard, labelKey: webConfigJSON.financlandlabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: webConfigJSON.financlandlabel },
                { label: webConfigJSON.reportlandloard, labelKey: webConfigJSON.reportlandlabel, icon: reportMain, activeIcon: activeMainReport, pathName: webConfigJSON.reportlandlabel },
                { label: webConfigJSON.docLandlord, labelKey: webConfigJSON.docLandlordLabel, icon: documents, activeIcon: documentActive, pathName: webConfigJSON.docLandlord },
                { label: webConfigJSON.chatLandloard, labelKey: webConfigJSON.chatlandlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: webConfigJSON.chatlandlabel },
              ]}
              onClickSidebar={this.handleMainItemClick}
              keasylogo={keasyIcon}
              openDrawer={this.state.openLandlordDrawer}
              onClickDrawer={this.handleLandlordDrawer}
            />
            <Box className="landlordDashboardMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
            <CustomNavbar navigation={this.props.navigation} id="landlordDashboardNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>

        </NavBarArea>
          <TaskArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
          <MainWorkContainer>
            <WorkStyles>
            <Box className="landlordDashboardNavbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>

            <CustomNavbar
              id={""} {...this.props}
              showChatButton={true}
              showPropertiesButton={true}     
              /></Box>
              <Box className="contentContainer">
              <WorkOrderHead data-test-id="seeTextId">
                {webConfigJSON.dashboardHeading}
              </WorkOrderHead>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.deliquencyReport}</Headings>
                      {this.state.delinquencyData.length > 0 ?
                        this.renderDeliquencyReport()
                        :
                        this.renderEmptyDetails(webConfigJSON.reportlandlabel)
                      }
                    </BorderContainer>
                  </Grid>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.worksOrders}</Headings>
                      {this.state.workOrderLength ?
                        this.renderWorkOrder()
                        :
                        this.renderEmptyDetails(webConfigJSON.workOrderslandlabel)
                      }
                    </BorderContainer>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.remindersDetails}</Headings>
                      {this.state.reminderLength ?
                        this.renderReminders()
                        :
                        this.renderEmptyDetails(webConfigJSON.reminlandlabel)
                      }
                    </BorderContainer>
                  </Grid>
                  <Grid item xl={6} lg={6} sm={12} xs={12}>
                    <BorderContainer>
                      <Headings>{webConfigJSON.preferreds}</Headings>
                      {this.state.preferredVendor.length === 0 && this.state.searchVendor === "" ?
                        this.renderEmptyDetails(webConfigJSON.prevendorlandlabel)
                        :
                        this.renderPreferredVendor()
                      }
                      <Box display={"none"}>
                        {this.renderDeliquencyReport()}
                        {this.renderReminders()}
                        {this.renderPreferredVendor()}
                        {this.renderWorkOrder()}
                      </Box>
                    </BorderContainer>
                  </Grid>
                </Grid>
              </Box>
                {this.renderPopup()}
                </Box>
            </WorkStyles>
            <Box className="bgImgWrapper" data-test-id="bgImgId">
              <Box className="backgroundImage">
                <img src={bottomImageIcon} alt="hexagon-one" className="hexImg" />
              </Box>
            </Box>
          </MainWorkContainer>
        </TaskArea>
      </WorkOrderStyle>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const WorkOrderStyle = styled(Box)({
  justifyContent: "space-between",
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    fontFamily: "Outfit",
    backgroundColor: "red",
  },
  "& .bgImgWrapper": {
    justifyContent: "flex-end",
    width: "100%",
    fontFamily: "Outfit",
    marginTop: "2rem",
    display: "flex",
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    fontFamily: "Outfit",
    textAlign: "end",
    width: "100%",
    position: "absolute"
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const NavBarArea = styled(Box)({ 
  position: "fixed",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 1000,
  overflowY: "auto",
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": { 
    position: "unset",
    height: "auto",
    border: "none",
    overflowY: "hidden", display: "flex", "paddingTop": "24px",
  }
});

const TaskArea = styled(Box)({
  overflowY: "auto",
  overflowX: "hidden",
  flexGrow: 1,
  marginLeft: "120px",
  "@media(max-width: 992px)": {
    overflowY: "unset",
    marginLeft: "0px",
    height: "auto",
  }
});

const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"
  }
});

const WorkOrderHead = styled(Typography)({
  color: "#000000",
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "20px",
  fontFamily: "Outfit",
});

const WorkStyles = styled(Box)({
  margin: "0px 0px 42px 0px",
  width: "calc(100% - 30px)",
  paddingLeft: "30px",
 "@media (max-width: 992px)": {
   width: "calc(100% - 32px)",
   paddingLeft: "16px",
   paddingRight: "16px",
 },
 "& .contentContainer": {
  paddingRight: "30px",
 "@media (max-width: 992px)": {
   paddingRight: 0,
 },
 },
  "& .search-main-content": {
    display: "flex",
    cursor: "pointer",
    gap: "1rem",
    fontFamily: "Outfit",
    "@media(max-width:600px)": {
      width: "100%",
      flexDirection: "row",
      height: "100%",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    borderRadius: "50px",
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    padding: "2px 6px 2px 6px",
    border: "1px solid #CBD5E1",
    height: "32px",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Outfit",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "140px",
    justifyContent: "space-around",
    height: "40px",
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    fontFamily: "Outfit",
    borderRadius: "8px",
    padding: "2px",
    display: "flex",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      width: "105px",
      height: "30px",
      fontSize: "12px",
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "14px",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#0F172A",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    color: "#CC9200",
    fontSize: "16px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    gap: "5px",
    fontFamily: "Outfit",
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: "8px",
    cursor: "pointer",
    alignItems: "center",
  },
  "& .new-vendor": {
    backgroundColor: "transparent",
    textTransform: "none",
    fontFamily: "Outfit",
    display: "flex",
    gap: "5px",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  "& .filter-content": {
    display: "flex",
    fontFamily: "Outfit",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .filter": {
    fontFamily: "Outfit",
    alignItems: "center",
    display: "flex",
    gap: "15px",
  },
  "& .new-properties": {
    gap: "5px",
    fontFamily: "Outfit",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "35px",
    cursor: "pointer",
    borderRadius: "8px",
    alignItems: "center",
  },
  "& .filter-data": {
    fontWeight: 700,
    fontFamily: "Outfit",
    fontSize: "12px",
    color: "#000000",
    whiteSpace: "nowrap"
  },
  "& .clear-data": {
    color: "#CC9200",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 700,
    cursor: "pointer",
    whiteSpace: "nowrap"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  height: "694px",
  padding: "1rem 1rem 0rem 1rem",
  flexDirection: "column",
  marginTop: "1rem",
  overflow: "auto",
  borderRadius: "8px 8px 32px 8px",
  display: "flex",
  "& .scrollable-container": {
    fontFamily: "Outfit",
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  },
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
});

const Headings = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Outfit"
});

const NothingSee = styled(Typography)({
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Outfit",
  color: "#CC9200"
});

const EmptyDetails = styled(Typography)({
  color: "#70645C",
  fontSize: "16px",
  fontWeight: 400,
  textAlign: "center",
  fontFamily: "Outfit"
});

const StartBtn = styled(Button)({
  width: "fit-content",
  height: "44px",
  fontSize: '16px',
  borderRadius: "8px",
  backgroundColor: "#FFC123",
  color: "#000000",
  textTransform: "none",
  marginRight: "25px",
  fontWeight: 700,
  cursor: "pointer",
  fontFamily: "Outfit, sans-serif",
  "&:hover": {
    backgroundColor: "#FFC123",
  },
  "@media (max-width:600px)": {
    marginRight: "0px",
  }
});

const CustomTableContainer = styled(TableContainer)({
  backgroundColor: "#FFFFFF",
  "&.isUnitsDisabled": {
    display: "none"
  }
});

const CustomContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const DetailsCell = styled(TableCell)({
  color: "#A3978F",
  fontWeight: 700,
  fontSize: "12px",
  textAlign: "left",
  whiteSpace: "nowrap",
});

const DetailsCells = styled(TableCell)({
  fontWeight: 700,
  fontSize: "12px",
  textAlign: "left",
  color: "#A3978F",
  whiteSpace: "nowrap",
});

const ViewCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 400,
  textAlign: "left",
  cursor: "pointer",
  color: "#000000",
});

const StatusCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  textAlign: "left",
  fontFamily: "Outfit",
  color: "#000000",
  '&::first-letter': {
    textTransform: 'capitalize',
  },
});

const CategoryCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  textAlign: "center",
  color: "#000000",
});

const StatusCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  textAlign: "left",
  fontFamily: "Outfit",
  color: "#000000",
});

const LocationCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 700,
  textAlign: "left",
  fontFamily: "Outfit",
  color: "#1C1917",
  '&::first-letter': {
    textTransform: 'capitalize',
  },
});

const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  textAlign: "center",
  fontFamily: "Outfit",
  whiteSpace: "nowrap",
  padding: "3px 8px",
  fontWeight: 700,
  fontSize: "9px",
  width: "fit-content"
});

const CustomCells = styled(TableCell)({
  color: "#A3978F",
  fontWeight: 700,
  whiteSpace: "nowrap",
  fontSize: "12px",
  padding: "0px 10px 0px 10px !important",
  textAlign: "center"
});

const CustomButtonsUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomButtonsDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const ColoringStatus = styled(Typography)({
  color: "#000000",
  textAlign: "center",
  fontFamily: "Outfit",
  whiteSpace: "nowrap",
  fontWeight: 400,
  fontSize: "12px"
});

const PreferredBox = styled(Box)({
  marginTop: "0rem"
});

const CustomPage = styled(Typography)({
  color: "#70645C",
  fontFamily: "Outfit",
  fontSize: "12px",
  fontWeight: 400,
});

const CustomPagination = styled(Pagination)({
  textAlign: "center",
  margin: "auto",
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  "& .MuiPaginationItem-page": {
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "Outfit !important",
    "@media(max-width: 360px)": {
      fontSize: "10px !important",
    },
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "white",
    color: "#CC9200",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 400,
    "@media(max-width: 360px)": {
      fontSize: "10px",
    },
  },
});

const CustomTableRow = styled(TableRow)({
  "&:last-child td": {
    borderBottom: "none",
  }
});

const CustomNewMenusItem = styled(MenuItem)({
  fontSize: "12px",
  fontFamily: "Outfit",
  color: "#000000",
  fontWeight: 400,
  "&.changeColor": {
    fontFamily: "Outfit",
    backgroundColor: "rgb(250,227,162)",
    borderRadius: "10px"
  },
  "&:hover": {
    color: "#000000",
    backgroundColor: "rgb(250,227,162)",
  },
  "@media(max-width: 475px)": {
    minHeight: "25px !important"
  }
});

const CustomModal = styled(Modal)({
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});

const ParagraphContainers = styled(Box)({
  backgroundColor: "white",
  width: "80%",
  fontFamily: "Outfit",
  padding: "25px 20px 80px 20px",
  height: "380px",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "25px",
   "@media(max-width: 1260px)": {
    width: "100%"
  },
  "@media(max-width: 992px)": {
    width: "97%"
  },
  "@media(max-width: 767px)": {
    width: "97%"
  },
  "@media(max-width: 600px)": {
    gap: "15px",
  },
  "@media(max-width: 475px)": {
    gap: "12px",
    height: "400px"
  },
  "@media(max-width: 466px)": {
    gap: "10px"
  }
});

const CloseModals = styled(Box)({
  display: "flex",
  cursor: "pointer",
  justifyContent: "flex-end",
  fontFamily: "Outfit",
});

const KeyContainers = styled(Box)({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Outfit",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
  "& .properties-dropdown": {
    width: "100%",
    fontFamily: "Outfit",
    color: "#000000",
    justifyContent: "space-around",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    cursor: "pointer",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    height: "44px",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      height: "30px",
      width: "105px",
      fontSize: "12px",
    }
  },
});

const KeyHeading = styled(Button)({
  backgroundColor: "rgb(253,242,208)",
  alignItems: "center",
  color: "#000000",
  fontWeight: 700,
  width: "fit-content",
  gap: "5px",
  textTransform: "none",
  fontSize: "16px",
  textAlign: "right",
  display: "flex",
  justifyContent: "center",
  fontFamily: "Outfit",
  "&:hover": {
    backgroundColor: "rgb(253,242,208)",
    color: "#000000"
  },
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const CustomChildTableContainers = styled(TableBody)({
  backgroundColor: "#FFFFFF",
  overflow: "auto",
  borderRadius: "8px",
  height: "300px",
  border: "1px solid #FFD466",
  width: "100%",
  "&.isUnitsDisabled": {
    display: "none"
  }
});

const AddButtons = styled(Button)({
  width: "fit-content",
  backgroundColor: "#FFC123",
  color: "#000000",
  borderRadius: "8px",
  textTransform: "none",
  height: "30px",
  "&:hover": {
    backgroundColor: "#FFC123",
    color: "#000000",
  }
});

const CustomCard = styled(Card)({
  width: "160px",
  "@media(max-width: 992px)": {
    width: "222px"
  }
});

const CustomCarContent = styled(CardContent)({
  borderRadius: "6px",
  borderLeft: "5px solid #FFC533",
  paddingTop: "5px",
  paddingBottom: "5px !important"
});

const CustomTypography = styled(Typography)({
  color: "#0F172A",
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Outfit"
});
const CustomUser = styled(Typography)({
  color: "#000000",
  fontSize: "30px",
  fontWeight: 700,
  fontFamily: "Outfit"
});

const PropertyTableCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "Outfit",
  backgroundColor: "#FFFFFF",
  border: "none",
  padding: "0px 12px"
});

const PaidTableCell = styled(TableCell)({
  fontWeight: "bold",
  border: "none",
  padding: "0px"
});

const VacantTableCell = styled(TableCell)({
   fontWeight: 700,
   fontSize: "12px",
   fontFamily: "Outfit",
   border: "none"
});

const PaidTypography = styled(Typography)({
  width: "60px", 
  fontFamily: "Outfit",
  padding: "12px 15px", 
  fontSize: "12px",
  fontWeight: 700,
  background: "#D1FAE5", 
  borderTopLeftRadius: "12px", 
  borderTopRightRadius: "12px",
  "@media(max-width: 475px)": {
    width: "30px"
  }
});

const PaidOver = styled(Typography)({
   width: "60px", 
   padding: "3px 15px", 
   background: "#FEF3C7", 
   borderTopLeftRadius: "12px", 
   borderTopRightRadius: "12px",
   fontSize: "12px",
   fontWeight: 700,
   fontFamily: "Outfit",
   "@media(max-width: 475px)": {
    width: "30px"
  }
});

const PaidLate = styled(Typography)({
   width: "60px", 
   padding: "3px 15px", 
   background: "#FFE299", 
   borderTopLeftRadius: "12px", 
   borderTopRightRadius: "12px",
   fontFamily: "Outfit",
   fontSize: "12px",
   fontWeight: 700,
   "@media(max-width: 475px)": {
    width: "30px"
  }
});

const NameTableCell = styled(TableCell)({
  padding: "0px 12px", 
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "12px",
  borderLeft: "1px solid #D1CBC7", 
  borderTopLeftRadius: "8px", 
  borderBottomLeftRadius: "8px"
});

const TablePadding = styled(TableCell)({
  padding: "0px"
});

const CountTableCell = styled(TableCell)({
  padding: "0px",
  fontFamily: "Outfit", 
  fontSize: "12px", 
  fontWeight: 400,
  borderRight: "1px solid #D1CBC7",
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px"
});

const PaidTablesCell = styled(TableCell)({
  border: "none",
  padding: "0px"
});

const TopHeading = styled(Typography)({
  width: "60px",
  background: "#D1FAE5", 
  padding: "6px 15px", 
  fontFamily: "Outfit", 
  fontSize: "12px", 
  fontWeight: 400,
  "@media(max-width: 475px)": {
    width: "30px"
  } 
});

const TopSecondHeading = styled(Typography)({
  width: "60px", 
  background: "#FEF3C7", 
  fontFamily: "Outfit", 
  fontSize: "12px", 
  fontWeight: 400, 
  padding: "6px 15px",
  "@media(max-width: 475px)": {
    width: "30px"
  } 
});

const TopThirdHeading = styled(Typography)({
  width: "60px", 
  background: "#FFE299", 
  padding: "6px 15px", 
  fontFamily: "Outfit", 
  fontSize: "12px",
  fontWeight: 400,
  "@media(max-width: 475px)": {
    width: "30px"
  } 
});

const PaidColor = styled(Typography)({
  width: "74px", 
  background: "#D1FAE5", 
  padding: "6px 8px", 
  fontFamily: "Outfit", 
  fontSize: "12px", 
  fontWeight: 400,
  "@media(max-width: 475px)": {
    width: "44px" 
  }
});

const LatePaid = styled(Typography)({
  width: "74px", 
  fontFamily: "Outfit", 
  fontSize: "12px", 
  fontWeight: 400, 
  background: "#FEF3C7", 
  padding: "6px 8px",
  "@media(max-width: 475px)": {
    width: "44px" 
  }
});

const PaidOvers = styled(Typography)({
  fontFamily: "Outfit", 
  fontSize: "12px", 
  fontWeight: 400, 
  width: "74px", 
  background: "#FFE299", 
  padding: "6px 8px",
  "@media(max-width: 475px)": {
    width: "44px" 
  }
});

const PaidBlank = styled(Typography)({
  width: "74px", 
  background: "#D1FAE5", 
  padding: "6px 8px",
  "@media(max-width: 475px)": {
    width: "44px" 
  }
});

const LateBlank = styled(Typography)({
  width: "74px", 
  background: "#FEF3C7", 
  padding: "6px 8px",
  "@media(max-width: 475px)": {
    width: "44px" 
  }
});

const LatePlusBlank = styled(Typography)({
  width: "74px", 
  background: "#FFE299", 
  padding: "6px 8px",
  "@media(max-width: 475px)": {
    width: "44px" 
  }
});

const BelowBlank = styled(Typography)({
  width: "90px", 
  background: "#D1FAE5", 
  padding: "10px 0px", 
  borderBottomLeftRadius: "12px", 
  borderBottomRightRadius: "12px",
  "@media(max-width: 475px)": {
    width: "60px"
  }
});

const PaidBelow = styled(Typography)({
  width: "90px", 
  background: "#FEF3C7", 
  padding: "10px 0px", 
  borderBottomLeftRadius: "12px", 
  borderBottomRightRadius: "12px",
  "@media(max-width: 475px)": {
    width: "60px"
  }
});

const OverBelow = styled(Typography)({
  width: "90px", 
  background: "#FFE299", 
  padding: "10px 0px", 
  borderBottomLeftRadius: "12px", 
  borderBottomRightRadius: "12px",
  "@media(max-width: 475px)": {
    width: "60px"
  }
});

// Customizable Area End
