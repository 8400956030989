import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Checkbox,
  Stack,
  TextField,
} from "@mui/material";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/system";
import PopupMessage from "../../../components/src/PopupMessage.web";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import CustomInputField from "../../../components/src/CustomInputField.web";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";

const SignupStyling = styled(Box)({
  "& .container": {
    display: "flex",
    fontFamily: "Outfit, sans-serif",
    overflow: "hidden",
    height: "100vh",
    backgroundColor: "#ffffff",
    width: "100%",
    position: "relative",
    flexDirection: "row",
    backgroundImage: `url(${require("../assets/image_background_keasyforms.svg")})`,
    backgroundSize: "922px 1510.14px",
    backgroundPosition: "62% 52%",
    backgroundRepeat: "no-repeat",
    zIndex: 10,
    "@media (max-width:1100px)": {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media (max-width:600px)": {
      width: "100%",
      height: "100%",
      position: "relative",
      overflowX: "hidden",
      flexDirection: "column",
      backgroundImage: `url(${require("../assets/backimage_keasyforms.svg")})`,
      backgroundSize: "100%",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      justifyContent: "left",
    },
  },
  "& .carousel_container": {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1.3,
    zIndex: 20,
    "@media (max-width:1100px)": {
      display: "none",
    },
  },
  "& .password_error_message": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "22px",
    color: "#000",
  },
  "& .password_error_icon": {
    fontSize: "16px",
    color: "#FF0000",
  },
  "& .password_sucess_icon": {
    fontSize: "16px",
    color: "#32CD32",
  },
  "& .password_error_wrapper": {
    marginTop: "32px",
  },
  "& .signup_form_container": {
    margin: "30px 10rem 5rem 5rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "1.5rem 0 1.5rem 0",
    flex: 0.7,
    height: "100%",
    "@media (max-width:1100px)": {
      width: "327px",
      gap: "1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      margin: "0rem",
    },
    "@media (max-height:800px)": {
      margin: "0px",
    }
  },
  "& .logo": {
    alignItems: "center",
    display: "flex",
    width: "354px",
    justifyContent: "center",
    "@media (maxWidth:600px)": {
      width: "327px",
    },
  },
  "& .logo_wrapper": {
    width: "210.46px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .privacy_policy": {
    width: "300px",
  },
  "& .image_image_keasylogo": {
    backgroundColor: "##",
    width: "39px",
    height: "71px",
  },
  "& .signup_subheading": {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    paddingBottom: "20px",
    "@media (max-height:800px)": {
      fontSize: "16px",
      lineHeight: "20px",
    }
  },
  "& .imageView_image_keasyfont": {
    top: "12px",
  },
  "& .image_image_keasyfont": {
    width: "154px",
    height: "38px",
  },
  "& .signup_heading": {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    marginBottom: "10px",
    "@media (max-height:800px)": {
      marginBottom: "0px",
    }
  },
  "& .errorMessage": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#DC2626",
    padding: "12px 16px 12px 16px",
    backgroundColor: "#FEE2E2",
    borderLeft: "4px solid #DC2626",
    borderRadius: "4px",
    marginBottom: "20px",
    maxWidth: "315px",
  },
  "& .errorMessageInput": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#DC2626",
  },
  "& .signup_form_formik": {
    padding: "40px 40px 5px 40px",
    scrollbarWidth: "none",
    MsOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-height:800px)": {
      padding: "0 40px",
    }
  },
  "& .input_chackbox_wrapper": {
    margin: "32 0px 10px -10px ",
    "@media (max-height:800px)": {
      width: "100%",
      margin: "16px 0px 10px -10px ",
    }
  },
  "& .checkbox": {
    padding: "0px 0px 10px 10px ",
  },
  "& .signup_button": {
    height: "56px",
    width: "354px",
    borderRadius: "8px",
    backgroundColor: "#FFC123",
    color: "#000000",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "17px",
    textTransform: "none",
    "@media (max-height:800px)": {
      height: "48px",
    },
    "&:hover": {
      backgroundColor: "#FFD700",
    },
    "@media (max-width:600px)": {
      height: "48px",
      width: "100%",
    },
  },
  "& .button_wrapper": {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },
  "& .login_link_wrapper": {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 0px -100px",
  },
  "& .bottom_link": {
    color: "#CC9200",
    cursor: "pointer",
  },
  "& .social_media_icon": {
    width: "28px",
    padding: "4px",
    backgroundColor: "#A3978F",
    margin: "12px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  "& .inputContainer": {
    marginBottom: "8px"
  }
});
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

type Values = {
  fullName: string;
  email: string;
  password: string;
  agreeTerms: boolean;
};

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "CarouselDisplay",
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <SignupStyling>
        <Box className="container">
          <PopupMessage
            open={this.state.messageOpen}
            type={this.state.messageType}
            message={this.state.message}
            handleClose={this.handlePopupMessageClose}
            data-test-id="popup"
          />
          <Box className="signup_form_container">
            <Box className="logo">
              <Box className="logo_wrapper">
                <Box>
                  <img
                    className="image_image_keasylogo"
                    src={require("../assets/1317615008KmTObgfmr1gx8VdhoxvtGF.svg")}
                    alt=""
                  />
                </Box>
                <Box className="imageView_image_keasyfont">
                  <img
                    className="image_image_keasyfont"
                    src={require("../assets/1317615009KmTObgfmr1gx8VdhoxvtGF.svg")}
                    alt=""
                  />
                </Box>
              </Box>
            </Box>
            <Formik
              data-test-id="formik"
              enableReinitialize={true}
              validateOnBlur={true}
              validate={(values) => this.validatePasswordField(values.password)}
              initialValues={{
                fullName: this.state.fullName,
                email: this.state.email,
                password: this.state.password,
                agreeTerms: this.state.agreeTerms,
              }}
              validationSchema={Yup.object({
                fullName: Yup.string()
                  .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Invalid full name")
                  .required(this.requiredFieldLabel),
                email: Yup.string()
                  .email(this.invalidEmailLabel)
                  .required(this.requiredFieldLabel),
                password: Yup.string()
                  .min(8)
                  .matches(/[a-z]/)
                  .matches(/\d/)
                  .matches(/[A-Z]/)
                  .matches(/[!@#$%^&*(),.?":{}|<>]/)
                  .required(),
                agreeTerms: Yup.boolean().oneOf(
                  [true],
                  this.termsAndConditionLabel
                ),
              })}
              onSubmit={(values: Values, { setSubmitting, setTouched }) => {
                setTouched({ agreeTerms: true });
                this.handleSubmit(values, setSubmitting);
              }}
            >
              {(props) => (
                <Form className="signup_form_formik" autoComplete="off">
                  <Typography className="signup_heading">
                    {this.btnTextSignUp}
                  </Typography>
                  <Typography className="signup_subheading">
                    {this.formSubheader}
                  </Typography>

                  {!this.state.agreeTerms && props.touched.agreeTerms ? (
                    <Box
                      data-test-id="agreeTermsErrorMessage"
                      className="errorMessage"
                    >
                      {this.termsAndConditionLabel}
                    </Box>
                  ) : null}

                  <input
                    name="dummy_email"
                    type="email"
                    style={{ display: "none" }}
                    autoComplete="username"
                  />
                  <TextField
                    name="password"
                    type="password"
                    data-test-id="dummy-password"
                    autoComplete="password"
                    style={{ display: "none" }}
                  />

                  <Box className="inputContainer" data-test-id="fullNameInputContainer">
                 <CustomInputField
                    className="input-box"
                    testID="txtInputFullName"
                    name="fullName"
                    label="Full name"
                    type="text"
                    placeholder="Your full name"
                    value={props.values.fullName}
                    onChange={(event: { target: { value: string } }) => {
                      let fullName = event.target.value;
                      this.setFullName(event.target.value);
                      this.setLocalStorage();
                      props.setFieldValue("fullName", fullName);
                    }}
                    isHideMsgError={!props.errors.fullName}
                    validationMsg={props.errors.fullName}
                    ></CustomInputField>
                  </Box>
                 <Box className="inputContainer">
                 <CustomInputField
                    className="input-box"
                    testID="txtInputEmail"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Your Email"
                    value={props.values.email}
                    onChange={(event: { target: { value: string } }) => {
                      let email = event.target.value;
                      this.setEmail(event.target.value);
                      props.setFieldValue("email", email);
                      this.setLocalStorage();
                    }}
                    isHideMsgError={!props.errors.email}
                    validationMsg={props.errors.email}
                    ></CustomInputField>
                  </Box>

                  <Box className="inputContainer">
                  <CustomInputField
                    className="input-box"
                    testID="txtInputPassword"
                    name="password"
                    label="Password"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder="Your Password"
                    autoComplete="new-password"
                    value={props.values.password}
                    onChange={(event: { target: { value: string } }) => {
                      let password = event.target.value;
                      this.setPassword(event.target.value);
                      this.setLocalStorage();
                      props.setFieldValue("password", password);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id="testTogglePassword"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    ></CustomInputField>
                  </Box>

                  {!this.state.isPasswordValid ? (
                    <Box
                      data-test-id="validationBlock"
                      className="password_error_wrapper"
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneUppercaseLetter ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneUppercaseLetterRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneUppercaseLetterCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                          {this.capitalLetterError}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneLowercaseLetter ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneLowercaseLetterRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneLowercaseLetterCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                          {this.lowerCaseError}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneNumber ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneNumberRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneNumberCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                          {this.numberError}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneSpecialChar ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneSpecialCharRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneSpecialCharCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                          {this.specialCharError}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasEightCharacters ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasEightCharactersRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasEightCharactersCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                          {this.minimumCharacters}
                        </Typography>
                      </Stack>
                    </Box>
                  ) : null}
                  <Stack
                    className="input_chackbox_wrapper"
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                  >
                    <Field
                      type="checkbox"
                      data-test-id="checkBoxAgreeTerms"
                      name="agreeTerms"
                      id="agreeTerms"
                      className="checkbox"
                      checked={props.values.agreeTerms}
                      as={Checkbox}
                      onChange={(event: { target: { checked: boolean } }) => {
                        let agreeTerms = event.target.checked;
                        this.setAgreeTerms(event.target.checked);
                        this.setLocalStorage();
                        props.setFieldValue("agreeTerms", agreeTerms);
                      }}
                    />
                    <label className="privacy_policy">
                      {this.agreeLabel}&nbsp;
                      <span
                        data-test-id="privacyPolicyLink"
                        className="bottom_link"
                        onClick={this.handlePrivacyPolicy}
                      >
                        {this.labelLegalPrivacyPolicy}&nbsp;
                      </span>
                      {this.andText}&nbsp;
                      <span
                        data-test-id="termsAndConditionsLink"
                        className="bottom_link"
                        onClick={this.handleTermsConditions}
                      >
                        {this.labelLegalTermCondition}
                      </span>
                    </label>
                  </Stack>

                  <Button
                    type="submit"
                    className="signup_button"
                    data-test-id="btnSignUp"
                  >
                    {this.btnTextSignUp}
                  </Button>
                </Form>
              )}
            </Formik>
            <Box className="login_link_wrapper">
              <Typography>
                {this.labelHaveAccount}
                <span
                  data-test-id="loginLink"
                  className="bottom_link"
                  onClick={this.handleClickLogin}
                >
                  {this.loginLink}
                </span>
              </Typography>
            </Box>

            <Box className="button_wrapper">
              <Typography align="center">
                <img
                  className="social_media_icon"
                  src={require("../assets/button_facebook.svg")}
                />
                <img
                  className="social_media_icon"
                  src={require("../assets/button_instagram.svg")}
                />
                <img
                  className="social_media_icon"
                  src={require("../assets/button_twitter.svg")}
                />
              </Typography>
            </Box>
          </Box>
          <Box className="carousel_container">
            <CarouselDisplay {...this.screenProps} />
          </Box>
        </Box>
      </SignupStyling>

      // Customizable Area End
    );
  }
}
