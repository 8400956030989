import React from "react";
import {
    Grid,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Pagination,
    PaginationItem,
    Box,
    Tabs,
    Tab
  } from "@mui/material";
import { TabPanel, TabContext } from '@mui/lab';
import { tableCellClasses } from "@mui/material/TableCell";
import ImageCollection from "@mui/icons-material/Collections";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import { activeProfile, keasyIcon, profileMain, projects, activeProjects, bottomImageIcon } from "./assets";
import EditIcon from '@mui/icons-material/Edit';
import PopupMessage from "../../../components/src/PopupMessage.web";
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const approved = require("../../invoicebilling/assets/approved.png");
export const keyMainChain = require("../../invoicebilling/assets/key.png");
export const activeKeyMain = require("../../invoicebilling/assets/activeKey.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
export const configJSON = require("./config.js");

export default class UserProfileBasicBlock extends UserProfileBasicController{
    constructor(props: Props) {
        super(props);
    };
    
    render() {
        const { currentPage, rowsPerPage } = this.state;

        const paginatedRows = this.state.vendorAttachments.slice(
            (currentPage - 1) * rowsPerPage,
            currentPage * rowsPerPage
        );

        const businessLicenseDate = this.formatDate(this.state.vendorData.business_license_exp_date);
        const businessInsuranceDate = this.formatDate(this.state.vendorData.business_insurance_exp_date);
        const epaCertificateDate = this.formatDate(this.state.vendorData.epa_license_exp_date);
        const otherCertificateDate = this.formatDate(this.state.vendorData.other_certificate_exp_date);
        const stateLicenseDate = this.formatDate(this.state.vendorData.state_license_exp_date);
        const stateInsuranceDate = this.formatDate(this.state.vendorData.state_insurance_exp_date);
        const paymentPreferenceUpdatedDate = this.formatDateTime();

        return (
            <Grid container direction="row" wrap="nowrap" 
                sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
            >
                <PopupMessage
                open={this.state.messageOpen}
                type={this.state.messageType}
                message={this.state.message}
                handleClose={this.handlePopupMessageClose}
              />
              <Grid item sx={webStyles.navBarItem}>
                <NavigationSidebar
                    data-test-id={"vendorId"}
                    activeItem={this.state.activeVendorItem}
                    sidebarItems={[
                        { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                        { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                        { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                        { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                        { label: configJSON.chatOrder, labelKey: configJSON.chatOrderLabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderLabel },
                    ]}
                    onClickSidebar={this.handleVendoritemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openVendorDrawer}
                    onClickDrawer={this.handleVendorDrawer}
                />
                <Box className="vendorProfileMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
                <CustomNavbar navigation={this.props.navigation} id="vendorProfileNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>
              </Grid>
              <Grid item sx={webStyles.mainContent}>
              <Container sx={{ 
                position: "relative", 
                paddingBottom: "100px",
                paddingLeft: "30px !important",
                paddingRight: "0px !important", "@media (max-width: 992px)": {paddingRight: "16px !important",  paddingLeft: '16px !important', },
                minHeight: "100vh", }}
                maxWidth={false}
                >
                    <Box className="vendorProfileNavbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>
                    <CustomNavbar 
                        navigation={this.props.navigation}
                        id="UserProfileBasic"
                        showChatButton={true}   
                        showPropertiesButton={false} 
                    />
                    </Box><Box className="vendorProfileContainer" sx={{paddingRight: "30px", "@media (max-width: 992px)": {paddingRight: 0,},}}>
                    <Typography sx={webStyles.vendor_profile_header} paragraph>
                        Vendor Profile
                    </Typography>
                    
                    <Paper elevation={3} sx={webStyles.contentPaper}>
                        <Grid container sx={webStyles.container} spacing={2}>
                        <Grid item xs={12} md={6} sx={webStyles.section}>
                            {/* vendor information */}
                            <Box sx={webStyles.infoSection}>
                                <div style={{ display: "flex", justifyContent: "space-between"}}>
                                    <Typography sx={webStyles.section_heading} gutterBottom>
                                        Vendor information
                                    </Typography>
                                    <Button id="editVendorInfoButton" onClick={() => this.handleEditClick('vendorinfo')} variant="text" sx={webStyles.editButton}>
                                        <EditIcon sx={webStyles.icon} />
                                        Edit vendor information
                                    </Button>
                                </div>
                                <Grid container>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>Name</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>{this.state.vendorData.name}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>
                                        Vendor type
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>{this.state.vendorData.vendor_type}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>
                                        Vendor trade
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>{this.state.vendorData.vendor_trade.join(', ')}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>
                                        Minimum cost
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>${this.state.vendorData.minimum_cost}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>
                                        Monday-Friday
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>{this.state.vendorData.mon_fri_working_hours}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>
                                        Saturday
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>{this.state.vendorData.sat_working_hours}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularTextLabel}>
                                        Sunday
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>{this.state.vendorData.sun_working_hours}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* contact information */}
                            <Box sx={webStyles.infoSection}>
                            <Typography variant="h6" sx={webStyles.section_heading} gutterBottom>
                                Contact information
                            </Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Phone numbers
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>Mobile: (+{this.state.vendorData.country_code}){this.state.vendorData.phone_number}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Email address
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.email_address}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>Address</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>
                                    {this.state.vendorData.address}
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Portal activated?
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.activated? "Yes" : "No"}</Typography>
                                </Grid>
                            </Grid>
                            </Box>

                            {/* federal tax information */}
                            <Box sx={webStyles.infoSection}>
                            <Typography variant="h6" sx={webStyles.section_heading}>
                                Federal Tax information
                            </Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Taxpayer name
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.taxpayer_name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Taxpayer ID
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.taxpayer_id}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>Send 1099?</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.send_1099? "Yes" : "No"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>Form W-9</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{display: "flex"}}>
                                    <ImageCollection sx={webStyles.icon} />
                                    <Typography style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth: "54%"
                                    }}>
                                        {this.state.vendorData.form_w9[0]?.file_name}{" "}
                                    </Typography>
                                    <Button id="formW9"
                                        style={{
                                        maxHeight: "34px",
                                        marginTop: "-5px"
                                    }} onClick={() => this.downloadAttachment(this.state.vendorData.form_w9[0]?.file_url, this.state.vendorData.form_w9[0]?.file_name)} sx={webStyles.downloadButton}>
                                        Download
                                    </Button>
                                </Grid>
                            </Grid>
                            </Box>

                            <Box sx={webStyles.infoSection}>
                            {/* online payables information */}
                                <div style={{ display: "flex", justifyContent: "space-between"}}>
                                    <Typography variant="h6" sx={webStyles.section_heading} gutterBottom>
                                        Online Payables Information
                                    </Typography>
                                    <Button id="editOnlinePayablesButton" onClick={() => this.handleEditClick('onlinepayables')} variant="text" sx={webStyles.editButton}>
                                        <EditIcon sx={webStyles.icon} />
                                        Edit online payables
                                    </Button>
                                </div>
                            <Grid container>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Use online payables
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.online_payables? "Yes" : "No"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Payment type
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.payment_type}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Vendor bank routing number
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.vendor_bank_routing_number}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Vendor bank account number
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.vendor_bank_account_number}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Account type
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.account_type}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Last payment preference updated date
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{paymentPreferenceUpdatedDate}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Last payment preference updated by
                                </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularText}>{this.state.vendorData.last_payment_preference_updated_by}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                <Typography sx={webStyles.regularTextLabel}>
                                    Voided check for bank account confirmation
                                </Typography>
                                </Grid>
                                {this.state.vendorData.voided_checks.length?
                                    (<Grid item xs={6} sx={{display: "flex"}}>
                                        <ImageCollection sx={webStyles.icon} />
                                        <Typography style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: "54%"
                                        }}>
                                            {this.state.vendorData.voided_checks[0]?.file_name}{" "}
                                        </Typography>
                                        <Button id="voidedCheck"
                                            style={{
                                            maxHeight: "34px",
                                            marginTop: "-5px"
                                        }} onClick={() => this.downloadAttachment(this.state.vendorData.voided_checks[0]?.file_url, this.state.vendorData.voided_checks[0]?.file_name)} sx={webStyles.downloadButton}>
                                            Download
                                        </Button>
                                    </Grid>) : 
                                    (<Grid item xs={6} sx={{display: "flex"}}>
                                        <Typography sx={webStyles.regularText}>
                                            No attachment
                                        </Typography>
                                    </Grid>)
                                }
                            </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ paddingTop: "15px", paddingBottom: "15px", paddingLeft: "35px", '@media (max-width: 992px)': { paddingLeft: '0px' },
}}>
                            {/* compliance information */}
                            <Box sx={webStyles.infoSection}>
                                <div style={{ display: "flex", justifyContent: "space-between"}}>
                                    <Typography variant="h6" sx={webStyles.section_heading} gutterBottom>
                                        Compliance information
                                    </Typography>
                                    <Button id="editComplianceInfoButton" onClick={() => this.handleEditClick('complianceinfo')} variant="text" sx={webStyles.editButton} style={{right: "-30px"}}>
                                        <EditIcon sx={webStyles.icon} />
                                        Edit compliance information
                                    </Button>
                                </div>
                                <TabContext value={this.state.tabValue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleTabClick}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            sx={{
                                                '& .MuiTab-root': {
                                                  color: 'inherit',
                                                  fontSize: "16px",
                                                  fontFamily: "Outfit"
                                                },
                                                '& .Mui-selected': {
                                                  color: '#CC9200 !important', // Custom color for the active tab
                                                },
                                                '& .MuiTabs-indicator': {
                                                  backgroundColor: '#CC9200', // Custom color for the indicator
                                                },
                                              }}
                                        >
                                            <Tab id="generalTab" label="General Liability" value='1' />
                                            <Tab id="epaTab" label="EPA Certification" value='2' />
                                            <Tab id="stateTab" label="State license" value='3' />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value='1' >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Business License
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{display: "flex"}}>
                                                <ImageCollection sx={webStyles.icon} />
                                                <Typography style={{
                                                    fontSize: "14px",
                                                    fontFamily: "Outfit",
                                                    fontWeight: 400,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: "54%"
                                                }}>
                                                    {this.state.vendorData.business_licenses[0]?.file_name}{" "}
                                                </Typography>
                                                <Button id="businessLicense"
                                                    style={{
                                                    maxHeight: "34px",
                                                    marginTop: "-5px"
                                                }} onClick={() => this.downloadAttachment(this.state.vendorData.business_licenses[0]?.file_url, this.state.vendorData.business_licenses[0]?.file_name)} sx={webStyles.downloadButton}>
                                                    Download
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Expiration date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularText}>{businessLicenseDate}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Business Insurance
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{display: "flex"}}>
                                                <ImageCollection sx={webStyles.icon} />
                                                <Typography style={{
                                                    fontSize: "14px",
                                                    fontFamily: "Outfit",
                                                    fontWeight: 400,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: "54%"
                                                }}>
                                                    {this.state.vendorData.business_insurances[0]?.file_name}{" "}
                                                </Typography>
                                                <Button id="businessInsurance"
                                                    style={{
                                                    maxHeight: "34px",
                                                    marginTop: "-5px"
                                                }} onClick={() => this.downloadAttachment(this.state.vendorData.business_insurances[0]?.file_url, this.state.vendorData.business_insurances[0]?.file_name)} sx={webStyles.downloadButton}>
                                                    Download
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Expiration date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularText}>{businessInsuranceDate}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value='2' >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    EPA Certification License
                                                </Typography>
                                            </Grid>
                                            
                                            {this.state.vendorData.epa_certification_licenses.length?
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <ImageCollection sx={webStyles.icon} />
                                                    <Typography style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Outfit",
                                                        fontWeight: 400,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: "54%"
                                                    }}>
                                                        {this.state.vendorData.epa_certification_licenses[0]?.file_name}{" "}
                                                    </Typography>
                                                    <Button id="epaLicense"
                                                        style={{
                                                        maxHeight: "34px",
                                                        marginTop: "-5px"
                                                    }} onClick={() => this.downloadAttachment(this.state.vendorData.epa_certification_licenses[0]?.file_url, this.state.vendorData.epa_certification_licenses[0]?.file_name)} sx={webStyles.downloadButton}>
                                                        Download
                                                    </Button>
                                                </Grid>) : 
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <Typography sx={webStyles.regularText}>
                                                        No attachment
                                                    </Typography>
                                                </Grid>)
                                            }
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Expiration date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularText}>{epaCertificateDate}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Other Certificate
                                                </Typography>
                                            </Grid>
                                            {this.state.vendorData.epa_other_certificates.length?
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <ImageCollection sx={webStyles.icon} />
                                                    <Typography style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Outfit",
                                                        fontWeight: 400,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: "54%"
                                                    }}>
                                                        {this.state.vendorData.epa_other_certificates[0]?.file_name}{" "}
                                                    </Typography>
                                                    <Button id="otherCertificate"
                                                        style={{
                                                        maxHeight: "34px",
                                                        marginTop: "-5px"
                                                    }} onClick={() => this.downloadAttachment(this.state.vendorData.epa_other_certificates[0]?.file_url, this.state.vendorData.epa_other_certificates[0]?.file_name)} sx={webStyles.downloadButton}>
                                                        Download
                                                    </Button>
                                                </Grid>) : 
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <Typography sx={webStyles.regularText}>
                                                        No attachment
                                                    </Typography>
                                                </Grid>)
                                            }
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Expiration date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularText}>{otherCertificateDate}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value='3' >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    State License
                                                </Typography>
                                            </Grid>
                                            {this.state.vendorData.state_licenses.length?
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <ImageCollection sx={webStyles.icon} />
                                                    <Typography style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Outfit",
                                                        fontWeight: 400,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: "54%"
                                                    }}>
                                                        {this.state.vendorData.state_licenses[0]?.file_name}{" "}
                                                    </Typography>
                                                    <Button id="stateLicense"
                                                        style={{
                                                        maxHeight: "34px",
                                                        marginTop: "-5px"
                                                    }} onClick={() => this.downloadAttachment(this.state.vendorData.state_licenses[0]?.file_url, this.state.vendorData.state_licenses[0]?.file_name)} sx={webStyles.downloadButton}>
                                                        Download
                                                    </Button>
                                                </Grid>) : 
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <Typography sx={webStyles.regularText}>
                                                        No attachment
                                                    </Typography>
                                                </Grid>)
                                            }
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Expiration date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularText}>{stateLicenseDate}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    State Insurance
                                                </Typography>
                                            </Grid>
                                            {this.state.vendorData.state_insurances.length?
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <ImageCollection sx={webStyles.icon} />
                                                    <Typography style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Outfit",
                                                        fontWeight: 400,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: "54%"
                                                    }}>
                                                        {this.state.vendorData.state_insurances[0]?.file_name}{" "}
                                                    </Typography>
                                                    <Button id="stateInsurance"
                                                        style={{
                                                        maxHeight: "34px",
                                                        marginTop: "-5px"
                                                    }} onClick={() => this.downloadAttachment(this.state.vendorData.state_insurances[0]?.file_url, this.state.vendorData.state_insurances[0]?.file_name)} sx={webStyles.downloadButton}>
                                                        Download
                                                    </Button>
                                                </Grid>) : 
                                                (<Grid item xs={6} sx={{display: "flex"}}>
                                                    <Typography sx={webStyles.regularText}>
                                                        No attachment
                                                    </Typography>
                                                </Grid>)
                                            }
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularTextLabel}>
                                                    Expiration date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={webStyles.regularText}>{stateInsuranceDate}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </TabContext>
                            </Box>

                            {/* notes */}
                            <Box sx={webStyles.infoSection}>
                            <Typography variant="h6" sx={webStyles.section_heading} gutterBottom>
                                Notes
                            </Typography>
                            <Typography sx={webStyles.regularText}>
                                {this.state.vendorData.notes}
                            </Typography>
                            </Box>

                            {/* attachments */}
                            <Typography variant="h6" sx={webStyles.section_heading} gutterBottom>
                            Attachments
                            </Typography>
                            <TableContainer sx={webStyles.tableContainer}>
                            <Table
                                sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                                minWidth: 650,
                                }}
                                aria-label="attachments table"
                            >
                                <TableHead>
                                <TableRow>
                                    <TableCell sx={webStyles.tableHeader}>Name</TableCell>
                                    <TableCell sx={webStyles.tableHeader}>
                                    Uploaded by
                                    </TableCell>
                                    <TableCell sx={webStyles.tableHeader}>Date</TableCell>
                                    <TableCell sx={webStyles.tableHeader}>Shared</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {paginatedRows?.map((row, index) => (
                                    <TableRow key={row.id}>
                                    <TableCell sx={{ display: "flex" }} component="th" scope="row">
                                        <ImageCollection sx={webStyles.icon} />
                                        <Typography style={{
                                            fontSize: "12px",
                                            fontFamily: "Outfit",
                                            fontWeight: 400,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {row.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{
                                                fontSize: "12px",
                                                fontFamily: "Outfit",
                                                fontWeight: 400,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                        }}>
                                            {row.update_by}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{
                                                fontSize: "12px",
                                                fontFamily: "Outfit",
                                                fontWeight: 400,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                        }}>
                                            {row.date}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{
                                                fontSize: "12px",
                                                fontFamily: "Outfit",
                                                fontWeight: 400,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                        }}>
                                            {row.date}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => this.downloadAttachment(row.file_url, row.name)} variant="text" sx={webStyles.downloadButton}>
                                            Download
                                        </Button>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            <Box sx={webStyles.paginationContainer}>
                            <Box></Box>
                            <Pagination
                                data-test-id="pagination"
                                count={Math.ceil(this.state.vendorAttachments.length / rowsPerPage)}
                                page={currentPage}
                                onChange={this.handleChangePage}
                                renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    sx={{
                                    ...webStyles.paginationText,
                                    "&.Mui-selected": {
                                        color: "#CC9200",
                                        backgroundColor: "unset",
                                    },
                                    }}
                                />
                                )}
                            />
                            
                            <Typography sx={{ ...webStyles.paginationText, marginRight: "10%" }}>
                            {this.state.vendorAttachments.length === 0
                                ? "0 results"
                                : `${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                                    currentPage * rowsPerPage,
                                    this.state.vendorAttachments.length
                                )} of ${this.state.vendorAttachments.length} results`}
                            </Typography>
                            </Box>
                        </Grid>
                        </Grid>
                    </Paper></Box>
                    <Box className="vendorProfileBottomImg" data-test-id="vendorProfileBottomImg" sx={{"@media (max-width:992px)": { display: "none",}}}>
                    <img
                        src={bottomImageIcon}
                        alt="Description"
                        style={{
                            position: "absolute",
                            bottom: "-15px",
                            right: "0"
                        }}
                    /></Box>
                </Container>
              </Grid>
            </Grid>
        );
    }
}

const webStyles = {
    navBarItem: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        overflowY: "auto",
        zIndex: 1000,
        "@media(max-width: 992px)": {
          paddingTop: "24px", display:"flex",
          position: "unset",
          height: "auto",
          overflowY: "hidden",
          border: "none",
        },
        scrollbarWidth: "none", 
        msOverflowStyle: "none",
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      },
    vendor_profile_header: {
        fontFamily: "Outfit",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "40px",
        letterSpacing: "-0.005em",
      },
      contentPaper: {
        border: "2px solid #FFD466",
        borderRadius: "8px",
        borderBottomRightRadius: "30px",
        padding: "20px",
        boxShadow: "unset",
        "@media (max-width: 992px)": {
            padding: "0rem"
        }
      },
      mainContent: {
        marginLeft: 0,
        "@media (min-width: 992px)": {
            borderLeft: "1px solid rgb(208,203,199)",
            marginLeft: "7.5rem",
            width: 'calc(100% - 120px)',
        }
      },
      container: {
        padding: "20px",
        "@media (max-width: 600px)": {
          flexDirection: "column",
        },
      },
      section: {
        "@media (min-width: 600px)": {
          borderRight: "2px solid #BAB1AB",
        },
        minHeight: "100%",
        padding: "10px",
      },
      icon: {
        marginRight: "10px",
        color: "#CC9200",
      },
      section_heading: {
        fontSize: "16px",
        fontFamily: "Outfit",
        fontWeight: 700,
      },
      regularTextLabel: {
        fontSize: "14px",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#BAB1AB",
        marginRight: "15px",
      },
      regularText: {
        fontSize: "14px",
        fontFamily: "Outfit",
        fontWeight: 400,
        wordBreak: 'break-word'
      },
      table: {
        minWidth: 650,
      },
      tableContainer: {
        margin: "20px 0",
      },
      paginationContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0",
      },
      downloadButton: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#CC9200",
        textTransform: "capitalize",
      },
      tableHeader: {
        fontFamily: "Outfit",
        fontSize: "12px",
        fontWeight: 400,
        color: "#BAB1AB",
      },
      infoSection: {
        marginBottom: "10%",
      },
      editButton: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#CC9200",
        textTransform: "capitalize",
        paddingTop: 0,
      },
      paginationText: {
        fontFamily: "Outfit",
        fontSize: "12px",
        fontWeight: "400",
        color: "70645C"
      }
}
