//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Paper,
  // Customizable Area End
  Grid,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import {
        imageOne,
        imageTwo,
        activeMainDashboard, 
        activeMainReminder,
        activePropertyMain, 
        activeMainFinancial,
        keyMainChain, 
        activeKeyMain,
        keasyIcon, 
        dashboardLandLogo,
        financialMain,
        propertyMain,
        remindersMain, 
        next,
        previous} from "./assets";
  import { hexagon, halfHexagon } from "../../appointmentmanagement/src/assets"    
       
 import Slider from "react-slick";
 export const configJSON = require("./config");

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
      data-test-id="next-arrow"
    >
     <img src={next} alt="Next" style={{ width: "40px", height: "40px" }} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
      data-test-id="prev-arrow"
    >
    <img src={previous} alt="Next" style={{ width: "40px", height: "40px" }} />
    </div>
  );
};
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
         responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
    };
// Customizable Area End

import DownloadOptionsController, {
  Props,
} from "./DownloadOptionsController.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class DownloadOptions extends DownloadOptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "DownloadOptions",
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>  
       <TenantLeaseStyle>
        <NavArea  className="tenantLeaseNavArea" sx={{"@media(max-width: 992px)": { display: "flex", "paddingTop": "24px",}}}>
        <NavigationSidebar
          data-test-id={"tenantId"}
          activeItem={this.state.activeTenantItem}
          sidebarItems={[
            { label: configJSON.dashboardLandloard, labelKey: configJSON.tenantDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.tenantDashboard },
            { label: configJSON.serviceRequestName, labelKey: configJSON.servicelable, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.servicePath },
            { label: configJSON.lease, labelKey: configJSON.leaselabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.leaselabel },
            { label: configJSON.paymentHistory, labelKey: configJSON.paymentHistorylabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.paymentHistorylabel },
            { label: configJSON.chatLandloard, labelKey: configJSON.chatlandlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatTenantabel }
          ]}
          onClickSidebar={this.handleMainItemClick}
          keasylogo={keasyIcon}
          openDrawer={this.state.openTenantDrawer}
          onClickDrawer={this.handleTenantDrawer}
        />
        <Box className="tenantLeaseMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
        <CustomNavbar navigation={this.props.navigation} id="tenantLeaseNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>
        </NavArea>
        <Box className="rightBox">
        <Box className="tenantLeaseNavbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>
        <CustomNavbar 
                {...this.screenProps}
                showChatButton={true}   
                showPropertiesButton={false} />
         </Box> <Box className="leaseContainer" sx={{ paddingRight: "30px","@media (max-width: 992px)": {paddingRight: 0,},}}>
          <Box className="heading">Lease</Box>
          <Box className="lease-wrapper">
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "1rem" }}>
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "1rem" }}>
              <Typography id="doYouNeedHelp" variant="h1" className="Title1" sx={{cursor: "pointer", "@media (max-width: 992px)": {fontSize: "18px",}}} onClick={this.navigateToChat}>
                 <HelpOutlineIcon style={{color: '#CC9200' }} />
                  Do you need help
                </Typography>
                <Typography variant="h1" className="Title" sx={{ width: "80%",marginBottom:"2rem" }}>
                  {this.state.propertyName}|{this.state.propertyUnit}
                </Typography>
              </Box>
              <Box className="carousel"  data-test-id="carousel">
                <div className="slider-container">
                  <Slider {...settings}  data-test-id="slider">
                    <div>
                    <img src={imageOne} style={{ width: "140px", height: "140px" }} />
                  </div>
                <div>
                      <img src={imageTwo} style={{ width: "140px", height: "140px" }} />
                    </div>
                    <div>
                      <img src={imageTwo} style={{ width: "140px", height: "140px" }} />
                    </div>
                  </Slider>
                </div>
              </Box>
            </Box>
            <Box sx={{ borderBottom: "1px solid #D1CBC7", my: { xs: "15px", md: "30px" } }} />
            <Box>
              <Typography className="startHeading">LEASE DETAILS</Typography>
              <Box sx={{ display: "flex", gap: "5rem", flexDirection: { xs: "row", md: "row" } }}>
                <Box>
                  <Typography className="labelTitle">Lease start date</Typography>
                  <Typography className="labelTitle">Lease end date</Typography>
                  <Typography className="labelTitle">Address</Typography>
                </Box>
                <Box>
                  <Typography className="details">{this.state.leaseStartDate}</Typography>
                  <Typography className="details">{this.state.leaseEndDate || "\u00A0"}</Typography>
                  <Typography className="details">{this.state.leaseaddress}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ borderBottom: "1px solid #D1CBC7", my: { xs: "15px", md: "30px" } }} />
            <Box>
              <Typography className="startHeading">LANDLORD INFORMATION</Typography>
              <Box sx={{ display: "flex", gap: "0rem", flexDirection: { xs: "row", md: "row" } }}>
                <Box>
                  <Typography className="labelTitle">Name</Typography>
                  <Typography className="labelTitle">Landlord Physical Address</Typography>
                  <Typography className="labelTitle">Phone Number</Typography>
                </Box>
                <Box>
                  <Typography className="details">{this.state.landlordName}</Typography>
                  {this.state.landlordAddress.length > 50 ? (
                  <Tooltip
                    title={this.state.landlordAddress}
                    arrow
                    placement="top"
                    data-test-id="tooltip-landlord-address"
                  >
                    <Typography className="details">
                      {`${this.state.landlordAddress.substring(0, 50)}... `}
                      <InfoIcon sx={{ fontSize: "16px", marginLeft: "5px", color: "#A3978F" }} />
                    </Typography>
                  </Tooltip>
                ) : (
                  this.state.landlordAddress
                )}
                <Typography className="details">
                  ({this.state.landlordNumber[0]}) {this.state.landlordNumber[1]}
                </Typography>

                </Box>
              </Box>
            </Box>
            <Box sx={{ borderBottom: "1px solid #D1CBC7", my: { xs: "15px", md: "30px" } }} />
            <Box>
              <Typography className="startHeading">TENANT INFORMATION</Typography>
              <Box sx={{ display: "flex", gap: "3rem", flexDirection: { xs: "row", md: "row" } }}>
                <Box>
                  <Typography className="labelTitle">Name</Typography>
                  <Typography className="labelTitle">Email</Typography>
                  <Typography className="labelTitle">Phone Number</Typography>
                  <Typography className="labelTitle">Number of occupants</Typography>
                </Box>
                <Box>
                <Typography sx={{ fontFamily: "Outfit", fontWeight: 700, fontSize: "16px", lineHeight: "24px", marginTop: "2rem",marginLeft:"2rem" }}>
                  {this.state.name}
                  <span
                    style={{
                      width: "75px",
                      height: "75px",
                      color: "#059669",
                      padding: "2px 8px",
                      gap: "2px",
                      borderRadius: "40px",
                      background: "#D1FAE5",
                      marginLeft: "1rem",
                      fontFamily: "Outfit",
                      fontSize: "12px",
                    }}
                  >
                   CURRENT
                  </span>
                </Typography>
                <Typography className="details">{this.state.email}</Typography>
                <Typography className="details">{this.state.phoneNumber}</Typography>
                <Typography className="details">{this.state.numberOfOccupants}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ borderBottom: "1px solid #D1CBC7", my: { xs: "15px", md: "30px" } }} />
            <Box>
              <Typography className="startHeading">RENTAL INFORMATION </Typography>
              <Box sx={{ display: "flex", gap: "0.8rem", flexDirection: { xs: "row", md: "row" } }}>
                <Box>
                  <Typography className="labelTitle">Date of first payment due</Typography>
                  <Typography className="labelTitle">Pay period</Typography>
                  <Typography className="labelTitle">Rent amount per month</Typography>
                  <Typography className="labelTitle">Security Deposit</Typography>
                  <Typography className="labelTitle">Payment Method</Typography>
                  <Typography className="labelTitle">Collected by</Typography>
                </Box>
                <Box>
                  <Typography className="details">
                    {moment(this.state.rentalFirstPayment).format('DD MMM YYYY')}
                  </Typography>
                  <Typography className="details">{}</Typography>
                  <Typography className="details">{this.state.rentalPayPeriod}</Typography>
                  <Typography className="details">{this.state.rentalAmount}</Typography>
                  <Typography className="details">{this.state.rentalDeposit}</Typography>
                  <Typography className="details">{this.state.rentalPaymentMethod}</Typography>
                  <Typography className="details">{this.state.collectedBy}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="buttonContainer">
              <Button className="download-button" onClick={this.handleDownload1}>
                Download Lease Contract
              </Button>
            </Box>
            </Box>
            <Box className="bgImgWrapper" data-test-id="bgImgId">
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-one" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-two" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-three" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
            </Box>
          </Box>
        </Box>
      </TenantLeaseStyle>
            </> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const TenantLeaseStyle = styled(Box)({
    display: "flex",
    fontFamily: "Outfit, sans-serif",
    width: "100%",
    "@media(max-width: 992px)": {
      flexDirection: "column"
    },
  "& .rightBox": {
    display:"flex",
    width:"calc(100% - 30px)",
    flexDirection:"column",
    marginLeft:"120px", paddingLeft: '30px',
    "@media(max-width: 992px)": {
      marginLeft:"0px", paddingLeft: '16px', paddingRight: "16px", width:"calc(100% - 32px)",
   
    }
  },
  "& .slick-prev:before, & .slick-next:before": {
    content: '""',
  },
  "& .slider-container": {
        width: "100%",
        "@media (min-width: 2560px)": { 
          width: "62%",
        },
        
        "@media (min-width: 2000px)": {
          width: "85%",
        },
        "@media (max-width: 1920px)": { 
          width: "85%",
        },
        "@media (min-width: 1536px)": {
          width: "100%",
        },
        "@media (max-width: 1440px)": { 
          width: "108%",
        },
        "@media (max-width: 1400px)": { 
          width: "74%",
        },
        "@media (max-width: 1366px)": { 
          width: "74%",
        },
        "@media (max-width: 1325px)": { 
          width: "76%",
        },
        "@media (max-width: 1280px)": { 
          width: "80%",
        },
        "@media (max-width: 1024px)": { 
          width: "100%",
        },
    },
  "& .slick-list": {
    width: "487px",
    "@media(max-width:600px)": {
      width: "150px",
}
  },
  "& .slick-slide": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  
  "& .slick-prev": {
    zIndex: 1,
    marginLeft:"-2rem"
  },
  "& .slick-next": {
    zIndex: 1,
    marginRight:"8rem",
    "@media (max-width:1400px)": {
      marginRight:"3.5rem",
    },
    "@media (max-width:600px)": {
      marginRight:"3.5rem",
    },
  },
  "& .Title": {
    fontFamily: 'Outfit',
    fontWeight: 700,
    color:"#CC9200",
    fontSize: '24px',
    lineHeight: '32px',
    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .Title1": {
    display:"flex",
    alignItems:"center",
    gap:"1rem",
    fontFamily: 'Outfit',
    color:"#CC9200",
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '32px',
    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    marginLeft: "0px",
    marginTop: "3rem",
    fontFamily:"Outfit",

    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .lease-wrapper": {
    display:"flex",
    flexDirection:"column",
    border: "1px solid #FFE299",
    borderRadius: "8px",
    margin: "2rem 0",
    padding: "2rem",
    "@media (max-width:992px)": {
      padding: "1rem",
    },
  },
  "& .labelTitle": {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '17px',
    marginTop: "2rem",
    fontFamily: 'Outfit',
    color:"#BAB1AB",
    "@media (max-width:992px)": {fontSize: "1rem",},
  },
  "& .carousel": {
     flex:1,
    "@media (max-width:1000px)": {
      marginLeft: "3rem",
    },
  },
  "& .details": {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '17px',
    marginLeft: "2rem",
    marginTop: "2rem",
    fontFamily: 'Outfit',
    color:"#000000",
    "@media (max-width:992px)": {
      fontSize: '16px',
    },
  },
  "& .startHeading": {
    fontWeight: 700,
    fontFamily: 'Outfit',
    lineHeight: '17px',
    color:"black",

    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .buttonContainer": {
    height:"5%",
    display:"flex",
    justifyContent:"end",
    alignItems:"center",

  },
  "& .download-button": {
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    height: "44px",
    width: "200px",
    color: "#000000",
    fontWeight: "bold",
    textTransform: "none",
    fontFamily: "Outfit",
    "&:hover": {
      backgroundColor: "#FFD700",
    },
    "@media (max-width:600px)": {
      width:"60%",
    },
},
"& .bgImgWrapper": {
  display: "flex",
  justifyContent: "flex-end",
  width: "97%",
  marginTop: "2rem",
  fontFamily: "Outfit",
  "@media (max-width:1220px)": {
    width: "88%"
  },
  "@media (max-width:992px)": {
    display: "none",
  }
},
"& .backgroundImage": {
  width: "250px",
  "@media (max-width:1220px)": {
    width: "180px"
  },
},
"& .hexImg": {
  objectFit: "cover"
}
  
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
// Customizable Area End
