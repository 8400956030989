import React from "react";
// Customizable Area Start
import { Box, styled, Typography, Button } from "@mui/material";
import {
  keasyLogo,
  facebook,
  instagram,
  twitter,
  keasyFont,
  profile,
  home,
  service,
  bgUser,
  backgroundImg
} from "./assets";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// Customizable Area End
import SelectUserProfileController, {
  Props,
  configJSON,
} from "./SelectUserProfileController.web";

export default class SelectUserProfile extends SelectUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <BackgroundContainer>
        <SelectUserContainer>
          <ContainerMain>
            <MainTopContainer>
              <img src={keasyLogo} alt="keyslogo" width={55} />
              <img src={keasyFont} alt="keasyHead" />
            </MainTopContainer>
            <LoginBox>
              <SelectUserHead variant="h5" gutterBottom>
                {configJSON.selectUser}
              </SelectUserHead>
              <UserNewHeadings>{configJSON.wantToUse}</UserNewHeadings>
            </LoginBox>
            <ButtonContainer>
              <LandlordButton
                data-test-id="isUserId"
                variant="contained"
                startIcon={<img src={home} alt="home" />}
                className={
                  this.state.activeSelected === "landlord" ? "isSelected" : ""
                }
                onClick={() => this.handleUserProfile("landlord")}
              >
                {configJSON.landlordUser}
              </LandlordButton>
              <ServiceProviderButton
                data-test-id="userVendorId"
                variant="contained"
                startIcon={<img src={service} alt="service" />}
                className={
                  this.state.activeSelected === "vendor" ? "isSelected" : ""
                }
                onClick={() => this.handleUserProfile("vendor")}
              >
                {configJSON.serviceProvider}
              </ServiceProviderButton>
            </ButtonContainer>
            <LoginButton
              data-test-id="loginId"
              variant="contained"
              onClick={this.handleSignUpProfile}
            >
              {configJSON.logInAccount}
            </LoginButton>
            <SocialMediaContainer>
              <SocialMediaImg src={facebook} alt="facebook" />
              <SocialMediaImg src={instagram} alt="instagram" />
              <SocialMediaImg src={twitter} alt="twitter" />
            </SocialMediaContainer>
          </ContainerMain>
        </SelectUserContainer>
        <Snackbar
          data-test-id="messageId"
          open={this.state.openSnack}
          autoHideDuration={2000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={this.handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {configJSON.userMessage}
          </Alert>
        </Snackbar>
      </BackgroundContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const SelectUserHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
});

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const MainTopContainer = styled(Box)({
  width: "100%",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ContainerMain = styled(Box)({
  width: "380px",
  "@media(max-width: 400px)": {
    width: "90%",
  },
});

const SelectUserContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
});

const TextUser = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginLeft: "14px",
  "@media(max-width: 400px)": {
    marginLeft: "0px",
  },
});

const UserHeadings = styled(Typography)({
  fontSize: "18px",
  color: "#544B45",
  fontWeight: 400,
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});

const UserNewHeadings = styled(Typography)({
  fontSize: "18px",
  fontWeight: 400,
  color: "#544B45",
  marginLeft: "15px",
  "@media(max-width: 400px)": {
    fontSize: "14px",
    marginLeft: "0px",
  },
});

const SocialMediaContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2em",
});

const LoginBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "15px",
  textAlign: "center",
  margin: "40px 0px 15px 0px",
});

const UserButton = styled(Button)({
  marginBottom: "15px",
  width: "100%",
});

const LandlordButton = styled(UserButton)({
  textTransform: "none",
  backgroundColor: "#FFF1CC",
  padding: "15px",
  color: "#000000",
  fontWeight: 700,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  "&.isSelected": {
    backgroundColor: "#FFE299",
    "&:hover": {
      backgroundColor: "#FFE299",
      color: "#000000",
    },
  },
  "&:hover": {
    backgroundColor: "#FFF1CC",
    color: "#000000",
  },
});



const ServiceProviderButton = styled(UserButton)({
  color: "#000000",
  display: "flex",
  padding: "15px",
  backgroundColor: "#FFF1CC",
  justifyContent: "flex-start",
  alignItems: "center",
  fontWeight: 700,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#FFF1CC",
    color: "#000000",
  },
  "&.isSelected": {
    backgroundColor: "#FFE299",
    "&:hover": {
      backgroundColor: "#FFE299",
      color: "#000000",
    },
  },
});

const LoginButton = styled(UserButton)({
  color: "#000000",
  textTransform: "none",
  backgroundColor: "#FFC123",
  padding: "12px 15px 12px 15px",
  fontSize: "18px",
  fontWeight: 700,
  marginTop: "30px",
  "&:hover": {
    backgroundColor: "#FFC123",
    color: "#000000",
  },
});

const SocialMediaImg = styled("img")({
  width: "28px",
  padding: "4px",
  backgroundColor: "#A3978F",
  margin: "12px",
  borderRadius: "8px",
  cursor: "pointer",
});

const BackgroundContainer = styled(Box)({
  backgroundImage: `url(${bgUser})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "bottom",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  "@media(max-width: 600px)": {
    backgroundImage: `url(${backgroundImg})`
  }
});
// Customizable Area End
