import React from "react";
// Customizable Area Start
import { Box, styled, Typography, Button } from "@mui/material";
import {
  keasyLogo,
  facebook,
  instagram,
  twitter,
  keasyFont,
  backgroundForgetPassword,
  fullHexagon,
  backgroundPassImg
} from "./assets";
import { configJSON } from "./ForgotPasswordController";
type Values = {
  password: string;
  newPassword: string;
};
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
export default class PasswordRecovery extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <BackgroundContainerRecovery>
        <RecoveryPasswordContainer>
          <ContainerMainRecovery>
            <MainTopContainerRecovery>
              <img src={keasyLogo} alt="keyslogo" width={55} />
              <img src={keasyFont} alt="keasyHead" />
            </MainTopContainerRecovery>
            <Box display={"flex"} flexDirection={"column"}>
            <BackgroundPassContainer>
              <BackRecovery>
            <RecoveryPasswordBox>
              <RecoveryPasswordHead variant="h5">
                {configJSON.recovery}
              </RecoveryPasswordHead>
              <RecoveryPasswordText>
                <RecoveryPasswordHeadingsTitle gutterBottom>
                  Check your email
                </RecoveryPasswordHeadingsTitle>
                <RecoveryPasswordHeadings>
                  We have sent a password reset link to {this.state.setNewEmail}
                </RecoveryPasswordHeadings>
              </RecoveryPasswordText>
            </RecoveryPasswordBox>
            <NavigationLinksRecovery>
              <LoginLinkRecoveryAccountRecovery variant="body2">
                <LoginLinkRecovery
                  data-test-id="resendEmail"
                  onClick={this.handleResendEmail}
                >
                  Resend e-mail
                </LoginLinkRecovery>
              </LoginLinkRecoveryAccountRecovery>
              <LoginLinkRecoveryAccountRecovery variant="body2">
                {this.backTo}
                <LoginLinkRecovery
                  data-test-id="loginLink"
                  onClick={this.handleClickLogin}
                >
                  {this.login}
                </LoginLinkRecovery>
              </LoginLinkRecoveryAccountRecovery>
            </NavigationLinksRecovery>
              </BackRecovery>
            </BackgroundPassContainer>
            </Box>
            <SocialMediaContainerRecovery>
              <SocialMediaImgRecovery src={facebook} alt="facebook" />
              <SocialMediaImgRecovery src={instagram} alt="instagram" />
              <SocialMediaImgRecovery src={twitter} alt="twitter" />
            </SocialMediaContainerRecovery>
          </ContainerMainRecovery>
        </RecoveryPasswordContainer>
        <Snackbar
          data-test-id="messageSuccessId"
          open={this.state.openSnackbar}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={this.handleSnackClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {configJSON.emailMessage}
          </Alert>
        </Snackbar>
      </BackgroundContainerRecovery>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const RecoveryPasswordHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
});
const ErrorMessageRecovery = styled(Box)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#DC2626",
  padding: "12px 16px 12px 16px",
  backgroundColor: "#FEE2E2",
  borderLeft: "4px solid #DC2626",
  borderRadius: "4px",
  marginBottom: "20px",
  maxWidth: "315px",
});
const MainTopContainerRecovery = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const ContainerMainRecovery = styled(Box)({
  width: "400px",
  padding: "1.5rem 0px",
  "@media(max-width: 600px)": {
    width: "100%",
    marginTop: "2rem"
  },
});
const NavigationLinksRecovery = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "20px"
});
const RecoveryPasswordContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media(max-width: 600px)": {
    padding: "0 30px",
  },
});
const RecoveryPasswordText = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const RecoveryPasswordHeadings = styled(Typography)({
  fontSize: "18px",
  color: "#544B45",
  fontWeight: 400,
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const RecoveryPasswordHeadingsTitle = styled(Typography)({
  fontSize: "19px",
  color: "#544B45",
  fontWeight: "bold",
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const LoginLinkRecoveryAccountRecovery = styled(Typography)({
  display: "flex",
  gap: "10px",
  fontWeight: 400,
  fontSize: "18px",
  marginTop: "1em",
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const SocialMediaContainerRecovery = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "3em",
  position: "absolute",
  bottom: "3rem",
  width: "inherit",
  "@media(max-width: 700px)": {
    marginTop: "35em",
    position: "absolute",
    bottom: "3rem",
    width: "90%"
  },
});
const RecoveryPasswordBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "40px",
});
const UserButtonRecovery = styled(Button)({
  marginBottom: "15px",
  width: "100%",
});

const LoginLinkRecovery = styled(Typography)({
  color: "#CC9200",
  fontSize: "18px",
  fontWeight: 700,
  textDecoration: "none",
  cursor: "pointer",
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const SocialMediaImgRecovery = styled("img")({
  width: "28px",
  padding: "4px",
  backgroundColor: "#A3978F",
  margin: "12px",
  borderRadius: "8px",
  cursor: "pointer",
});
const BackgroundContainerRecovery = styled(Box)({
  backgroundImage: `url(${backgroundForgetPassword})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "bottom",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
  "& .password_error_icon": {
    fontSize: "16px",
    color: "#FF0000",
  },
  "& .password_sucess_icon": {
    fontSize: "16px",
    color: "#32CD32",
  },
  "& .password_error_wrapper": {
    marginTop: "32px",
  },
  "& .signup_form_formik": {
    overflow: "auto",
  },
  "& .confirm-input-box": {
    marginTop: "40px",
  },
  "@media(max-width: 600px)": {
    backgroundImage: `url(${backgroundPassImg})`
  }
});

const BackgroundPassContainer = styled(Box)({
  backgroundImage: `url(${fullHexagon})`,
  backgroundRepeat: "no-repeat",
  display: "flex",
  backgroundSize: "contain",
  width: "100%",
  flexDirection: "column",
  padding: "35px 15px 35px 15px",
  alignItems: "center",
  height: "60vh",
  "@media(max-width: 600px)": {
    backgroundImage: "none",
    height: "auto",
    padding: "0px 0px 0px 0px"
  }
});

const BackRecovery = styled(Box)({
  width: "92%",
  marginTop: "6rem",
  "@media(max-width: 600px)": {
    width: "100%"
  }
});
// Customizable Area End
