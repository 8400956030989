import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const serviceLogoActive = require("../../appointmentmanagement/assets/service.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeprojects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
export const serviceLogoInactive = require("../../appointmentmanagement/assets/serviceRequest.png");
import { attachmentIcon } from "./assets";
import {ChatAttachment} from "./ChatController";
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EmojiPicker, { EmojiStyle, SuggestionMode } from 'emoji-picker-react';
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import PopupMessage from "../../../components/src/PopupMessage.web";
export const profileMain = require("../../tasks/assets/profile.png");
export const activeProfile = require("../../tasks/assets/activeProfile.png");
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const approved = require("../../invoicebilling/assets/approved.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fileInput: HTMLInputElement | null = null;

  renderAddRoomModal = () => {
    return(
      <Modal
        id="add-chat"
        open={this.state.addChatModal}
        onClose={() => this.handleModalClose('chatModal')}
        aria-labelledby="add-chat-modal"
        aria-describedby="add-chat-modal"
      >
        <Box sx={webStyles.modal_style}>
          <Box sx={webStyles.modal_header}>
            <Typography sx={{fontWeight: '700', fontSize: '18px', fontFamily: 'Outfit'}}>
              Create chat
            </Typography>
            <IconButton
              data-test-id="addChatClose"
              onClick={() => this.handleModalClose('chatModal')}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={webStyles.modal_content}>
          <TextField
            required
            id="name"
            name="name"
            variant="outlined"
            size="small"
            autoComplete="off"
            fullWidth
            value={this.state.name}
            error={this.state.errors.name}
            helperText={this.state.errors.name ? "Please enter a valid name": "" }
            onChange={this.handleInputChange}
          />
          {this.state.SearchedUsers.length > 0 && (
            <List style={webStyles.searchList}>
              {this.state.SearchedUsers.map((user) => (
                <ListItemButton id={`user-${user.id}`} key={user.id} onClick={() => {this.setState({ selectedSearchUser: user, name: user.first_name, SearchedUsers: [] })}}>
                  {user.first_name}
                </ListItemButton>
              ))}
            </List>
          )}
          <Button
            data-test-id="btnAddAccount"
            size="medium"
            id="nextButton"
            type="submit"
            sx={webStyles.form_button}
            onClick={() => this.addUserToChat()}
          >
            Add
          </Button>
          </Box>
        </Box>
      </Modal>
    )
  };

  attachmentComp(attachment: ChatAttachment) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const extension = attachment.file_name.split('.').pop()?.toLowerCase();
    if(imageExtensions.includes(extension || '')){
      return (
        <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: 4, borderRadius: 2, width: this.state.displayProfile ? '80%' : '50%' }}>
          <img src={attachment.file_url} alt="image" style={webStyles.imgAttachment} />
        </Card>
      )
    }
    return (
      <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: 4, borderRadius: 2, p: 1, width: this.state.displayProfile ? '60%' : '40%' }}>
        <img src={attachmentIcon} alt="attachment icon" />
        <CardContent sx={{ flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <Typography sx={{fontWeight: '400', fontSize: '16px', fontFamily: 'Outfit'}}>{attachment.file_name}</Typography>
          <Typography sx={{fontWeight: '400', fontSize: '16px', fontFamily: 'Outfit'}} color="text.secondary">
            {attachment.size_mb >= 1 ? attachment.size_mb+' MB' : attachment.size_kb+' KB'}
          </Typography>
        </CardContent>
        <IconButton id={`attachment-menu-${attachment.id}`} onClick={(menus) => { this.handleMenuClick(menus, attachment.id) }}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          data-test-id="attachment-menu"
          anchorEl={this.state.attachmentAnchorElement[attachment.id]}
          open={Boolean(this.state.attachmentAnchorElement[attachment.id])}
          onClose={() => this.handleMenuClose(attachment.id)}
        >
          <MenuItem id={`menu-item-${attachment.id}`} onClick={()=>this.handleDownload(attachment.file_url, attachment.file_name, attachment.id)}>Download</MenuItem>
        </Menu>
      </Card>
    );
  };

  renderRoomList = () => {
    return (
      <Box>
        <List>
          <ListItem key="chat-title" sx={{ borderBottom: 1 }}>
            <ListItemText
              primary={
                <Box sx={webStyles.newChat}>
                  <Typography sx={{ fontWeight: 700, fontSize: '24px', fontFamily: 'Outfit' }}>
                    Chat
                  </Typography>
                </Box>
              }
            />
            <Button data-test-id="btnShowAddModal" variant="contained" sx={webStyles.addChatButton} onClick={this.showModal}>
              <AddCircleOutlineIcon fontSize="medium" />
            </Button>
          </ListItem>
          <ListItem sx={{ display: "flex", maxHeight: "70vh", padding: 0, alignItems: "end" }}>
            <Box width={"100%"}>
              <List sx={webStyles.chatListContentArea}>
                {this.state.chatsArray.map((chat) => (
                  <ListItemButton
                    id={`chatlist-${chat.attributes?.id}`}
                    onClick={() => this.handleChatClick(chat.attributes?.id)} 
                    key={chat.attributes?.id} 
                    sx={{ borderBottom: 1,
                      "&.Mui-selected": {
                        backgroundColor: {sm: "white", xs: "white", md: "#FFF1CC"},
                      },
                    }}
                    selected={this.state.activeChatId === chat.attributes?.id}
                  >
                    <ListItemAvatar>
                      <Avatar alt={chat.attributes?.receiver?.first_name} src={chat.attributes?.receiver?.profile_url} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Grid container>
                          <Grid item sx={webStyles.chatList} color={chat.attributes?.unread_count === 0 ? "#999" : "#CC9200"} xs={10}>
                            <Typography sx={{ 
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "Outfit",
                              display: "inline",
                              color: chat.attributes?.unread_count === 0 ? "#A3978F" : "#CC9200" 
                            }}>
                              {chat.attributes?.receiver?.first_name} {chat.attributes?.receiver?.last_name}
                            </Typography>
                            <Badge sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "#CC9200"
                                },
                                marginLeft: "10px"
                              }} variant="dot" invisible={chat.attributes?.unread_count === 0}>
                            </Badge>
                            {chat.attributes?.receiver?.user_type && (
                              <Typography variant="caption" sx={{
                                ml: 1,
                                color: chat.attributes?.unread_count === 0 ? "#A3978F" : "#CC9200",
                                textTransform: 'capitalize',
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "Outfit",
                              }}>
                                {chat.attributes?.receiver?.user_type} {chat.attributes?.receiver?.user_type === 'tenant' ? " at "+chat.attributes?.receiver?.user_info?.property_name +" - "+ chat.attributes?.receiver?.user_info?.unit_name : ""}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={1}>
                            <Typography sx={{ 
                              fontSize: "14px",
                              fontWeight: "400",
                              fontFamily: "Outfit",
                              color: "#70645C" }}>
                              {chat.attributes.last_message_at !== null && this.timeAgo(chat.attributes.last_message_at)}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <Grid container>
                          <Grid item sx={webStyles.chatList} xs={9}>
                            <Typography sx={{ 
                              display: "inline",
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "400",
                              fontFamily: "Outfit", }}>
                              {chat.attributes.last_message?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={1}>
                            <Badge sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "#FFF8E5",
                                  color: "#CC9200",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "Outfit",
                                },
                                marginTop: 1,
                                right: "-5px"
                              }}
                              badgeContent={chat.attributes.unread_count}
                              invisible={chat.attributes.unread_count === 0}
                            >
                            </Badge>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </ListItem>
          
        </List>
      </Box>
    );
  };

  getSidebarItems() {
    const { userType } = this.state;
    
    if (userType === 'tenant') {
      return [
        { label: configJSON.dashboardOrder, labelKey: configJSON.tenantDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.tenantDashboard },
        { label: configJSON.serviceRequests, labelKey: configJSON.servicelabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.servicelabel },
        { label: configJSON.lease, labelKey: configJSON.leaselabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.leaselabel },
        { label: configJSON.paymentHistory, labelKey: configJSON.paymentHistorylabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.paymentHistorylabel },
        { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel }
      ];
    }

    if (userType === 'landlord') {
      return [
        { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
        { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
        { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
        { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
        { label: configJSON.service1, labelKey: configJSON.serviceLable1, icon: serviceLogoInactive, activeIcon:serviceLogoActive , pathName: configJSON.serviceLable1 },
        { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
        { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
        { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.financOrderlabel },
        { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
        { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
      ];
    }

    if (userType === 'vendor') {
      return [
        { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
        { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeprojects, pathName: configJSON.vendorProjects },
        { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
        { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
        { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
      ];
    }

    return [];
  };

  showPropertiesDropdown() {
    const { userType } = this.state;

    return userType === 'landlord' ? true : false;
  };

  screenProps = {
    navigation: this.props.navigation,
    id: "Chat",
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={{overflowY: "hidden"}}>
      <Grid container direction="row" wrap="nowrap" 
            sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
        >
          <PopupMessage
                open={this.state.popUpOpen}
                type={this.state.popUpType}
                message={this.state.popUpMessage}
                handleClose={this.handlePopupMessageClose}
              />
          <Grid item sx={webStyles.navBarItem}>
            {this.state.userType === 'landlord' ? 
              <NavigationSidebar
                data-test-id={"navId"}
                activeItem={this.state.activeVendorItem}
                sidebarItems={this.getSidebarItems()}
                onClickSidebar={this.handleVendoritemClick}
                keasylogo={keasyIcon}
                openDrawer={this.state.openVendorDrawer}
                onClickDrawer={this.handleVendorDrawer}
            /> : 
                this.state.userType === 'vendor' ? 
                  <NavigationSidebar
                    data-test-id={"navId"}
                    activeItem={this.state.activeVendorItem}
                    sidebarItems={[
                      { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                      { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeprojects, pathName: configJSON.vendorProjects },
                      { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                      { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                      { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
                    ]}
                    onClickSidebar={this.handleVendoritemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openVendorDrawer}
                    onClickDrawer={this.handleVendorDrawer}
                  /> :
                  <NavigationSidebar
                    data-test-id={"tenantId"}
                    activeItem={this.state.activeVendorItem}
                    sidebarItems={[
                        { label: configJSON.dashboardOrder, labelKey: configJSON.tenantDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.tenantDashboard },
                        { label: configJSON.serviceRequests, labelKey: configJSON.servicelabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.servicelabel },
                        { label: configJSON.lease, labelKey: configJSON.leaselabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.leaselabel },
                        { label: configJSON.paymentHistory, labelKey: configJSON.paymentHistorylabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.paymentHistorylabel },
                        { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel }
                    ]}
                    onClickSidebar={this.handleVendoritemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openVendorDrawer}
                    onClickDrawer={this.handleVendorDrawer}
                />
            }
            <Box className="vendorOnboardingMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
            <CustomNavbar navigation={this.props.navigation} id="vendorOnboardingNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>

          </Grid>
          <Grid item sx={{ 
                flexGrow: 1, 
                borderLeft: "1px solid rgb(208,203,199)", 
                minHeight: "100vh", 
                height: "120vh",
                marginLeft: "120px",
                "@media (max-width: 992px)": {
                  marginLeft: 0,
                },
              }}>
            <Container
              maxWidth={false}
              sx={{
                padding: {
                  xs: "0 !important", // Remove padding
                }
              }}
            >
              <Box sx={{ 
                display: 'block', paddingLeft: "30px",
                "@media (max-width: 992px)": {
                  paddingLeft: "16px",  display: 'none',
                  paddingRight: "16px",
                },
              }}>
                <CustomNavbar 
                  {...this.screenProps}
                  showChatButton={true}   
                  showPropertiesButton={this.showPropertiesDropdown()} 
                />
              </Box>
              {this.renderAddRoomModal()}
              <Grid container >
                <Grid item md={3} sm={12} xs={12}
                  sx={{ display: {sm: this.state.currentMobileView === 'list' ? 'block' : "none", xs: this.state.currentMobileView === 'list' ? 'block' : "none", md: "block"}}}
                >
                  {this.renderRoomList()}
                </Grid>
                {this.state.activeChatUser.id !== 0 && <Grid item md={this.state.displayProfile ? 6 : 9 } sm={12} xs={12}
                  sx={{ display: {sm: this.state.currentMobileView === 'chat' ? 'block' : "none", xs: this.state.currentMobileView === 'chat' ? 'block' : "none", md: "block"}}}
                >
                <List>
                    <ListItem key="chat-header" sx={{ borderBottom: 1, paddingBottom: "16px" }}>
                      <IconButton id="back-button" sx={{ display: {sm: "block", xs: "block", md: "none"}}} onClick={this.handleBackClick}>
                        <ArrowBackIcon fontSize="large" />
                      </IconButton>
                      <ListItemAvatar>
                        <Avatar alt={this.state.activeChatUser.first_name} />
                      </ListItemAvatar>
                      <ListItemButton
                        data-test-id="chatProfileNameBtn"
                        sx={{ textTransform: "initial" }} 
                        onClick={() => {this.setState({ displayProfile: true })}}
                      >
                        <Typography data-test-id="chatHeader" sx={{ fontWeight: "700", fontSize: "16px", fontFamily: "Outfit", color: "#CC9200", cursor: "pointer" }}>
                          {this.state.activeChatUser.first_name} {this.state.activeChatUser.last_name} {' | '}
                          {this.state.activeChatUser.user_type === 'tenant' ? 
                            "Tenant at "+this.state.activeChatUser.user_info.property_name +" - "+ this.state.activeChatUser.user_info.unit_name 
                            :
                            this.state.activeChatUser.user_type === 'landlord' ? "Landlord" : "Vendor"
                          }
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={{ display: "flex", height: "calc(100vh - 360px)", '@media (max-width: 992px)': {height: "calc(100vh - 360px)" }, alignItems: "end" }}>
                    <Box width={"100%"} height={"100%"}>
                      <List sx={webStyles.messageContentArea}>
                          {this.state.messageList?.map((message) => (
                              <React.Fragment key={message.id}>
                                  {message?.attributes?.attachments?.length > 0 && (
                                    message?.attributes?.attachments?.map((attachment: ChatAttachment) => (
                                      <Box sx={{ marginLeft: '70px', marginBottom: '16px' }} key={attachment.id}>
                                        {this.attachmentComp(attachment)}
                                      </Box>
                                    ))
                                  )}
                                  <ListItem id={`message-${message.id}`} alignItems="flex-start">
                                      <ListItemAvatar>
                                        <Avatar alt={message.attributes?.account?.first_name} />
                                      </ListItemAvatar>
                                      <ListItemText
                                          primary={
                                            <Box sx={{ display: "flex" }}>
                                              <Typography
                                                  sx={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Outfit' }}
                                              >
                                                  {message.attributes?.account?.first_name}{' '}
                                                  {message.attributes?.account?.last_name}{' | '}
                                              </Typography>
                                              &nbsp;
                                              <Typography sx={{ fontWeight: '400', fontSize: '16px', fontFamily: 'Outfit' }}>
                                              {message.attributes?.account?.user_type === 'tenant' ? 
                                              "Tenant at "+message.attributes?.account?.user_info.property_name +" - "+ message.attributes?.account?.user_info.unit_name 
                                              :
                                              message.attributes?.account?.user_type === 'landlord' ? "Landlord" : "Vendor"
                                              }
                                              </Typography>
                                            </Box>
                                          }
                                          secondary={
                                              <Typography sx={{ fontWeight: '400', fontSize: '16px', fontFamily: 'Outfit' }}>
                                                  {message.attributes?.message}
                                              </Typography>
                                          }
                                      />
                                  </ListItem>

                              </React.Fragment>
                          ))}
                      </List>

                      <ClickAwayListener onClickAway={() => this.setState({ showEmojiPicker: false })} mouseEvent="onMouseDown">
                        <Box 
                          position="absolute" 
                          zIndex="100" 
                          sx={{ bottom: 65 }}
                        >
                          <EmojiPicker 
                            open={this.state.showEmojiPicker}
                            skinTonesDisabled={true}
                            emojiStyle={EmojiStyle.GOOGLE}
                            suggestedEmojisMode={SuggestionMode.RECENT}
                            height={350}
                            onEmojiClick={this.handleEmojiClick}
                          />
                        </Box>
                      </ClickAwayListener>
                      
                      <Box sx={{ 
                        border: 1,
                        borderRadius: '4px',
                        borderColor: '#bdbdbd',
                        padding: 1
                      }}>
                        <Box sx={{ display: "flex", justifyContent: "row", alignItems: "center" }}>
                          <IconButton 
                            data-test-id="btnInsertImage"
                            sx={{ color: "#CC9200" }}
                            onClick={() => this.fileInput?.click()}
                          >
                            <AttachFileIcon />
                          </IconButton>
                          <input
                            type="file"
                            data-test-id="fileInput"
                            accept=".png,.jpeg,.jpg,.mp4,.webm,.ogg,.pdf"
                            style={{ display: "none" }}
                            ref={(input) => (this.fileInput = input)}
                            onChange={(event) => {
                              this.handleFileChange(event);
                              if (this.fileInput) {
                                this.fileInput.value = "";
                              }
                            }}
                          />
                          <IconButton
                            data-test-id="btnInsertEmoji"
                            sx={{ color: "#CC9200" }}
                            onClick={() => {this.setState({ showEmojiPicker: true })}}
                          >
                            <TagFacesIcon />
                          </IconButton>
                          <TextField
                            id="messageText"
                            name="messageText"
                            placeholder="Message Keasy"
                            onChange={this.handleInputChange}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.messageText}
                            fullWidth
                            InputProps={{
                              disableUnderline: true, // Disable underline in the input field
                              sx: {
                                border: 'none', // Remove the outline border
                              },
                            }}
                            variant="standard"
                          />
                          <IconButton
                            id="btnSend"
                            sx={webStyles.sendIcon}
                            onClick={this.handleSendMessage}
                          >
                            <SendIcon />
                          </IconButton>
                        </Box>
                        
                        {this.state.messageAttachment.length > 0 && (
                          <Box sx={{ display: "flex", justifyContent: "row", alignItems: "center", marginTop: 1 }}>
                            {this.state.messageAttachment.map(attachment => (
                              <Badge
                                sx={{ top: 5, right: 5 }}
                                badgeContent={
                                <IconButton
                                  data-test-id="remove-attachment-button"
                                  size="small"
                                  onClick={() => this.handleRemoveFile(attachment)}
                                  sx={{
                                    backgroundColor: '#CC9200',
                                    color: 'white',
                                    '&:hover': {
                                      backgroundColor: '#CC9200',
                                    },
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              }
                            >
                              <Grid container sx={{ border: 1, borderRadius: '4px', borderColor: '#bdbdbd', height: "120px", width: "200px", marginLeft: 1 }}>
                                <Grid item xs={5} sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#FFF1CC" }}>
                                  <InsertDriveFileIcon fontSize="large" sx={{color: "#bdbdbd"}} />
                                </Grid>
                                <Grid item xs={7} sx={{ display: "flex", padding: 1, alignItems: "center", overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>
                                  <Typography>
                                    {attachment.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Badge>
                            ))}
                          </Box>
                        )}
                        
                      </Box>
                  </Box>
                    </ListItem>
                  </List>
                </Grid>}
                <Grid item md={3} data-test-id="profile-section"
                  sx={{ display: {sm: "none", xs: "none", md: this.state.displayProfile ? 'block' : "none"}}}>
                  <List sx={{borderLeft: '2px solid #D0D0D0'}}>
                    <ListItem key="profile-header" sx={{ padding: "12px", borderBottom: 1 }} >
                      <IconButton data-test-id="close-profile-btn" onClick={() => {this.setState({ displayProfile: false })}}>
                        <CloseIcon />
                      </IconButton>
                      <ListItemText primary={
                        <Box>
                          <Typography sx={{ ml: 2, fontWeight: '700', fontSize: '20px', fontFamily: 'Outfit' }}>
                            Profile
                          </Typography>
                        </Box>
                      }/>
                    </ListItem>
                    <Box sx={{height: "70vh", overflowY: "auto"}}>
                        <Avatar sx={{ height: '240px', width: '100%', borderRadius: 0}} />

                        <ListItem sx={{ borderBottom: '2px solid #D0D0D0'}}>
                          <ListItemText primary={
                            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                              <Typography sx={{fontWeight: '700', fontSize: '24px', fontFamily: 'Outfit', whiteSpace: "nowrap"}}>
                                {this.state.activeChatUser.first_name} {this.state.activeChatUser.last_name}
                              </Typography>
                              &nbsp;
                              <Typography sx={{ textTransform: "capitalize", fontWeight: '400', fontSize: '24px', fontFamily: 'Outfit', flexShrink: 0 }}>
                              {'| '}{this.state.activeChatUser.user_type}
                              </Typography>
                            </Box>
                          } />
                        </ListItem>

                        <ListItem sx={{ borderBottom: '2px solid #D0D0D0', display: this.state.activeChatUser.user_type === 'landlord' ? 'none' : 'block' }}>
                          <ListItemText primary={
                            <>
                              <Typography color="textSecondary" sx={{fontWeight: '700', fontSize: '18px', fontFamily: 'Outfit'}}>
                                { this.state.activeChatUser.user_type === 'tenant' ? 
                                    this.state.activeChatUser.user_info.property_name +" - "+ this.state.activeChatUser.user_info.unit_name :
                                    this.state.activeChatUser.user_info.vendor_type
                                }
                              </Typography>
                              {
                                this.state.activeChatUser.user_type === 'tenant' ? 
                                  <Typography sx={{fontWeight: '400', fontSize: '18px', fontFamily: 'Outfit'}} color="textSecondary">
                                    Since {this.formatDate(this.state.activeChatUser.user_info.lease_start_date)}
                                  </Typography>
                                  :
                                  <Typography sx={{fontWeight: '400', fontSize: '18px', fontFamily: 'Outfit'}} color="textSecondary">
                                    {Array.isArray(this.state.activeChatUser.user_info.vendor_trade) 
                                    ? this.state.activeChatUser.user_info.vendor_trade.join(", ") 
                                    : ""}
                                  </Typography>
                              }
                              
                            </>
                          } />
                        </ListItem>

                        <ListItem>
                          <ListItemText primary={
                            <>
                              <Typography color="textSecondary" sx={{fontWeight: '700', fontSize: '16px', fontFamily: 'Outfit'}}>
                                Contact:
                              </Typography>
                              <Typography color="textSecondary" sx={{fontWeight: '400', fontSize: '16px', fontFamily: 'Outfit'}}>
                                Phone number: {this.state.activeChatUser.full_phone_number}
                              </Typography>
                              <Typography color="textSecondary" sx={{fontWeight: '400', fontSize: '16px', fontFamily: 'Outfit'}}>
                                Email: {this.state.activeChatUser.email}
                              </Typography>
                            </>
                          } />
                        </ListItem>
                    </Box>
                    
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
        </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  navBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000,
    "@media(max-width: 992px)": { display: "flex", "paddingTop": "1.5rem",
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    },
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  sendIcon: {
    backgroundColor: "#FFF1CC",
    color: "black",
    borderRadius: "25%",
  },
  addChatButton: {
    backgroundColor: "#FFF1CC",
    color: "black",
    aspectRatio: '1 / 1',
    minWidth: 'auto',
    padding: 1,
    marginBottom: 1,
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  newChat: {
    display: "flex",
    justifyContent: "space-between"
  },
  chatList: {
    overflow: " hidden",
    textWrap: "noWrap",
    textOverflow: "ellipsis",
  },
  modal_style: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    overflow:'scroll',
    display:'block',
    p: 4
  },

  modal_header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #A9A9A9",
    p: 2,
  },

  modal_content: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
    paddingBottom: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    marginTop: "72px",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
  },

  searchList: {
    border: '1px solid #ccc',
    width: '-moz-available',
    maxHeight: "100px",
    overflow: "auto"
  },

  imgAttachment: {
    width: '100%'
  },

  messageContentArea: {
    display: "flex", 
    height: "100%", 
    overflowY: "scroll", 
    flexDirection: "column-reverse", 
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },

  chatListContentArea: {
    display: "flex", 
    maxHeight: "calc(100vh - 360px)",
    overflowY: "scroll",
    padding: 0, 
    flexDirection: "column", 
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '@media (max-width: 992px)': {maxHeight: "calc(100vh - 240px)" },
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  }
};
// Customizable Area End
