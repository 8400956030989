import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { MouseEvent } from 'react';
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { convertResponseToFile } from "../../../components/src/MockApiHelperBlock";
interface FileWithPreview extends File {
  id?: any; 
  preview: string;
  file_name?: string;
}

interface Vehicle{
  id?: number | null;
  vehicleType: string;
  brand: string;
  modelYear: Date | null;
  vehicleReg: string;
  [key: string]: string | number | Date | null | undefined;
}

interface Pet {
  id: number | null;
  petType: string;
  petName: string;
  ageYear: Date | null;
  formFilesPet: FileWithPreview[];
  [key: string]: string | number | Date | null | undefined | FileWithPreview[];
}

interface FormErrors {
  name: string;
  dateOfBirthDate: string;
  email: string;
  address: string;
  areaCode: string;
  phoneNumber: string;
  formFilesInsurance: string;
  renewalDate: string;
  brand: string;
  modelYear: string;
  vehicleReg: string;
  petName: string;
  ageYear: string;
  formFilesPet: string;
  vehicleType: string;
  petType: string;
  occupent: string;
  dateOffirstPayment: string;
  payPeriod: string;
  rent: string;
  SecurityDeposit: string;
  LateCharges: string;
  payType: string;
  collectedBy: string;
  LeaseStartDate:string;
  leaseaddress:string;
  [key: string]: string; 
}

export interface DropData {
  id: number;
  name: string
}

interface INavigateToPage {
  id?: number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
type ValidatedFieldValue = Date | null | FileWithPreview[];
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeMyproperty: string;
  openLandlordDrawer: boolean;
  formFilesInsurance: FileWithPreview[];
  formFilesPet: FileWithPreview[];
  modelYear: Date | null;
  ageYear: Date | null;
  dateOfBirthDate: Date | null;
  renewalDate: Date | null;
  name: string;
  address: string;
  email: string;
  areaCode: string;
  phoneNumber: string;
  errMsg: string;
  brand: string;
  vehicleReg: string;
  petName: string;
  vehicleType: string;
  petType: string;
  occupent: string;
  dateOffirstPayment: Date | null;
  payPeriod: string;
  rent: string;
  SecurityDeposit: string;
  LateCharges: string;
  payType: string;
  collectedBy: string;
  LeaseStartDate: Date | null;
  leaseaddress: string;
  validationErrors: {
    name?: string;
    dateOfBirthDate?: string;
    address?: string;
    email?: string;
    areaCode?: string;
    phoneNumber?: string;
    vehicleRegistrationPlate?: string;
    petType?: string;
    petName?: string;
    ageYear?: string;
    petID?: string;
    formFilesInsurance?: string;
    renewalDate?: string;
    brand?: string;
    modelYear?: string;
    vehicleReg?: string;
    formFilesPet?: string;
    vehicleType?: string;
    occupent?: string;
    dateOffirstPayment?: string;
    payPeriod?: string;
    rent?: string;
    SecurityDeposit?: string;
    LateCharges?: string;
    payType?: string;
    collectedBy?: string;
    LeaseStartDate?: string;
    leaseaddress?: string;
  };
  activeStep: number;
  token: string;
  unitId: string;
  propertyId: string;
  vehicleList: DropData[];
  petList: DropData[];
  sucessMsg: string;
  formData: any,
  tenantId: any,
  editTenant: any,
  messagePopup: boolean;
  messageForPopup: string;
  messageType: 'success' | 'error' | 'warning' | 'info';
  errors: any,
  vehicleId: any,
  petId: any,
  vehicles: Vehicle[],
  pets: Pet[],
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class NewTenantController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
     postTenant: string="";
     getVehicle: string="";
     getPetType: string="";
     tenantCallId: string="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      activeMyproperty: "landlordproperties",
      openLandlordDrawer: false,
      formFilesInsurance: [],
      formFilesPet: [],
      modelYear: null,
      ageYear: null,
      dateOfBirthDate: null,
      renewalDate: null,
      name: "",
      address: "",
      email: "",
      areaCode: "",
      errMsg: "",
      dateOffirstPayment: null,
      validationErrors: {},
      phoneNumber: "",
      brand: "",
      vehicleReg: "",
      petName: "",
      vehicleType: "",
      petType: "",
      activeStep: 0,
      occupent: "",
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: null,
      leaseaddress: "",
      token: "",
      unitId: "",
      propertyId: "",
      vehicleList: [],
      petList: [],
      sucessMsg: '',
      formData: [],
      tenantId: "",
      editTenant: false,
      messagePopup: false,
      messageForPopup: "",
      messageType:  "success",
      errors:{},
      vehicleId: null,
      petId: null,
      vehicles: [
        {
          id: null,
          vehicleType: "",
          brand: "",
          modelYear: null,
          vehicleReg: ""
        }
      ],
      pets: [
        {
          id: null,
          petType: "",
          petName: "",
          ageYear: null,
          formFilesPet: []
        }
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
   
    // Customizable Area Start
    const token = await getStorageData('authToken');
    const propertyId = await getStorageData('propertyId');
    const tenantId = await getStorageData('tenantId');
    const currentUrl = window.location.href;
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ token: token, unitId: idData, propertyId: propertyId,tenantId: tenantId, editTenant: currentUrl.includes("EditTenant")});
    this.getVehicleData();
    this.getPetData();
    if(currentUrl.includes("EditTenant")){
      this.getTenantData();
    }
    // Customizable Area End
  }
  
  // Customizable Area Start

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.postTenant) {
        if(responseJson.data){
          
          this.setState({ sucessMsg: "Tenant added sucessfully"}, () => {
            this.clearData()
            this.navigateToPage({ props: this.props, screenName: "PortfolioManagement" })
          })
        } else if(responseJson.errors) {
          this.setState({
            messagePopup: true,
            messageType: "error",
            messageForPopup: responseJson.errors[0]
          })
        }
        return responseJson
      }
      if (apiRequestCallId === this.getVehicle) {
        this.setState({ vehicleList: responseJson?.data})
        return responseJson
      }
      if (apiRequestCallId === this.getPetType) {
        this.setState({ petList: responseJson?.data})
        return responseJson
      }
      if (apiRequestCallId === this.tenantCallId) {
        this.setState({ formData: responseJson.data})
        if (responseJson.data.attributes.insurances.length) {
          const formFilesInsurance = responseJson.data.attributes.insurances.map(async (insurance: any) => {
            return await convertResponseToFile(insurance.file_url, insurance.file_name, insurance.mime_type, insurance.id);
          });
        
          const resolvedFiles = await Promise.all(formFilesInsurance);
          this.handleFilesChange("formW9Files", resolvedFiles.filter(Boolean) as FileWithPreview[]);
        }

          const tenantPets = responseJson.data.attributes.tenant_pets.data || [];
          const mappedPets = tenantPets.map((pet: any) => ({
            id: pet.id,
            petType: pet.attributes.type_of_pet.id.toString(),
            petName: pet.attributes.pet_name,
            ageYear: new Date(pet.attributes.age),
            formFilesPet: [] 
          }));
        
          const petDocumentsPromises = tenantPets.map(async (pet: any) => {
            const petDocs = pet.attributes.pet_id.map(async (doc: any) => 
              await convertResponseToFile(doc.url, doc.file_name, doc.mime_type, doc.id)
            );
            return Promise.all(petDocs);
          });
          const resolvedPetDocuments = await Promise.all(petDocumentsPromises);
        
          const petsWithFiles = mappedPets.map((pet: any, index: any) => ({
            ...pet,
            formFilesPet: resolvedPetDocuments[index]
          }));
        
          

        const vehicles = responseJson.data.attributes.tenant_vehicles.data.map((vehicle: any) => ({
          id: vehicle.id,
          vehicleType: vehicle.attributes.class_of_vehicle.id.toString(),
          brand: vehicle.attributes.brand_name,
          modelYear: new Date(vehicle.attributes.model),
          vehicleReg: vehicle.attributes.vehicle_registration_plate
        }));        
        
        
        this.setState({
          name: responseJson?.data.attributes.name,
          modelYear: responseJson?.data.attributes.tenant_vehicles.data[0]?.attributes.model,
          ageYear: responseJson?.data.attributes.tenant_pets.data[0]?.attributes.age,
          dateOfBirthDate: responseJson?.data.attributes.date_of_birth,
          renewalDate: responseJson?.data.attributes.renewal_date,
          address: responseJson?.data.attributes.address,
          email: responseJson?.data.attributes.email_address,
          areaCode: responseJson?.data.attributes.area_code,
          dateOffirstPayment: responseJson?.data.attributes.date_of_first_payment_due,
          phoneNumber: responseJson?.data.attributes.phone_number,
          brand: responseJson?.data.attributes?.tenant_vehicles.data[0]?.attributes.brand_name,
          vehicleReg: responseJson?.data.attributes.tenant_vehicles.data[0]?.attributes.vehicle_registration_plate,
          vehicleId: responseJson?.data.attributes.tenant_vehicles.data[0]?.id,
          petName: responseJson?.data.attributes.tenant_pets.data[0]?.attributes.pet_name,
          vehicleType: responseJson?.data.attributes.tenant_vehicles.data[0]?.attributes.class_of_vehicle.id,
          petType: responseJson?.data.attributes.tenant_pets.data[0]?.attributes.type_of_pet.id,
          petId: responseJson?.data.attributes.tenant_pets.data[0]?.id,
          occupent: responseJson?.data.attributes.number_of_occupants,
          payPeriod: responseJson?.data.attributes.pay_period,
          rent: responseJson?.data.attributes.rent_amount_per_month,
          SecurityDeposit: responseJson?.data.attributes.security_deposit,
          LateCharges: responseJson?.data.attributes.late_charges,
          payType: responseJson?.data.attributes.payment_method,
          collectedBy: responseJson?.data.attributes.collected_by,
          LeaseStartDate: responseJson?.data.attributes.lease_start_date,
          leaseaddress: responseJson?.data.attributes.lease_address,
          vehicles: vehicles.length ? vehicles : [this.getDefaultVehicle()],
          pets: petsWithFiles.length ? petsWithFiles : [this.getDefaultPet()],
        })
        return responseJson
      }
    }
    // Customizable Area End
  }

  getDefaultVehicle = () => ({
    id: null,
    vehicleType: "",
    brand: "",
    modelYear: null,
    vehicleReg: ""
  });

  getDefaultPet = () => ({
    id: null,
    petType: "",
    petName: "",
    ageYear: null,
    formFilesPet: []
  });

  handleAddVehicle = () => {
    this.setState(prevState => ({
      vehicles: [
        ...prevState.vehicles,
        {
          id: null,
          vehicleType: "",
          brand: "",
          modelYear: null,
          vehicleReg: ""
        }
      ]
    }));
  };

  handleAddPet = () => {
    this.setState(prevState => ({
      pets: [
        ...prevState.pets,
        {
          id: null,
          petType: "",
          petName: "",
          ageYear: null,
          formFilesPet: []
        }
      ]
    }));
  };

  handleVehicleChange = (index: number, field: string, value: any) => {
    const newVehicles = [...this.state.vehicles];
    newVehicles[index][field] = value;
    this.setState({ vehicles: newVehicles }, () => this.validateField(field, value));
  };

  handlePetChange = (index: number, field: string, value: any) => {
    const newPets = [...this.state.pets];
    newPets[index][field] = value;
    this.setState({ pets: newPets }, () => this.validatePetField(index, field, value));
  };

  handlePetFilesChange = (index: number, files: FileWithPreview[]) => {
    const newPets = [...this.state.pets];newPets[index].formFilesPet = files;this.setState({ pets: newPets }, () => this.validatePetField(index, 'formFilesPet', files));
  };

  validatePetField = (index: number, field: string, value: any) => {
    const errors = { ...this.state.errors };
    
    switch (field) {
      case 'petType':
        errors[`petType_${index}`] = value ? '' : 'Pet type is required';
        break;
      case 'petName':
        errors[`petName_${index}`] = value ? '' : 'Pet name is required';
        break;
      case 'ageYear':
        errors[`ageYear_${index}`] = value ? '' : 'Pet age is required';
        break;
      case 'formFilesPet':
        errors[`formFilesPet_${index}`] = (Array.isArray(value) && value.length > 0) ? '' : 'Pet ID is required'; break; 
      default:
        break;
    }
    
    this.setState({ validationErrors: errors });
  };

  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMyproperty: labelKey }, () => {
      this.handleDashboardMenu();
    });
  };

  handleDashboardMenu = () => {
    const { activeMyproperty } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMyproperty
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer
    });
  };

  handleDateChange = (newValue: Date | null) => {
    this.setState({ dateOfBirthDate: newValue }, ()=> {
      this.validateDateField("dateOfBirthDate", newValue)
    });
  };

  handleLeaseDateChange = (newValue: Date | null) => {
    this.setState({ LeaseStartDate: newValue }, () => {
      this.validateDateField("LeaseStartDate",newValue)
    });
  };

  handledateOffirstPayment = (newValue: Date | null) => {
    this.setState({ dateOffirstPayment: newValue },() => {
      this.validateDateField("dateOffirstPayment", newValue)
    });
  };

  handleModelYearChange = (newYearValue: Date | null) => {
    this.setState({ modelYear: newYearValue }, () => {
      this.validateDateField("modelYear",newYearValue )
    });
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {  
    this.setState({
      formFilesInsurance: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateDateField("formFilesInsurance",files )
      return name
    });
  };

  handleFilesPetChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      formFilesPet: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateDateField("formFilesPet",files )
      return name
    });
  };

  handleAgeYearChange = (newAgeYearValue: Date | null) => {
    this.setState({ ageYear: newAgeYearValue }, () => {
      this.validateDateField("ageYear", newAgeYearValue)
    });
  };

  handleRenewalDateChange = (newdateValue: Date | null) => {
    this.setState({ renewalDate: newdateValue }, ()=> {
      this.validateDateField("renewalDate",newdateValue)
    });
  };

  handleSelect = (vehicle: string) => {
    this.setState({ vehicleType: vehicle})
  }

  handlePet = (petName: string) => {
    this.setState({ petType: petName})
  }

  handlePayment = (payName: string) => {
    this.setState({ payType: payName})
  }
  
   onValueChange = (name: string, value: string) => {

    let isValid = true;

    if (["name", "petName", "brand"].includes(name)) {
      const isOnlyCharacters = /^[a-zA-Z\s]*$/.test(value); 
      isValid = isOnlyCharacters;
    }
  
    if (["areaCode", "phoneNumber", "occupent", "rent", "SecurityDeposit","LateCharges"].includes(name)) {
      const isOnlyNumbers = /^\d*$/.test(value); 
      isValid = isOnlyNumbers && value.length <= 10;
    }

    if (["occupent"].includes(name)) {
      const isOnlyNumbers = /^\d*$/.test(value); 
      isValid = isOnlyNumbers && value.length <= 2;
    }
  
    if (isValid) {
      this.setState({ ...this.state, [name]: value  }, () => {
        this.validateField(name, value);
      });
    }
  };


  validateEmail = (email: string) => {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email);
  };

  validateField = (field: string, value: string) => {
    const { validationErrors } = this.state;
    switch (field) {
      case 'name':
        validationErrors.name = value ? '' : 'Name is required';
        break;
      case 'address':
        validationErrors.address = value ? '' : 'Address is required';
        break;
      case 'email':
        validationErrors.email = value && this.validateEmail(value) ? '' : 'Valid email is required';
        break;
      case 'areaCode':
        validationErrors.areaCode = this.areaCode(validationErrors.areaCode, value);
        break;
      case 'phoneNumber':
        validationErrors.phoneNumber = this.phoneNumber(validationErrors.phoneNumber, value);
        break;
      case 'brand':
        validationErrors.brand = this.brand(validationErrors.brand, value);
        break;
      case 'vehicleReg':
        validationErrors.vehicleReg = this.vehicleReg(validationErrors.vehicleReg, value);
        break;
      case 'petName':
        validationErrors.petName = this.petName(validationErrors.petName, value);
        break;
      case 'occupent':
        validationErrors.occupent = this.occupent(validationErrors.occupent, value);
        break;
      case 'payPeriod':
        validationErrors.payPeriod = this.payPeriod(validationErrors.payPeriod, value);
        break;
      case 'rent':
        validationErrors.rent = this.rent(validationErrors.rent, value);
        break;
      case 'SecurityDeposit':
        validationErrors.SecurityDeposit = this.SecurityDeposit(validationErrors.SecurityDeposit, value);
        break;
      case 'LateCharges':
        validationErrors.LateCharges = this.LateCharges(validationErrors.LateCharges, value);
        break;
      case 'collectedBy':
        validationErrors.collectedBy = this.collectedBy(validationErrors.collectedBy, value);
        break;
      case 'leaseaddress':
        validationErrors.leaseaddress = this.leaseaddressData(validationErrors.leaseaddress, value);
        break;
      default:
        break;
    }
    this.setState({ validationErrors });
  };

  areaCode = (validationErrors: string | undefined, value: string): string => {
    if (value) {
      return ''; 
    }
    return 'Area code is required';
  }
  phoneNumber = (validationErrors: string | undefined, value: string): string => {
    if (value) {
      return ''; 
    }
    return 'Phone number is required';
  }
    brand = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Brand is required';
    }
    vehicleReg = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Vehicle registration plate is required';
    }
    petName = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Pet name is required';
    }
    occupent = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Number of occupent is required';
    }
    payPeriod = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Pay Period is required';
    }
    rent = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'rent is required';
    }
    SecurityDeposit = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Security Deposit is required';
    }
    LateCharges = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Late Charges is required';
    }
    collectedBy = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Collectedby is required';
    }
    leaseaddressData = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Lease address is required';
    }

    validateForm = () => {
      const errors: FormErrors = this.getInitialErrors();
      const { activeStep } = this.state;
    
      if (activeStep === 0) {
        this.validateStep0(errors);
      } else if (activeStep === 1) {
        this.validateStep1(errors);
      }
    
      this.setState({ validationErrors: errors });
    
      return this.isValid(errors);
    };
    
    getInitialErrors = (): FormErrors => ({
      name: "",
      dateOfBirthDate: "",
      email: "",
      address: "",
      areaCode: "",
      phoneNumber: "",
      formFilesInsurance: "",
      renewalDate: "",
      brand: "",
      modelYear: "",
      vehicleReg: "",
      petName: "",
      ageYear: "",
      formFilesPet: "",
      vehicleType: "",
      petType: "",
      occupent: "",
      dateOffirstPayment: "",
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: "",
      leaseaddress: ""
    });
    
    validateStep0 = (errors: FormErrors) => {
      const {
        name, dateOfBirthDate, address, email, areaCode, phoneNumber,
        formFilesInsurance, renewalDate, brand, modelYear, vehicleReg,
        petName, ageYear, formFilesPet, vehicleType, petType, occupent
      } = this.state;
    
      this.checkRequiredField(errors, 'name', name, 'Name is required');
      this.checkRequiredField(errors, 'dateOfBirthDate', dateOfBirthDate, 'Date of birth is required');
      this.checkRequiredField(errors, 'address', address, 'Address is required');
      this.checkEmailField(errors, 'email', email);
      this.checkRequiredField(errors, 'areaCode', areaCode, 'Area code is required');
      this.checkRequiredField(errors, 'phoneNumber', phoneNumber, 'Phone number is required');
      this.checkRequiredArrayField(errors, 'formFilesInsurance', formFilesInsurance, 'Insurance file is required');
      this.checkRequiredField(errors, 'renewalDate', renewalDate, 'Renewal date is required');
      this.occupant(occupent, errors);

  this.state.pets.forEach((pet, index) => {
    const isFilled = Object.values(pet).some(value => value);
    if (isFilled) {
      this.checkRequiredField(errors, `petType_${index}`, pet.petType, 'Pet type is required');
      this.checkRequiredField(errors, `petName_${index}`, pet.petName, 'Pet name is required');
      this.checkRequiredField(errors, `ageYear_${index}`, pet.ageYear, 'Pet age is required');
      this.checkRequiredArrayField(errors, `formFilesPet_${index}`, pet.formFilesPet, 'Pet ID is required');
    }
  });

  this.state.vehicles.forEach((vehicle, index) => {
    const isFilled = Object.values(vehicle).some(value => value);
    
    if (isFilled) {
      this.checkRequiredField(errors, `vehicleType_${index}`, vehicle.vehicleType, 'Vehicle type is required');
      this.checkRequiredField(errors, `brand_${index}`, vehicle.brand, 'Vehicle brand is required');
      this.checkRequiredField(errors, `modelYear_${index}`, vehicle.modelYear, 'Vehicle model year is required');
      this.checkRequiredField(errors, `vehicleReg_${index}`, vehicle.vehicleReg, 'Vehicle registration is required');
    }
  });
  this.setState({errors})
    };
    
    validateStep1 = (errors: FormErrors) => {
      const {
        dateOffirstPayment, payPeriod, rent, SecurityDeposit, LateCharges,
        payType, collectedBy, LeaseStartDate, leaseaddress
      } = this.state;
    
      this.checkRequiredField(errors, 'dateOffirstPayment', dateOffirstPayment, 'Date of first payment is required');
      this.checkRequiredField(errors, 'payPeriod', payPeriod, 'Pay Period is required');
      this.checkRequiredField(errors, 'rent', rent, 'Rent is required');
      this.checkRequiredField(errors, 'SecurityDeposit', SecurityDeposit, 'Security Deposit is required');
      this.checkRequiredField(errors, 'LateCharges', LateCharges, 'Late Charges is required');
      this.checkRequiredField(errors, 'payType', payType, 'Payment type is required');
      this.checkRequiredField(errors, 'collectedBy', collectedBy, 'Collected by is required');
      this.checkRequiredField(errors, 'LeaseStartDate', LeaseStartDate, 'Lease Start Date is required');
      this.checkRequiredField(errors, 'leaseaddress', leaseaddress, 'Lease address is required');
    };
    
    checkRequiredField = (errors: FormErrors, field: keyof FormErrors, value: string | Date | null, errorMessage: string) => {
      if (!value) errors[field] = errorMessage;
    };
    
    checkRequiredArrayField = (errors: FormErrors, field: keyof FormErrors, value: string | FileWithPreview[], errorMessage: string) => {
      if (!value.length) errors[field] = errorMessage;
    };
    
    checkEmailField = (errors: FormErrors, field: keyof FormErrors, value: string) => {
      if (!value && !this.validateEmail(value)) errors[field] = 'Valid email is required';
    };
    
    
    isValid = (errors: FormErrors) => {
      return (Object.keys(errors) as Array<keyof FormErrors>).every((key) => !errors[key]);
    };
  assignError = (errors: { [key: string]: string }, key: string, message: string) => {
    errors[key] = message;
  };
  
  petId = (formFilesPet: FileWithPreview[], errors: { formFilesPet: string }) => {
    if (formFilesPet.length === 0) {
      this.assignError(errors, 'formFilesPet', 'Pet ID is required');
    }
  };

  vehicleType = (data: string, errors: { vehicleType: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'vehicleType', 'Vehicle type is required');
    }
  };
  
  petType = (data: string, errors: { petType: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'petType', 'Pet type is required');
    }
  };

  occupant = (data: string, errors: { occupent: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'occupent', 'Number of occupent is required');
    }
  };
  handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if(this.validateForm()){
      this.handleAddTenant()
    }
  };

  handleNext = () => {
    const isValid = this.validateForm();
    
    if (isValid) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  handleBackMain = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  validateDateField = (field: string, value: ValidatedFieldValue) => {
    const { validationErrors } = this.state;
    switch (field) {
      case 'dateOfBirthDate':
        validationErrors.dateOfBirthDate = this.dateOfBirthDate(validationErrors.dateOfBirthDate, value);
        break;
      case 'renewalDate':
        validationErrors.renewalDate = value ? '' : 'Renewal date is required';
        break; 
      case 'modelYear':
        validationErrors.modelYear = this.modelYear(validationErrors.modelYear, value);
        break; 
      case 'ageYear':
        validationErrors.ageYear = this.ageYear(validationErrors.ageYear, value);
        break; 
      case 'dateOffirstPayment':
        validationErrors.dateOffirstPayment = this.dateOffirstPayment(validationErrors.dateOffirstPayment, value);
        break; 
      case 'LeaseStartDate':
        validationErrors.LeaseStartDate = this.LeaseStartDate(validationErrors.LeaseStartDate, value);
        break; 
      case 'formFilesInsurance':
        validationErrors.formFilesInsurance = this.formFilesInsurance(validationErrors.formFilesInsurance, value);
        break; 
      case 'formFilesPet':
        validationErrors.formFilesPet = this.formFilesPet(validationErrors.formFilesPet, value);
        break; 
      default:
        break;
    }
    this.setState({ validationErrors });
  };

  dateOfBirthDate = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Date of birth is required';
  }

  modelYear = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Model year is required';
  }

  ageYear = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Age year is required';
  }

  dateOffirstPayment = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Date of first payment is required';
  }

  LeaseStartDate = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Lease Start Date is required';
  }

  formFilesInsurance = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value?.toString()) {
      return ''; 
    }
    return 'Insurance file is required';
  }

  formFilesPet = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value?.toString()) {
      return ''; 
    }
    return 'Pet ID is required';
  }

  handleAddTenant = async () => {
    const headers = { token: this.state.token };
    const formData = new FormData();
  
    this.appendBasicTenantData(formData);
    this.appendDateFields(formData);
    this.appendVehicleData(formData);
    this.appendPetData(formData);
    this.appendFiles(formData);
    this.appendFinancialData(formData);
  
    const endpoint = this.state.editTenant ? configJSON.putTenant : configJSON.postTenant;
    const method = this.state.editTenant ? configJSON.putAPiMethod : configJSON.exampleAPiMethod;
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postTenant = requestMessage.messageId;
  
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  appendBasicTenantData = (formData:any) => {
    formData.append('tenant[unit_id]', this.state.unitId);
    if (this.state.editTenant) formData.append('tenant[id]', this.state.tenantId);
    formData.append('tenant[property_id]', this.state.propertyId);
    formData.append('tenant[name]', this.state.name);
    formData.append('tenant[address]', this.state.address);
    formData.append('tenant[email_address]', this.state.email);
    formData.append('tenant[area_code]', this.state.areaCode);
    formData.append('tenant[phone_number]', this.state.phoneNumber);
  };
  
  appendDateFields = (formData:any) => {
    this.appendFormattedDate(formData, 'tenant[date_of_birth]', this.state.dateOfBirthDate);
    this.appendFormattedDate(formData, 'tenant[renewal_date]', this.state.renewalDate);
    this.appendFormattedDate(formData, 'tenant[date_of_first_payment_due]', this.state.dateOffirstPayment);
    this.appendFormattedDate(formData, 'tenant[lease_start_date]', this.state.LeaseStartDate);
  };
  
  appendFormattedDate = (formData:any, key:any, date:any) => {
    if (date) {
      const validDate = date instanceof Date ? date : new Date(date);
      if (!isNaN(validDate.getTime())) {
        formData.append(key, validDate.toISOString().split('T')[0]);
      }
    }
  };
  
  appendVehicleData = (formData:any) => {
    this.state.vehicles.forEach((vehicle, index) => {
      if (Object.values(vehicle).some(value => value)) {
        formData.append(`tenant[tenant_vehicles_attributes][${index}][id]`, vehicle.id?.toString() || '');
        formData.append(`tenant[tenant_vehicles_attributes][${index}][class_of_vehicle_id]`, vehicle.vehicleType);
        formData.append(`tenant[tenant_vehicles_attributes][${index}][brand_name]`, vehicle.brand);
        formData.append(`tenant[tenant_vehicles_attributes][${index}][model]`, vehicle.modelYear?.getFullYear().toString() || '');
        formData.append(`tenant[tenant_vehicles_attributes][${index}][vehicle_registration_plate]`, vehicle.vehicleReg);
      }
    });
  };
  
  appendPetData = (formData:any) => {
    this.state.pets.forEach((pet, index) => {
      if (Object.values(pet).some(value => value)) {
        formData.append(`tenant[tenant_pets_attributes][${index}][id]`, pet.id || '');
        formData.append(`tenant[tenant_pets_attributes][${index}][type_of_pet_id]`, pet.petType);
        formData.append(`tenant[tenant_pets_attributes][${index}][pet_name]`, pet.petName);
        formData.append(`tenant[tenant_pets_attributes][${index}][age]`, pet.ageYear?.getFullYear().toString() || '');
        
        pet.formFilesPet.forEach(file => {
          formData.append(`tenant[tenant_pets_attributes][${index}][pet_id][]`, file);
        });
      }
    });
  };
  
  appendFiles = (formData:any) => {
    const appendFilesToForm = (files:any, key:any) => {
      files.forEach((file:any) => formData.append(key, file));
    };
  
    const insuranceKey = 'tenant[insurances][]';
    const petDocsKey = 'tenant[tenant_pets_attributes][][pet_id][]';

      appendFilesToForm(this.state.formFilesInsurance, insuranceKey);
  };
  
  appendFinancialData = (formData:any) => {
    formData.append('tenant[pay_period]', this.state.payPeriod);
    formData.append('tenant[rent_amount_per_month]', this.state.rent);
    formData.append('tenant[security_deposit]', this.state.SecurityDeposit);
    formData.append('tenant[late_charges]', this.state.LateCharges);
    formData.append('tenant[payment_method]', this.state.payType);
    formData.append('tenant[collected_by]', this.state.collectedBy);
    formData.append('tenant[lease_address]', this.state.leaseaddress);
    formData.append('tenant[number_of_occupants]', this.state.occupent);
  };
  
  extractYear = (date:any) => {
    const validDate = date instanceof Date ? date : new Date(date);
    return !isNaN(validDate.getTime()) ? validDate.getFullYear().toString() : null;
  };
  
  


  clearData = () => {
    this.setState({
      unitId: "",
      propertyId: "",
      name: "",
      dateOfBirthDate: null,
      address: "",
      email: "",
      areaCode: "",
      phoneNumber: "",
      formFilesInsurance: [],
      renewalDate: null,
      vehicleType: "",
      brand: "",
      modelYear: null,
      vehicleReg: "",
      petType: "",
      petName: "",
      ageYear: null,
      formFilesPet: [],
      occupent: "",
      dateOffirstPayment: null,
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: null,
      leaseaddress: ""
    });
  }

  getTenantData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tenantCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTenantApi}/${this.state.tenantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getVehicleData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVehicle = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVehicleData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPetData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPetType = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPetTypeData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  navigateToPage = ({
    id,
    screenName,
    raiseMessage,
    props,
    }: INavigateToPage) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
    }
    navigateToAppointments = () => {
      this.props.navigation.goBack();
    };
    handlePopupClose = () => {
      this.setState({
        messagePopup: false
      });
    };
  // Customizable Area End

}
