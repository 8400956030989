import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Divider,
  FormGroup,
  Chip,
  IconButton,
  Alert,
  Select,
  MenuItem
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeProfile, keasyIcon, profileMain, projects, activeProjects } from "./assets";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Close from "@mui/icons-material/Close";
import FileUpload from "../../accountgroups/src/FileUpload";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
export const configJSON = require("./config.js");
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const approved = require("../../invoicebilling/assets/approved.png");
export const keyMainChain = require("../../invoicebilling/assets/key.png");
export const activeKeyMain = require("../../invoicebilling/assets/activeKey.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

import "react-datepicker/dist/react-datepicker.css";

const timeArray = [
  "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", 
  "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", 
  "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
]

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    const formType = this.props.formType || window.location.href.split('/').pop();
    return (
      <Grid container direction="row" wrap="nowrap" 
            sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
        >
          <Grid item sx={webStyles.navBarItem}>
            <NavigationSidebar
                data-test-id={"navId"}
                activeItem={this.state.activeVendorItem}
                sidebarItems={[
                  { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                  { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                  { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                  { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                  { label: configJSON.chatOrder, labelKey: configJSON.chatOrderLabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderLabel },
                ]}
                onClickSidebar={this.handleVendoritemClick}
                keasylogo={keasyIcon}
                openDrawer={this.state.openVendorDrawer}
                onClickDrawer={this.handleVendorDrawer}
            />
             <Box className="vendorCustomisableProfilesMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
             <CustomNavbar navigation={this.props.navigation} id="vendorCustomisableProfilesNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>
          </Grid>
          <Grid item sx={{ 
            flexGrow: 1, 
            borderLeft: "1px solid rgb(208,203,199)", 
            height: "100%", 
            marginLeft:  "7.5rem", "@media (max-width: 992px)": {marginLeft: 0,}}}>
            <Container
              maxWidth={false}
              sx={{
                paddingRight: "0px !important", paddingLeft: '30px !important', "@media (max-width: 992px)": {paddingRight: "1rem !important",  paddingLeft: '1rem !important', }
              }}
            ><Box className="vendorCustomisableProfilesNavbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>
              <CustomNavbar 
                navigation={this.props.navigation}
                id="CustomisableUserProfiles"
                showChatButton={true}   
                showPropertiesButton={false} 
              />
              </Box><Box className="customisableUserProfilesContainer" sx={{width: "90%", paddingRight: "30px", "@media (max-width: 992px)": {width:"100%", paddingRight: 0,},}}>
              <Typography sx={webStyles.vendor_update_header} paragraph style={{display: "flex", justifyContent: "space-between", alignItems: "end"}}>
                <Box>Update Vendor Details</Box>
                <Button sx={webStyles.goBackBtn} style={{ textTransform: 'none' }} id="backBtnTest" onClick={this.navigateToProfile}>Go Back</Button>
              </Typography>
              <Box sx={webStyles.form_container}>
              <PopupMessage
                open={this.state.messageOpen}
                type={this.state.messageType}
                message={this.state.message}
                handleClose={this.handlePopupMessageClose}
              />
                {formType === "vendorinfo" && (
                  <form id="form1" onSubmit={this.handleFormSubmit}>
                  <Divider sx={{ marginBottom: "46px" }} />
          
                  <Grid container spacing={2} sx={{ px: 4, pr: 8 , '@media (max-width: 992px)': { px: 0, pr: 0 },
}}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={webStyles.form_section_label}>
                        Vendor Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="name" sx={webStyles.input_label}>
                          Name
                        </FormLabel>
                        <TextField
                          required
                          id="name"
                          name="name"
                          fullWidth
                          autoComplete="name"
                          variant="outlined"
                          size="small"
                          error={this.state.errors.vendorName}
                          helperText={this.getHelperText("name")}
                          value={this.state.vendorData.name}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="vendor_type" sx={webStyles.input_label}>
                          Vendor Type
                        </FormLabel>
                        <TextField
                          required
                          id="vendor_type"
                          name="vendor_type"
                          fullWidth
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          size="small"
                          value={this.state.vendorData.vendor_type}
                          onChange={this.handleInputChange}
                        >
                          <option value="">Please Select</option>
                          {this.state.allVendorTypes?.map((vendorType) => (
                            <option key={vendorType} value={vendorType}>{vendorType}</option>
                          ))}
                        </TextField>
                      </FormGroup>
          
                      <FormLabel htmlFor="vendorTrade" sx={webStyles.input_label}>
                        Vendor Trade
                      </FormLabel>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          border: 1,
                          borderColor: "grey.400",
                          borderRadius: 1,
                          "&:hover": {
                            borderColor: "black",
                          },
                        }}
                      >
                        <TextField
                          id="vendorTrade"
                          name="vendorTrade"
                          fullWidth
                          placeholder="Separate with comma"
                          size="small"
                          value={this.state.trade}
                          onChange={this.handleTradeInput}
                          onKeyPress={this.handleKeyPress}
                          sx={{
                            flex: 1,
                            mr: 1,
                            "& fieldset": { border: "none" },
                          }}
                        />
                        <IconButton
                          id="addIconButton"
                          onClick={this.handleAddTrade}
                          sx={{ color: this.state.trade !== ""? "black" : "#BAB1AB" }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Box>
                      {this.validateField("vendorTrade") && (
                        <Alert id="tradeAlert" severity="error" sx={{ mt: 2 }}>
                          Please add atleast 1 trade
                        </Alert>
                      )}
                      <Box mt={2}>
                        {this.state.vendorData.vendor_trade.map((trade, index) => (
                          <Chip
                            key={index}
                            label={trade}
                            variant="outlined"
                            icon={
                              <IconButton
                                aria-label="delete"
                                data-test-id="deleteIconButton"
                                size="small"
                                onClick={() => this.handleDelete(trade)}
                                sx={{ mr: -1 }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            }
                            sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                          />
                        ))}
                      </Box>
                      {
                        this.state.vendorData.vendor_type !== "" && (
                          <>
                            <FormGroup>
                              <FormLabel htmlFor="minCost" sx={webStyles.input_label}>
                                Minimum Price
                              </FormLabel>
                              <TextField
                                required
                                id="minCost"
                                name="minCost"
                                autoComplete="name"
                                variant="outlined"
                                size="small"
                                error={this.state.errors.minCost}
                                helperText={this.getHelperText("minCost")}
                                value={this.state.minCost}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                            <Box display="flex" alignItems="center" sx={{marginBottom: "16px", marginTop: "16px"}}>
                              <Typography variant="body1" sx={{
                                    ...webStyles.input_label,
                                    '@media (max-width: 992px)': {width:"54px" },}} style={{ marginRight: 16, minWidth: "11%", color: "rgba(0, 0, 0, 0.6)" }}>Mon-Fri</Typography>
                              <FormControl variant="outlined" style={{ marginRight: 8 }}>
                                <TextField
                                  required
                                  key="monFriStartTime"
                                  id="monFriStartTime"
                                  name="monFriStartTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.monFriStartTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="monFriStartTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`monFriStartTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ margin: '0 8px', color: "rgba(0, 0, 0, 0.6)" }}>to</Typography>
                              <FormControl variant="outlined">
                                <TextField
                                  required
                                  id="monFriEndTime"
                                  name="monFriEndTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.monFriEndTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="monFriEndTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`monFriEndTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                            {this.validateField("monFri") && (
                              <Alert id="monFriAlert" severity="error" sx={{ mt: 2 }}>
                                End time must be later than the start time.
                              </Alert>
                            )}
                            <Box display="flex" alignItems="center" sx={{marginBottom: "16px", marginTop: "16px"}}>
                              <Typography variant="body1" sx={{
                                    ...webStyles.input_label, '@media (max-width: 992px)': {width:"54px" },}} 
                                    style={{ marginRight: 16, minWidth: "11%", color: "rgba(0, 0, 0, 0.6)" }}>Sat</Typography>
                              <FormControl variant="outlined" style={{ marginRight: 8 }}>
                                <TextField
                                  required
                                  id="satStartTime"
                                  name="satStartTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.satStartTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="satStartTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`satStartTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ margin: '0 8px', color: "rgba(0, 0, 0, 0.6)" }}>to</Typography>
                              <FormControl variant="outlined">
                                <TextField
                                  required
                                  id="satEndTime"
                                  name="satEndTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.satEndTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="satEndTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`satEndTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                            {this.validateField("sat") && (
                              <Alert id="satAlert" severity="error" sx={{ mt: 2 }}>
                                End time must be later than the start time.
                              </Alert>
                            )}
                            <Box display="flex" alignItems="center" sx={{marginBottom: "16px", marginTop: "16px"}}>
                              <Typography variant="body1" sx={{ ...webStyles.input_label, '@media (max-width: 992px)': {width:"54px" },}} 
                                    style={{ marginRight: 16, minWidth: "11%", color: "rgba(0, 0, 0, 0.6)", }}>Sun</Typography>
                              <FormControl variant="outlined" style={{ marginRight: 8 }}>
                                <TextField
                                  required
                                  id="sunStartTime"
                                  name="sunStartTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.sunStartTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="sunStartTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`sunStartTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                              <Typography variant="body1" sx={webStyles.input_label} style={{ margin: '0 8px', color: "rgba(0, 0, 0, 0.6)" }}>to</Typography>
                              <FormControl variant="outlined">
                                <TextField
                                  required
                                  id="sunEndTime"
                                  name="sunEndTime"
                                  select
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                  size="small"
                                  value={this.state.sunEndTime}
                                  onChange={this.handleInputChange}
                                >
                                  <option key="sunEndTime" value="">--:--</option>
                                  {timeArray.map((time) => (
                                    <option key={`sunEndTime-${time}`} value={time}>{this.convertTo12HourFormat(time)}</option>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Box>
                            {this.validateField("sun") && (
                              <Alert id="sunAlert" severity="error" sx={{ mt: 2 }}>
                                End time must be later than the start time.
                              </Alert>
                            )}
                          </>
                        )
                      }
                    </Grid>
                  </Grid>
          
                  <Divider sx={{ marginBottom: "46px", marginTop: "42px" }} />
          
                  <Grid container spacing={2} sx={{ px: 4, pr: 8, '@media (max-width: 992px)': { p: '0px' },
 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={webStyles.form_section_label}>
                        Contact Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="phone" sx={webStyles.input_label}>
                          Phone Number
                        </FormLabel>
                        <Grid container spacing={2} sx={{"@media (max-width: 992px)": {flexFlow:"row" },}}>
                          <Grid item xs={5} sm={3}>
                            <FormControl fullWidth>
                              <Select
                                id="country_code"
                                name="country_code"
                                variant="outlined"
                                size="small"
                                value={this.state.vendorData.country_code}
                                onOpen={this.handleCountryCodeDropdown}
                                onChange={this.handleCountryChange}
                                onClose={() => {this.setState({ isCountryDropdownActive: false })}}
                                sx={{
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "4px",
                                }}
                              >
                                <MenuItem value="">Select a country</MenuItem>
                                {this.state.countries?.map((country) => (
                                  <MenuItem key={country.name} value={country.country_code}>
                                    <span style={{ display: this.state.isCountryDropdownActive? "block" : "none"}}>
                                      {country.emoji_flag} {country.name} (+{country.country_code})
                                      </span>
                                    <span style={{ display: this.state.isCountryDropdownActive? "none" : "block"}}>+{country.country_code}</span>
                                  </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <TextField
                              required
                              id="phone_number"
                              name="phone_number"
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={this.state.errors.phone}
                              helperText={this.getHelperText("phone_number")}
                              value={this.state.vendorData.phone_number}
                              onChange={this.handleInputChange}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="email_address" sx={webStyles.input_label}>
                          Email Address
                        </FormLabel>
                        <TextField
                          required
                          id="email_address"
                          name="email_address"
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={this.state.errors.email}
                          helperText={this.getHelperText("email_address")}
                          value={this.state.vendorData.email_address}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="address" sx={webStyles.input_label}>
                          Address
                        </FormLabel>
                        <TextField
                          required
                          id="address"
                          name="address"
                          fullWidth
                          autoComplete="name"
                          variant="outlined"
                          size="small"
                          value={this.state.vendorData.address}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
          
                  <Divider sx={{ marginBottom: "46px", marginTop: "42px" }} />
          
                  <Grid container spacing={2} sx={{ px: 4, '@media (max-width: 992px)': { px: 0 },}}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={webStyles.form_section_label}>
                        Federal Tax
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="taxpayer_name" sx={webStyles.input_label}>
                          Taxpayer Name
                        </FormLabel>
                        <TextField
                          required
                          id="taxpayer_name"
                          name="taxpayer_name"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={this.state.vendorData.taxpayer_name}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormGroup sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="taxpayer_id" sx={webStyles.input_label}>
                          Taxpayer ID
                        </FormLabel>
                        <TextField
                          required
                          id="taxpayer_id"
                          name="taxpayer_id"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={this.state.vendorData.taxpayer_id}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
          
                      <FormControl sx={{ marginBottom: "16px" }}>
                        <FormLabel htmlFor="send1099" 
                          sx={{
                            ...webStyles.input_label,
                            '&.Mui-focused': {
                              color: 'rgba(0, 0, 0, 0.6)',
                            },
                          }}
                        >
                          Send 1099?
                        </FormLabel>
                        <RadioGroup
                          value={this.state.send1099}
                          aria-labelledby="send1099"
                          name="send1099"
                          onChange={this.handleInputChange}
                        >
                          <FormControlLabel
                            value="yes"
                            id="yes"
                            control={<CustomRadioButton />}
                            label="Yes"
                          />
                          <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label="No" />
                        </RadioGroup>
                      </FormControl>
          
                      <FileUpload
                        label="Form W-9"
                        name="formW9Files"
                        files={this.state.formW9Files}
                        onFilesChange={this.handleFilesChange}
                      />
                      {this.validateField("formW9Files") && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          Please attach a file
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"end"}>
                    <Button
                      size="medium"
                      id="update"
                      type="submit"
                      sx={webStyles.form_button}
                    >
                      Update
                    </Button>
                  </Box>
                </form>
                )}
                {formType === "onlinepayables" && (
                    <form id="form2" onSubmit={this.handleFormSubmit}>
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4, '@media (max-width: 992px)': { p: 0 } }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          Online Payables
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="onlinePayables"
                            sx={{
                              ...webStyles.input_label,
                              '&.Mui-focused': {
                                color: 'rgba(0, 0, 0, 0.6)',
                              },
                            }}
                          >
                            Use online payables?
                          </FormLabel>
                          <RadioGroup
                            value={this.state.onlinePayables}
                            aria-labelledby="onlinePayables"
                            id="onlinePayables"
                            name="onlinePayables"
                            onChange={this.handleInputChange}
                          >
                            <FormControlLabel
                              value="yes"
                              id="yes"
                              control={<CustomRadioButton />}
                              label="Yes"
                            />
                            <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label="No" />
                          </RadioGroup>
                        </FormControl>
            
                        <FileUpload
                          label="Upload voided check (optional)"
                          name="voidedCheckFiles"
                          files={this.state.voidedCheckFiles}
                          onFilesChange={this.handleFilesChange}
                        />
            
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="vendor_bank_routing_number" sx={webStyles.input_label}>
                            Vendor Bank Routing Number (optional)
                          </FormLabel>
                          <TextField
                            id="vendor_bank_routing_number"
                            name="vendor_bank_routing_number"
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={this.state.errors.vendor_bank_routing_number}
                            helperText={this.getHelperText("vendor_bank_routing_number")}
                            value={this.state.vendorData.vendor_bank_routing_number}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
            
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="vendor_bank_account_number" sx={webStyles.input_label}>
                            Vendor Bank Account Number (optional)
                          </FormLabel>
                          <TextField
                            id="vendor_bank_account_number"
                            name="vendor_bank_account_number"
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={this.state.errors.vendor_bank_account_number}
                            helperText={this.getHelperText("vendor_bank_account_number")}
                            value={this.state.vendorData.vendor_bank_account_number}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
            
                        <FormControl sx={{ marginBottom: "16px", display: "flex" }}>
                          <FormLabel htmlFor="payment_type" 
                            sx={{
                              ...webStyles.input_label,
                              '&.Mui-focused': {
                                color: 'rgba(0, 0, 0, 0.6)',
                              },
                            }}
                          >
                            Payment type
                          </FormLabel>
                          <RadioGroup
                            value={this.state.vendorData.payment_type}
                            aria-labelledby="payment_type"
                            id="payment_type"
                            name="payment_type"
                            onChange={this.handleInputChange}
                          >
                            <FormControlLabel
                              value="eCheck"
                              id="eCheck"
                              control={<CustomRadioButton />}
                              label="eCheck"
                            />
                            <FormControlLabel
                              value="payViaCheckInMail"
                              id="payViaCheckInMail"
                              control={<CustomRadioButton />}
                              label="Pay via check in mail"
                            />
                            <FormControlLabel
                              value="payOnlineViaVendorsWebsite"
                              id="payOnlineViaVendorsWebsite"
                              control={<CustomRadioButton />}
                              label="Pay online via vendor's website"
                            />
                            <FormGroup sx={{ marginBottom: "12px", ml: 3 }}>
                              <FormLabel htmlFor="upload_website_link" 
                                sx={{
                                ...webStyles.input_label,
                                '&.Mui-focused': {
                                  color: 'rgba(0, 0, 0, 0.6)',
                                },
                              }}>
                                Upload website link
                              </FormLabel>
                              <TextField
                                id="upload_website_link"
                                name="upload_website_link"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={this.state.vendorData.upload_website_link}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
            
                            <FormControlLabel
                              value="payWithVenmo"
                              id="payWithVenmo"
                              control={<CustomRadioButton />}
                              label="Pay with Venmo"
                            />
                            <FormGroup sx={{ marginBottom: "12px", ml: 3 }}>
                              <FormLabel
                                htmlFor="venmo_account_number"
                                sx={{
                                  ...webStyles.input_label,
                                  '&.Mui-focused': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                  },
                                }}
                              >
                                Upload Venmo account number
                              </FormLabel>
                              <TextField
                                id="venmo_account_number"
                                name="venmo_account_number"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={this.state.vendorData.venmo_account_number}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
            
                            <FormControlLabel
                              value="payWithZelle"
                              id="payWithZelle"
                              control={<CustomRadioButton />}
                              label="Pay with Zelle"
                            />
                            <FormGroup sx={{ ml: 3 }}>
                              <FormLabel
                                htmlFor="zelle_account_number"
                                sx={{
                                  ...webStyles.input_label,
                                  '&.Mui-focused': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                  },
                                }}
                              >
                                Upload Zelle account number
                              </FormLabel>
                              <TextField
                                id="zelle_account_number"
                                name="zelle_account_number"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={this.state.vendorData.zelle_account_number}
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </RadioGroup>
                        </FormControl>
            
                        <FormGroup>
                          <FormLabel htmlFor="comments" sx={webStyles.input_label}>
                            Comments
                          </FormLabel>
                          <TextField
                            id="comments"
                            name="comments"
                            multiline
                            rows={3}
                            variant="outlined"
                            helperText={
                              <span style={{ float: "right" }}>
                                {this.state.vendorData.comments.length}/100
                              </span>
                            }
                            inputProps={{ maxLength: 100 }}
                            value={this.state.vendorData.comments}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Box display={"flex"} justifyContent={"end"}>
                      <Button
                        size="medium"
                        id="update"
                        type="submit"
                        sx={webStyles.form_button}
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                )}
                {formType === "complianceinfo" && (
                    <form id="form3" onSubmit={this.handleFormSubmit}>
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4, '@media (max-width: 992px)': { px: '0rem' } }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          General liability
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FileUpload
                          label="Upload business license"
                          name="businessLicense"
                          files={this.state.businessLicense}
                          onFilesChange={this.handleFilesChange}
                        />
                        {this.validateField("businessLicense") && (
                        <Alert id="businessLicenseAlert" severity="error" sx={{ mt: 2 }}>
                          Please attach a file
                        </Alert>
                        )}
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel htmlFor="licenseExpDate" sx={webStyles.input_label}>
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            required={true}
                            onChange={this.handleDateChange(
                              "licenseExpDate"
                            )} 
                            value={this.state.licenseExpDate} />
                        </FormGroup>
            
                        <FileUpload
                          label="Upload business insurance"
                          name="businessInsurance"
                          files={this.state.businessInsurance}
                          onFilesChange={this.handleFilesChange}
                        />
                        {this.validateField("businessInsurance") && (
                        <Alert id="businessInsuranceAlert" severity="error" sx={{ mt: 2 }}>
                          Please attach a file
                        </Alert>
                        )}
                        <FormGroup sx={{ marginBottom: "42px" }}>
                          <FormLabel htmlFor="insuranceExpDate" sx={webStyles.input_label}>
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            required={true}
                            value={
                              this.state.insuranceExpDate
                            }
                            onChange={
                              this.handleDateChange(
                              "insuranceExpDate"
                            )}
                            />
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4, '@media (max-width: 992px)': { px: '0px' } }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          EPA Certification (optional)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FileUpload
                          label="Upload EPA Certification license"
                          name="EpaCertificationLicense"
                          files={this.state.EpaCertificationLicense}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel
                            htmlFor="EpaCertLicenseExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                          onChange={this.handleDateChange(
                            "EpaCertLicenseExpDate"
                          )} 
                          required={false}
                          value={
                            this.state.EpaCertLicenseExpDate
                          }
                          ></CustomDatePicker>
                        </FormGroup>
            
                        <FileUpload
                          label="Upload other certificate"
                          name="otherCertificate"
                          files={this.state.otherCertificate}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "42px" }}>
                          <FormLabel
                            htmlFor="otherCertificateExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                          onChange={
                            this.handleDateChange("otherCertificateExpDate")
                        } 
                            value={this.state.otherCertificateExpDate}
                            />
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4, '@media (max-width: 992px)': { padding: "0" } }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>
                          State license (optional)
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FileUpload
                          label="Upload state license"
                          name="stateLicense"
                          files={this.state.stateLicense}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "16px" }}>
                          <FormLabel
                            htmlFor="stateLicenseExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            onChange={this.handleDateChange(
                              "stateLicenseExpDate"
                            )}
                            value={this.state.stateLicenseExpDate}>
                              
                            </CustomDatePicker>
                        </FormGroup>
            
                        <FileUpload
                          label="Upload state insurance"
                          name="stateInsurance"
                          files={this.state.stateInsurance}
                          onFilesChange={this.handleFilesChange}
                        />
                        <FormGroup sx={{ marginBottom: "42px" }}>
                          <FormLabel
                            htmlFor="stateInsuranceExpDate"
                            sx={webStyles.input_label}
                          >
                            Exp. Date
                          </FormLabel>
                          <CustomDatePicker
                            onChange={
                              this.handleDateChange(
                              "stateInsuranceExpDate"
                            )} 
                            value={
                              this.state.stateInsuranceExpDate
                            }
                            >
                          </CustomDatePicker>
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Divider sx={{ marginBottom: "46px" }} />
            
                    <Grid container spacing={2} sx={{ px: 4, '@media (max-width: 992px)': { paddingLeft: '0px', paddingRight: "0px" } }}>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={webStyles.form_section_label}>Notes</Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormGroup>
                          <TextField
                            id="notes"
                            name="notes"
                            multiline
                            rows={3}
                            variant="outlined"
                            helperText={
                              <span style={{ float: "right" }}>
                                {this.state.vendorData.notes.length}/100
                              </span>
                            }
                            inputProps={{ maxLength: 100 }}
                            value={this.state.vendorData.notes}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
            
                    <Box display={"flex"} justifyContent={"end"}>
                      <Button
                        id="update"
                        size="medium"
                        type="submit"
                        sx={webStyles.form_button}
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                )}
              </Box>
              </Box></Container>
          </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  navBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000,
    "@media(max-width: 992px)": {
      paddingTop: "24px",
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
      display: "flex"
    },
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  vendor_update_header: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
  },
  form_container: {
    paddingLeft: "152px",
      '@media (max-width: 992px)': {
        paddingLeft: "0px",
      }
  },
  form_section_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    color: "#BAB1AB",
  },
  input_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    marginTop: "72px",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFF1CC",
    },
  },
  form_back_button: {
    backgroundColor: "#FFF1CC",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    marginTop: "72px",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  "&.Mui-disabled": {
    backgroundColor: "#FFF1CC",
  },
  goBackBtn: {
    width: "80px",
    height: "32px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "14px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
  },
}
// Customizable Area End
