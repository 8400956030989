 // Customizable Area Start
 Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.NewpropertyEndpoint = "account_block/properties"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PortfolioManagement";
exports.labelBodyText = "PortfolioManagement Body";
exports.updateVendorEndPoint ="account_block/vendor_accounts";
exports.dashboardLandloard1 = "Dashboard";
exports.myAccountlandloard1 = "My Account";
exports.worklandloard1 = "Work Orders";
exports.preferredlandloard1 = "Preferred Vendors";
exports.reminderslandloard1 = "Reminders";
exports.myproplandloard1 = "My Properties";
exports.financialandloard1 = "Financial";
exports.reportlandloard1 = "Delinquency Report";
exports.chatLandloard1 = "Chat with Keasy";
exports.settingLandlord1 = "Settings";
exports.dashboardLandlabel1 = "landlordashboard";
exports.myAccountlandlabel1 = "landlordaccount";
exports.workOrderslandlabel1 = "landlordworkOrders";
exports.prevendorlandlabel1 = "landlordvendors";
exports.reminlandlabel1 = "landlordreminders";
exports.myproplandlabel1 = "landlordproperties";
exports.reportlandlabel1 = "landlordreport";
exports.chatlandlabel1 = "landlordchat";
exports.settinglandlabel = "Settings2";
exports.dashboardLandlabel = "Dashboard";
exports.workOrderslandlabel = "Appointments";
exports.prevendorlandlabel = "TaskList";
exports.reminlandlabel = "AutomatedPrioritization";
exports.financlandlabel = "Analytics";
exports.reportlandlabel = "VisualAnalytics";
exports.chatlandlabel = "Chat"

exports.btnExampleTitle = "CLICK ME";
exports.backbutton = "Back";
exports.selectCountry = "Select a country";
exports.getAreaCodeAPIEndPoint = "account_block/landlord_accounts/countries_listing";
exports.nextbutton = "Next";
exports.newproperty = "  New Property"
exports.save = "Save";
exports.cancelButton = "Cancel";
exports.description = "Description";
exports.folioGuardPolicy = "FolioGuard Policy";
exports.amenitiesValue = "Amenities";
exports.rentalLicense = "Rental license";
exports.propertyInformation = "Update Property Information";
exports.portfolio = "Portfolio";
exports.siteManager = "Site manager";
exports.years = "Year built";
exports.classInfo = "Class";
exports.managementStart = "Management start date";
exports.managementEnd = "Management end date";
exports.staticValues = "(Static)"
exports.managementEndReason = "Management end reason";
exports.renewalDate = "Renewal date";
exports.additionalIntrest = "Additional Interest";
exports.mainLimits = "Maintenance limit";
exports.insuranceExpiration = "Insurance Expiration";
exports.homeWarranty = "Has home warranty coverage";
exports.unitEntry = "Unit entry pre-authorized";
exports.mainNotes = "Maintenance notes";
exports.onlineMain = "Online maintenance";
exports.maintenanceInformation = "Update Maintenance Information";
exports.reqInstructions = "Request instructions";
exports.areaCodes = "Area Code";
exports.phoneNumbers = "Phone number";
exports.contactInfo = " Update Vacancy Posting Contact Info";
exports.extentions = "Extension";
exports.phone = "Phone";
exports.dogsAllowed = "Dogs Allowed";
exports.catsAllowed = "Cats Allowed";
exports.editAmenities = "Update Amenities";
exports.amenities = "Amenities";
exports.propertyMarkName = "Property marketing name";
exports.marketDesc = "Marketing description";
exports.updateAmenities = "Update Amenities";
exports.uploads = "Upload";
exports.samples = "Sample Name";
exports.uploadAttachment = "Upload Attachments";
exports.uploadAttachmentApiEndPoint = "account_block/properties/upload_attachment";
exports.getAttachmentApiEndPoint = "account_block/properties/attachments?id=";
exports.deleteApiEndPoint = "account_block/properties/delete_attachment";
exports.deleteAPiMethod = "DELETE";
exports.results = "results";
exports.ofValue = "of";
exports.updateMarketing = "Update Marketing Information";
exports.updated = "Update Details";
exports.lisingType = "Lising Type";
exports.mrsName = "Mrs";
exports.missName = "Miss";
exports.docsLandlordsLabel = "Cfdocumentforwarding4";
exports.documentsLandlord = "Documents";
exports.msName = "Ms";
exports.mrName = "Mr";
exports.getAreaCodeApiEndPoint = "account_block/landlord_accounts/countries_listing";
exports.emailAddress = "example@example.com";
exports.phoneError = "Please enter a valid phone number.";
exports.editFindKeyApiEndPoint = "account_block/units/";
exports.emailError = "Please enter a valid email address.";
exports.propertyType = "Property type";
exports.slashs = "-";
exports.addressName = "Address";
exports.email = "Email address";
exports.firstName = "First name";
exports.lastName ="Last name";
exports.addressError = "The input box must not exceed 30 characters.";
exports.phoneRegax = /^\d{10}$/;
exports.nameRegax = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
exports.emailsRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.keysLocation = "Keys location";
exports.floor = "Floor";
exports.entrance = "Entrance";
exports.code = "Code";
exports.tenant = "Add tenant";
exports.locationNotes = "Location notes";
exports.updateApiMethodType = "PUT";
exports.putApiEndPoint = "account_block/properties/update_property_information";

exports.btnExampleTitle = "CLICK ME";
exports.backbutton = "Back";
exports.nextbutton = "Next";
exports.newproperty = "  New Property";
exports.getProperty = "account_block/properties";
exports.getPropertySearch = "account_block/properties/search";
exports.postTenant = "bx_block_portfolio_management/tenants";
exports.putTenant = "bx_block_portfolio_management/tenants/update_tenant_information";
exports.getPropertyInfo = "account_block/properties/show_individual_property";
exports.postUnit = "account_block/units";
exports.getVehicleData = "bx_block_categories/categories/vehicle_list";
exports.getPetTypeData = "bx_block_categories/categories/pet_types_list";
exports.financialandloard = "Financial";
exports.financlandlabel = "Analytics";
exports.servicePath = "LandlordServiceRequestsDashboard";
exports.propertiesApiEndPoint = "account_block/properties"
exports.propertiesHead = "Properties";
exports.getTenantApi = "bx_block_portfolio_management/tenants";
exports.serviceLable = "Service Requests";
exports.getPropertyTypesAPIEndpoint = "account_block/property_types";
exports.getPropertyClassesAPIEndpoint = "account_block/property_classes";
exports.getPropertyPortfoliosAPIEndpoint = "account_block/property_portfolios";
// Customizable Area End