import React from "react";
// Customizable Area Start
import { Box, styled, Typography, Button } from "@mui/material";
import {
  keasyLogo,
  facebook,
  instagram,
  twitter,
  keasyFont,
  backgroundForgetPassword,
  backgroundPassImg
} from "./assets";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";

type Values = {
  email: string;
};
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import CustomInputField from "../../../components/src/CustomInputField.web";
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <BackgroundContainer>
        <ForgotPasswordContainer>
          <ContainerMain>
            <MainTopContainer>
              <img src={keasyLogo} alt="keyslogo" width={55} />
              <img src={keasyFont} alt="keasyHead" />
            </MainTopContainer>
            <ForgotPasswordBox>
              <ForgotPasswordHead variant="h5" gutterBottom data-test-id = "passwordId">
                {this.passwordRecovery}
              </ForgotPasswordHead>
              <ForgotPasswordText>
                <ForgotPasswordHeadingsTitle gutterBottom>
                  {this.forgotPassword}
                </ForgotPasswordHeadingsTitle>
                <ForgotPasswordHeadings>
                  {this.forgotSubtitles}
                </ForgotPasswordHeadings>
              </ForgotPasswordText>
            </ForgotPasswordBox>
            {this.state.errorMessage === this.emailDoesNotExist ? (
              <ErrorMessage data-test-id = "errorId">{this.state.errorMessage}</ErrorMessage>
            ) : null}
            <ButtonContainer>
              <Formik
                data-test-id="formik"
                enableReinitialize={true}
                validateOnBlur={true}
                initialValues={{
                  email: this.state.emailValueWeb,
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email(this.pleaseEnterAValidEmail)
                    .required(this.emailIsRequired),
                })}
                onSubmit={(values: Values, { setSubmitting }) => {
                  this.handleSubmit(values, setSubmitting);
                }}
              >
                {(props) => (
                  <Form className="signup_form_formik">
                    <Field
                      className="input-box"
                      testID="txtInputEmail"
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Your email"
                      value={props.values.email}
                      onChange={(event: { target: { value: string } }) => {
                        this.setEmail(event.target.value);
                        props.setFieldValue("email", event.target.value);
                      }}
                      as={CustomInputField}
                    />
                    <ErrorMessageInput>{props.errors.email}</ErrorMessageInput>
                    <RecoveryLinkButton
                      data-test-id="loginId"
                      variant="contained"
                      type="submit"
                    >
                      {this.sendRecoveryLink}
                    </RecoveryLinkButton>
                  </Form>
                )}
              </Formik>
            </ButtonContainer>

            <LoginLinkAccount variant="body2">
              {this.backTo}
              <LoginLink onClick={this.handleClickLogin}>
                {this.login}
              </LoginLink>
            </LoginLinkAccount>
            <SocialMediaContainer>
              <SocialMediaImg src={facebook} alt="facebook" />
              <SocialMediaImg src={instagram} alt="instagram" />
              <SocialMediaImg src={twitter} alt="twitter" />
            </SocialMediaContainer>
          </ContainerMain>
        </ForgotPasswordContainer>
      </BackgroundContainer>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const ForgotPasswordHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
});
const ErrorMessage = styled(Box)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#DC2626",
  padding: "12px 16px 12px 16px",
  backgroundColor: "#FEE2E2",
  borderLeft: "4px solid #DC2626",
  borderRadius: "4px",
  marginBottom: "20px",
  maxWidth: "315px",
});
const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "&.signup_form_formik": {
    width: "fit-content"
  }
});
const ErrorMessageInput = styled(Box)({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#DC2626",
});
const MainTopContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const ContainerMain = styled(Box)({
  width: "380px",
  padding: "1.5rem 0px",
  "@media(max-width: 600px)": {
    width: "100%",
  },
});
const ForgotPasswordContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media(max-width: 700px)": {
    marginTop: "2em",
    padding: "0 24px",
  },
});
const ForgotPasswordText = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const ForgotPasswordHeadings = styled(Typography)({
  fontSize: "18px",
  color: "#544B45",
  fontWeight: 400,
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const ForgotPasswordHeadingsTitle = styled(Typography)({
  fontSize: "19px",
  color: "#544B45",
  fontWeight: "bold",
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const LoginLinkAccount = styled(Typography)({
  display: "flex",
  gap: "10px",
  fontWeight: 400,
  fontSize: "18px",
  marginTop: "1em",
  alignItems: "center",
  "@media(max-width: 600px)": {
    fontSize: "14px",
  },
});
const SocialMediaContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10em",
  position: "absolute",
  width: "inherit",
  bottom: "3rem",
  "@media(max-width: 700px)": {
    marginTop: "2em",
    position: "absolute",
    bottom: 0,
    width: "inherit"
  },
});
const ForgotPasswordBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "15px",
  margin: "60px 0px 30px 0px",
});
const UserButton = styled(Button)({
  marginBottom: "15px",
  width: "100%",
});

const RecoveryLinkButton = styled(UserButton)({
  width: "360px",
  color: "#000000",
  textTransform: "none",
  backgroundColor: "#FFC123",
  padding: "12px 15px 12px 15px",
  fontSize: "18px",
  fontWeight: 900,
  marginTop: "25px",
  letterSpacing: "-1px",
  "&:hover": {
    backgroundColor: "#FFC123",
    color: "#000000",
  },
  "@media(max-width:600px)": {
    width: "100%"
  }
});
const LoginLink = styled(Typography)({
  color: "#CC9200",
  fontSize: "18px",
  fontWeight: 700,
  textDecoration: "none",
  cursor: "pointer",
  "@media(max-width: 400px)": {
    fontSize: "14px",
  },
});
const SocialMediaImg = styled("img")({
  width: "28px",
  padding: "4px",
  backgroundColor: "#A3978F",
  margin: "12px",
  borderRadius: "8px",
  cursor: "pointer",
});
const BackgroundContainer = styled(Box)({
  backgroundImage: `url(${backgroundForgetPassword})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "bottom",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
  "@media(max-width: 600px)": {
    backgroundImage: `url(${backgroundPassImg})`,
  }
});
// Customizable Area End
