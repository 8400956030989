Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";

exports.dashboardLandloard = "Dashboard";
exports.myAccountlandloard = "My Account";
exports.worklandloard = "Work Orders";
exports.preferredlandloard = "Preferred Vendors";
exports.reminderslandloard = "Reminders";
exports.myproplandloard = "My Properties";
exports.financialandloard = "Financial";
exports.reportlandloard = "Delinquency Report";
exports.chatLandloard = "Chat with Keasy";
exports.settingLandlord = "Settings";
exports.dashboardLandlabel = "Dashboard";
exports.myAccountlandlabel = "Settings2";
exports.workOrderslandlabel = "Appointments";
exports.prevendorlandlabel = "TaskList";
exports.reminlandlabel = "AutomatedPrioritization";
exports.myproplandlabel = "PortfolioManagement";
exports.financlandlabel = "Analytics";
exports.reportlandlabel = "VisualAnalytics";
exports.chatlandlabel = "Chat";
exports.docLandlordLabel = "Cfdocumentforwarding4";
exports.docLandlord = "Documents";
exports.settinglandlabel = "Settings2";
exports.serviceRequests = "Service Requests";
exports.lease = "Lease";
exports.paymentHistory = "Payment History";
exports.servicelabel = "ServicerequestDashboard";
exports.leaselabel = "DownloadOptions";
exports.paymentHistorylabel = "BaselineReporting";
exports.tenantDashboard = "TenantDashboard";
exports.chatTenantabel = "Chat";
exports.vendorProfile  = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.vendorProjects = "Tasks";
exports.vendorDashboard = "VendorDashboard";
exports.landlord = "Landlord Dashboard";
exports.tenant = "Tenant Dashboard";
exports.vendor = "Vendor Dashboard";
exports.vendorIconSide = "Preferred Dashboard"
exports.preffredVendors = "preferredVendors"
exports.backButton = "Back";
exports.nextButton = "Next";
exports.finish = "Finish";
exports.vendorInformation = "Vendor information";
exports.companyName = "Company name";
exports.vendorType = "Vendor Type";
exports.ownerName = "Vendor name";
exports.venTrade = "Vendor trade";
exports.contact = "Contact";
exports.areaCode = "Area Code";
exports.phoneNumber = "Phone number";
exports.mobile = "Mobile";
exports.email = "Email";
exports.address = "Address";
exports.portalActivated = "Portal activated";
exports.federalTax = "Federal Tax";
exports.taxpayer = "Taxpayer name";
exports.taxpayerID = "Taxpayer ID";
exports.taxpayerNumber = "Tax form account number";
exports.send1099 = "Send 1099?";
exports.onlinePayable = "Online Payables";
exports.useOnline = "Use online payables?";
exports.paymentType = "Payment type";
exports.routingNumber = "Vendor Bank Routing Number";
exports.accountNumber = "Vendor Bank Account Number";
exports.accountTypes = "Account type";
exports.compliance = "Compliance";
exports.compExpiration = "Workers' comp. Expiration";
exports.generalLiable = "General liability";
exports.epaCertificates = "EPA Certification (optional)";
exports.auto = "Auto insurance expiration";
exports.stateLicense = "State license (optional)";
exports.contract = "Contract expiration";
exports.expirationDate = "Exp. Date";
exports.paperWork = "Upload insurances paper work";
exports.expireDates = "Expiration Date";
exports.insurances = "Upload insurance licensing";
exports.notes = "Notes";
exports.newVendor = "New Vendor";
exports.radioYes = "Yes";
exports.radioNo = "No";
exports.getCountriesCodeAPIEndPoint = "account_block/landlord_accounts/countries_listing";
exports.formW = "Form W-9";
exports.vendorRegax = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
exports.vendornumber = /^\d{10}$/;
exports.vendorPhone = /^[a-zA-Z0-9]{9,34}$/;
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.accountRegax = /^[a-zA-Z0-9]{9,34}$/;
exports.taxRegax = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
exports.taxNumberIdRegax= /^[a-zA-Z0-9]+$/;
exports.addVendorApiEndPoint = "account_block/landlord_accounts/add_preferred_vendor";
exports.postApiMethod = "POST";
exports.getVendorsTypeApiEndPoint = "bx_block_categories/categories";
exports.companyMessage = "Company name must not exceed 50 characters.";
exports.newWorks = "New work order";
exports.locations = "Location";
exports.category = "Category";
exports.workNeeded = "Work Needed";
exports.status = "Status";
exports.nothing = "Nothing to see here yet...";
exports.nothingText = "Once you complete the needed steps you will be able to see all the details about the collection that was made To complete the needed steps please start by adding the property information";
exports.startButton = "Start";
exports.deliquencyReport = "DELINQUENCY REPORT";
exports.worksOrders = "WORK ORDERS";
exports.remindersDetails = "REMINDERS";
exports.preferreds = "PREFERRED VENDORS";
exports.newReminder = "New reminder";
exports.propertiesName = "Property";
exports.paid = "Paid";
exports.latePaid = "0-30";
exports.lateSecond = "late";
exports.sixtyLate = "30+";
exports.vacant = "Vacant";
exports.dueDate = "Due";
exports.assignee = "Assignee";
exports.done = "Completed";
exports.transerToVendor = "Assign to...";
exports.openOrder = "Open reminders";
exports.editWorks = "Edit";
exports.rejected = "Reject";
exports.emailAddress= "E-mail:";
exports.workinHours = "Working hours";
exports.monSaturday = "Mon-Fri, ";
exports.minCost = "Min. cost $";
exports.blankDetails = "-";
exports.chooseVendor = "Choose vendor";
exports.viewAll = "View all";
exports.electricals = "Electricals";
exports.totalPaid = "Total Paid";
exports.getTenantDetailsApiEndPoint = "/bx_block_dashboard/tenants_dashboard/tenant_information";
exports.getPaymentDetailsApiEndPoint = "/bx_block_baselinereporting/tenant_payment_history/payment_details";
exports.downloadLeaseInfoApiEndPoint = "/bx_block_downloadoptions/download_options/tenant_lease";
exports.getPaymentHistoryApiEndPoint = "/bx_block_baselinereporting/tenant_payment_history";
exports.dashboardWorkOrderEndPoint = "bx_block_dashboard/landlord_dashboard/landlord_work_orders?page=";
exports.totalOf = "of";
exports.results = "results";
exports.dashboardVendorEndPoint = "bx_block_dashboard/landlord_dashboard/landlord_preferred_vendors?page_no=";
exports.saturday = "Sat: ";
exports.sunday = "Sun: ";
exports.errorMessage = "error";
exports.popupMessageForError = "Search box must not exceed 30 characters.";
exports.reminderEndPoint = "bx_block_dashboard/landlord_dashboard/landlord_reminders?page=";
exports.searchingError = "Search box must not exceed 30 characters.";
exports.reminderActionsApEndPoint = "bx_block_automaticreminders/reminders/";
exports.reminderPutMethod = "PUT";
exports.dashboardHeading = "Leasing Activity";
exports.vendorPhoneNumber = "Phone";
exports.types = "Type";
exports.deliquencyApiEndPoint = "bx_block_dashboard/landlord_dashboard/landlord_delinquency_report";
exports.addVendors = "Add vendor";
exports.addNewVendors = "Add new vendor";
exports.oldVendorsApiEndPoint = "account_block/vendor_accounts/list_all_vendors";
exports.addExistingButtons = "Add existing";
exports.postApprovedVendorApiEndPoint = "account_block/landlord_accounts/assign_preferred_vendor";
exports.renewalOutlook = "RENEWAL OUTLOOK";
exports.lessThan = "Less than 30";
exports.betweenMore = "Between 30 to 60";
exports.betweenLate = "Between 60 to 90";
exports.sincePlus = "↑ +0,0% Since";
exports.sinceMinus = "↑ -0,0% Since";
exports.renewalApiEndPoint = "bx_block_dashboard/landlord_dashboard/renewal_outlook";
exports.serviceOrder = "Service Requests";
exports.serviceOrderlabel = "LandlordServiceRequestsDashboard";

// Customizable Area End