import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { StyleSheet } from "react-native";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { styled } from "@mui/system";
import { Field, Formik } from "formik";
const LoginStyling = styled(Box)({
    display: "flex",
    fontFamily: "Outfit, sans-serif",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    width: "100%",
    position: "relative",
    flexDirection: "row",
    backgroundImage: `url(${require('../assets/image_background_keasyforms.svg')})`,
    backgroundSize: '922px 1510.14px',
    backgroundPosition: "62% 52%",
    backgroundRepeat: 'no-repeat',
    zIndex: 10,
    "@media (max-width:1100px)": { 
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: 'center',
    },
    "@media (max-width:600px)": {
      width: "100%",
      height: "100%",
      position: "relative",
      flexDirection: "column",
      backgroundImage: `url(${require('../assets/backimage_keasyforms.svg')})`,
      backgroundSize: '100%',
      backgroundPosition: "center top",
      backgroundRepeat: 'no-repeat',
      justifyContent: 'left',
    },
    "& .login_form_wrapper": {
    margin: "2rem 10rem 5rem 5rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "1.5rem 0 1.5rem 0",
    flex: 0.7,
    height: "100%",
    "@media (max-width:1300px)": {
      margin: "3rem 3rem 3rem 3rem",
    },
    "@media (max-width:600px)": {
      width: "327px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      margin: "1rem",
    },
    "@media (max-height:700px)": {
      margin: "0rem 1rem 1rem 1rem",
      padding: "0.5rem 0 0.5rem 0",
    },
  },
  "& .keasy_logo": {
    width: "354px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:600px)": {
      width: "327px",
    },
  },
  "& .keasy_logo_wrapper": {
    width: "210.46px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:600px)": {
      width: "136.6px",
    },
  },
  imageView_image_keasylogo: {},
  "& .image_image_keasylogo": {
    backgroundColor: "##",
    width: "39px",
    height: "71px",
    "@media (max-width:600px)": {
      width: "34px",
      height: "62px",
    },
  },
  "& .imageView_image_keasyfont": {
    top: "12px",
  },
  "& .image_image_keasyfont": {
    width: "154px",
    height: "38px",
    "@media (max-width:600px)": {
      width: "89px",
      height: "27px",
    },
  },
  "& .login_heading": {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: "2rem",
    "@media (max-height:800px)": {
      marginBottom: "0.7rem",
    }
  },
  "& .errorMessage": {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#DC2626',
    padding: "12px 16px 12px 16px",
    backgroundColor: "#FEE2E2",
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    marginBottom: "20px",
  },
  "& .login_form": {
    padding: "40px",
    "@media (max-height:800px)": {
      padding: "0px",
    }
  },
  "& .input_box_wrapper": {
    marginBottom: '16px',
  },
  "& .forgetPassword_Wrapper": {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: "#CC9200",
    margin: "20px 0 35px 0",
    "@media (max-height:900px)": {
      margin: "15px 0 25px 0",
    },
    "@media (max-height:800px)": {
      margin: "15px 0 15px 0",
    }
  },
  "& .forgetPassword_text": {
    cursor: "pointer",
  },
  "& .login_btn": {
    height: "56px",
    width: "354px",
    borderRadius: '8px',
    backgroundColor: "#FFC123",
    color: "#000000",
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '17px',
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
    "@media (max-width:600px)": {
      height: "56px",
      width: "326.1px",
    }
  },
  "& .button_text_wrapper": {
    display: "flex",
    justifyContent: "center",
  },
  "& .bottom_text": {},
  "& .bottom_link_text": {
    color: "#CC9200",
    cursor: "pointer",
  },
  "& .carousel_wrapper": {
    height: '100vh',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1.3,
    zIndex: 20,
    "@media (max-width:1100px)": {
      display: "none",
    }
  },
  "& .mobile-social-media": {
    position: 'absolute',
    bottom: "4rem",
    "@media (max-width:600px)": {
      position: 'absolute',
      bottom: "1rem",
    },
    "@media (max-height:650px)": {
      position: 'absolute',
      bottom: "0px",
    }
  },
  "& .social-media-icon": {
    width: "28px",
    padding: "4px",
    backgroundColor: "#A3978F", 
    margin: "12px",
    borderRadius: '8px',
    cursor: "pointer",
  },
  "& .checkbox": {
    height: "18px",
    width: "18px",
  },
});
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "CarouselDisplay",
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks      
      <LoginStyling>
        <Box className="login_form_wrapper">
          <Box className="keasy_logo">
            <Box className="keasy_logo_wrapper">
              <Box
                className="imageView_image_keasylogo"
              >
                <img
                  className="image_image_keasylogo"
                  src={require("../assets/1319215079KmTObgfmr1gx8VdhoxvtGF.svg")}
                  alt=""
                />
              </Box>

              <Box
                className="imageView_image_keasyfont"
              >
                <img
                  className="image_image_keasyfont"
                  src={require("../assets/1319215080KmTObgfmr1gx8VdhoxvtGF.svg")}
                  alt=""
                />
              </Box>
            </Box>
          </Box>
          <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={{ email: this.state.email, password: this.state.password, rememberMe: this.state.checkedRememberMe }}
              onSubmit={async () => {
                  await this.handleDoEmailLogIn();
              }}
              data-test-id="Formik"
            >
              {({ errors, values, handleSubmit, setFieldValue }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box className="login_form">
                      <Typography className="login_heading">Log in</Typography>
                      {this.state.showErrorMessage && (
                      <Box className="errorMessage">
                        <Typography fontSize={"14px"} lineHeight={"22px"}>No account registered with this email</Typography>
                      </Box>
                      )}
                      {this.state.showPasswordErrorMessage && (
                      <Box className="errorMessage">
                        <Typography fontSize={"14px"} lineHeight={"22px"}>Please enter valid password</Typography>
                      </Box>
                      )}
                      <Box className="input_box_wrapper">
                        <CustomInputField
                          className="input-box"
                          testID="txtInputEmail"
                          name="email"
                          label="Email"
                          type="email"
                          value={values.email}
                          placeholder="Your Email"
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                            this.setEmail(e.target.value);
                          }}
                          isHideMsgError={this.state.hideEmailError}
                          validationMsg={this.getErrorMessage("email", values.email)}
                        ></CustomInputField>
                      </Box>
                      <Box className="input_box_wrapper">
                        <CustomInputField
                          className="input-box"
                          testID="txtInputPassword"
                          name="password"
                          label="Password"
                          type={this.state.enablePasswordField ? "password" : "text"}
                          placeholder="Your Password"
                          value={values.password}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                            this.setPassword(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="testTogglePassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {this.state.enablePasswordField ? (
                                    <img src={require("../assets/I135822546619447531944615KmTObgfmr1gx8VdhoxvtGF.svg")} width={"24px"}/>
                                    ) : (
                                    <img src={require("../assets/ic_password_visible.png")} width={"24px"}/>)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          isHideMsgError={this.state.hidePasswordError}
                          validationMsg={this.getErrorMessage("password", values.password)}
                        ></CustomInputField>
                      </Box>
                      <Box className="forgetPassword_Wrapper">
                        <Box>
                        <Field
                          name="rememberMe"
                          type="checkbox"
                          checked={values.rememberMe}
                          onChange={(e: any) => {
                            const isChecked = e.target.checked;
                            setFieldValue("rememberMe", isChecked);
                            this.setRememberMe(isChecked);
                          }}
                          className="checkbox"
                          data-test-id="testRememberMe"
                        />
                          <span style={{color: "#000000", fontWeight: 400, fontSize: "1rem"}}>Remember me</span>
                        </Box>
                        <Typography className="forgetPassword_text" data-test-id="btnForgotPassword" onClick={this.handleClickForgotPassword}>
                          Forgot password?
                        </Typography>
                      </Box>
                      <Button className="login_btn" style={{ textTransform: 'none' }} data-test-id="btnEmailLogIn" type="submit">Log in</Button>
                    </Box>
                  </form>
                  );
            }}
          </Formik>
          <Box className="button_text_wrapper">
            <Typography className="bottom_text">
              Don’t have an account?
              <span className="bottom_link_text" onClick={this.handleClickSignUp} data-test-id="testSignupbtn"> Sign Up</span>
            </Typography>
          </Box>
          <Box className="mobile-social-media">
          <img
              className="social-media-icon"
              src={require("../assets/button_facebook.svg")}
            />
            <img
              className="social-media-icon"
              src={require("../assets/button_instagram.svg")}
            />
              <img
              className="social-media-icon"
              src={require("../assets/button_twitter.svg")}
            />
          </Box>
        </Box>
        
        <Box className="carousel_wrapper">
          <CarouselDisplay {...this.screenProps} />
        </Box>
      </LoginStyling>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
