import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
import { AlertColor } from "@mui/material";
import { ArrowProps } from 'react-multi-carousel/lib/types';
import { Message } from "../../../framework/src/Message";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getStorageData } from "../../../../packages/framework/src/Utilities";

export interface CustomLeftArrowProps extends ArrowProps {
    onClick?: () => void;
}

interface TenantData {
    property: string;
    unit: string;
    tenantName: string;
    active: boolean;
    leaseStartDate: string;
    leaseEndDate: string;
    address: string;
    landlordName: string;
    landlordAddress: string;
    landlordPhone: string[];
    tenant: string;
    tenantEmail: string;
    tenantAreaCode: string;
    tenantPhone: string;
    noOfOccupants: number;
    firstPaymentDue: string;
    payPeriod: string;
    rent: string;
    deposit: string;
    method: string;
    collectedBy: string;
}

interface PaymentData {
    status: number;
    last_payment: string;
    last_transaction_date: string | null;
    upcoming_payment_date: string;
    pending_amount: string;
}
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeTenantItem: string;
    openTenantDrawer: boolean;
    popUpOpen: boolean;
    popUpType: AlertColor;
    popUpMessage: string;
    tenantDetails: TenantData;
    paymentHistory: any[];
    paymentData: PaymentData;
    currentPage: number;
    rowsPerPage: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}
export default class TenantDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTenantDetailsApiCallId: string = "";
    getPaymentDetailsApiCallId: string = "";
    downloadLeaseInfoApiCallId: string = "";
    getPaymentHistoryApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            activeTenantItem: "TenantDashboard",
            openTenantDrawer: false,
            popUpOpen: false,
            popUpType: "error",
            popUpMessage: "",
            tenantDetails: {
                property: "",
                unit: "",
                tenantName: "",
                active: false,
                leaseStartDate: "",
                leaseEndDate: "",
                address: "",
                landlordName: "",
                landlordAddress: "",
                landlordPhone: [],
                tenant: "",
                tenantEmail: "",
                tenantAreaCode: "",
                tenantPhone: "",
                noOfOccupants: 0,
                firstPaymentDue: "",
                payPeriod: "",
                rent: "",
                deposit: "",
                method: "",
                collectedBy: ""
            },
            paymentHistory: [],
            paymentData: {
                status: 0,
                last_payment: " --",
                last_transaction_date: null,
                upcoming_payment_date: "",
                pending_amount: " --"
            },
            currentPage: 1,
            rowsPerPage: 12,
            // Customizable Area End
        };
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getTenantDetails();
        this.getPaymentDetails();
        this.getPaymentHistory();
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          this.handleGetTenantDetailsResponse(message);
          this.handleGetPaymentDetailsResponse(message);
          this.handleDownloadLeaseInfoResponse(message);
          this.handleGetPaymentHistoryResponse(message);
        }
    };

    navigateToChat = () => {
      this.props.navigation.navigate("Chat");
    };

    handleTenantitemClick = (tenantKey: string) => {
        this.setState({ activeTenantItem: tenantKey }, () => {
            this.handleWorkOrderMenu();
          });
    };

    handleTenantDrawer = () => {
        this.setState({
            openTenantDrawer: !this.state.openTenantDrawer
        });
    };

    handleWorkOrderMenu = () => {
        const { activeTenantItem } = this.state;
        const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
        toMsgWork.addData(
          getName(MessageEnum.NavigationTargetMessage),
          activeTenantItem
        );
        toMsgWork.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(toMsgWork);
    };

    handlePopupMessageClose = () => {
        this.setState({
          popUpOpen: false,
        });
    };

    handleSortRequest = (property: string, order: string) => {
        const paymentHistoryCopy = [...this.state.paymentHistory];
        paymentHistoryCopy.sort((a, b) => {
          if (a[property] < b[property]) {
            return order === "asc" ? -1 : 1;
          }
          if (a[property] > b[property]) {
            return order === "asc" ? 1 : -1;
          }
          return 0;
        });
        this.setState({ paymentHistory: paymentHistoryCopy});
      };

    handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        this.setState({ currentPage: newPage });
    };

    formatDate = (dateString: string) => {
        if(dateString){
          const dateObject = new Date(dateString);
    
          const day = String(dateObject.getUTCDate()).padStart(2, '0');
          const month = String(dateObject.toLocaleString('default', { month: 'short' }));
          const year = dateObject.getUTCFullYear();
    
          return `${day} ${month} ${year}`;
        } else {
          return "No date selected";
        }
    };

    handleNewTransaction=()=>{
      const toMsgOpenWork = new Message(getName(MessageEnum.NavigationMessage));
      toMsgOpenWork.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "NewTransaction"
      );
      toMsgOpenWork.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(toMsgOpenWork);
    };

    getPaymentDetails = async() => {
        const token = await getStorageData("authToken");

        const header = {
          token: token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getPaymentDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getPaymentDetailsApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

      handleGetPaymentDetailsResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (apiRequestCallId === this.getPaymentDetailsApiCallId && responseJson?.status) {
            this.setState({ paymentData: responseJson });
        } else if(responseJson?.errors) {
            this.setState({
              popUpOpen: true,
              popUpType: "error",
              popUpMessage: responseJson?.errors[0].token ? "Session Expired, Please Log in again." : responseJson?.errors
            });
        }
      };

      getTenantDetails = async() => {
        const token = await getStorageData("authToken");

        const header = {
          token: token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getTenantDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getTenantDetailsApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

      handleGetTenantDetailsResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (apiRequestCallId === this.getTenantDetailsApiCallId && responseJson?.data?.id) {
            let data = responseJson.data.attributes;
            let details: TenantData = {
                property: data.property.property_name,
                unit: data.unit.name,
                tenantName: data.name,
                active: data.status === 'Active',
                leaseStartDate: this.formatDate(data.lease_detail.lease_start_date),
                leaseEndDate: this.formatDate(data.lease_detail.lease_end_date),
                address: data.address,
                landlordName: data.landlord_information.owner_name,
                landlordAddress: data.landlord_information.owner_address,
                landlordPhone: data.landlord_information.primary_contact_number,
                tenant: data.name,
                tenantEmail: data.email_address,
                tenantAreaCode: data.area_code,
                tenantPhone: data.phone_number,
                noOfOccupants: data.number_of_occupants,
                firstPaymentDue: this.formatDate(data.date_of_first_payment_due),
                payPeriod: data.pay_period,
                rent: data.rent_amount_per_month,
                deposit: data.security_deposit,
                method: data.payment_method,
                collectedBy: data.collected_by
            }
            this.setState({ tenantDetails: details });
        } else if(responseJson?.errors) {
            this.setState({
              popUpOpen: true,
              popUpType: "error",
              popUpMessage: responseJson?.errors[0].token ? "Session Expired, Please Log in again." : responseJson?.errors
            });
        }
      };

      downloadLeaseInfo = async() => {
        const token = await getStorageData("authToken");

        const header = {
          token: token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.downloadLeaseInfoApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.downloadLeaseInfoApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

      handleDownloadLeaseInfoResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (apiRequestCallId === this.downloadLeaseInfoApiCallId && responseJson?.data?.id) {
            let data = responseJson.data.attributes;
            const doc = new jsPDF();

            // Adding title
            doc.setFontSize(18);
            doc.text('Lease Information', 14, 22);

            // Lease details table
            doc.setFontSize(12);
            doc.text("Lease overview", 40, 35);
            doc.text("Property: "+data.property.name+" | "+data.property.unit, 20, 45);
            doc.text("Lease start date: "+data.lease_detail.lease_start_date, 20, 50);
            doc.text("Lease end data: "+data.lease_detail.lease_end_date, 20, 55);
            doc.text("Address: "+data.lease_detail.address, 20, 60);

            // Landlord information
            doc.text("Landlord information", 40, 75);
            doc.text("Name: "+data.landlord_information.name, 20, 85);
            doc.text("Address: "+data.landlord_information.address, 20, 90);
            doc.text("Phone: "+data.landlord_information.phone.join(', '), 20, 95);

            // Tenant information
            doc.text("Tenant information", 40, 110);
            doc.text("Name: "+data.tenant_information.name, 20, 120);
            doc.text("Phone: "+data.tenant_information.phone, 20, 125);
            doc.text("Email: "+data.tenant_information.email, 20, 130);
            doc.text("No of occupants: "+data.tenant_information.number_of_occupants, 20, 135);

            // Rental information
            doc.text("Rental information", 40, 150);
            doc.text("Date of first payment due: "+data.rental_information.date_of_first_payment_due, 20, 160);
            doc.text("Pay period: "+data.rental_information.pay_period, 20, 165);
            doc.text("Rent amount per month: "+data.rental_information.rent_amount_per_month, 20, 170);
            doc.text("Security deposit: "+data.rental_information.security_deposit, 20, 175);
            doc.text("Payment method: "+data.rental_information.payment_method, 20, 180);
            doc.text("Collected by: "+data.rental_information.collected_by, 20, 185);
            
            // Save the PDF
            doc.save('Lease_Info.pdf');
        } else if(responseJson?.errors) {
            this.setState({
              popUpOpen: true,
              popUpType: "error",
              popUpMessage: responseJson?.errors[0].token ? "Session Expired, Please Log in again." : responseJson?.errors
            });
        }
      };

      getPaymentHistory = async() => {
        const token = await getStorageData("authToken");

        const header = {
          token: token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getPaymentHistoryApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getPaymentHistoryApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

      handleGetPaymentHistoryResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (apiRequestCallId === this.getPaymentHistoryApiCallId && responseJson?.data?.length) {
            this.setState({ paymentHistory: responseJson?.data });
        } else if(responseJson?.errors) {
            this.setState({
              popUpOpen: true,
              popUpType: "error",
              popUpMessage: responseJson?.errors[0].token ? "Session Expired, Please Log in again." : responseJson?.errors
            });
        }
      };

    // Customizable Area End
}
