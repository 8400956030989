import React from "react";

// Customizable Area Start
import {
  Grid,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
  Box,
  styled,
  InputBase,
  TextField,
  Checkbox,
  Chip,
  Pagination,
  PaginationItem,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  Modal,
  FormGroup,
  FormLabel,
  InputAdornment,
  Alert,
  ClickAwayListener,
} from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeProfile, keasyIcon, profileMain, projects, activeProjects, bottomImageIcon } from "./assets";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { 
  ArrowDropDown,
  ArrowDropUp,
  Send,
  Today,
  KeyboardArrowUp,
  KeyboardArrowDown, 
  Close,
  MoreVert,
  FilterList,
} from "@mui/icons-material";
import {
  createTheme,
} from "@mui/material/styles";
import AuditTrailDetail from "../../audittrail/src/AuditTrailDetail.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import FileUpload from "../../accountgroups/src/FileUpload";
import { WorkOrder } from "./TasksController";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const approved = require("../../invoicebilling/assets/approved.png");
export const keyMainChain = require("../../invoicebilling/assets/key.png");
export const activeKeyMain = require("../../invoicebilling/assets/activeKey.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
// Customizable Area End

import TasksController, { Props, configJSON } from "./TasksController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Tasks extends TasksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: keyof WorkOrder, label: string) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent="center">
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              id={`ascendingIcon-${property}`}
              onClick={() => this.handleSortRequest(property, "asc")}
            >
              <ArrowDropUp />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              id={`descendingIcon-${property}`}
              onClick={() => this.handleSortRequest(property, "desc")}
            >
              <ArrowDropDown />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };

  renderStatusChip = (status: string) => {
    if (status === "WORK COMPLETED") {
      return (
        <Chip
          label="WORK COMPLETED"
          sx={{
            backgroundColor: "#f0f0f0", // Light teal background
            color: "#787878", // Darker teal text
            borderRadius: "20px",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
          }}
        />
      );
    } else if (status === "AWAITING PAYMENT") {
      return (
        <Chip
          label="AWAITING PAYMENT"
          sx={{
            backgroundColor: "#FDD2D2", // Light teal background
            color: "#FF2020", // Darker teal text
            borderRadius: "20px",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
          }}
        />
      );
    } else if (status === "PAID") {
      return (
        <Chip
          label="PAID"
          sx={{
            backgroundColor: "#ACF4C4", // Light teal background
            color: "#24A64E", // Darker teal text
            borderRadius: "20px",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
          }}
        />
      );
    } else if (status === "WORK IN PROGRESS") {
      return (
        <Chip
          label="WORK IN PROGRESS"
          sx={{
            backgroundColor: "#FCFDAD", // Light orange background
            color: "#D2C608", // Darker orange text
            borderRadius: "20px",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
          }}
        />
      );
    }
  };
  screenProps = {
    navigation: this.props.navigation,
    id: "Tasks",
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage, rowsPerPage } = this.state;

    const paginatedRows = this.state.workOrders.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );
    return (
      <Grid container direction="row" wrap="nowrap" 
                sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
            >
              <PopupMessage
                open={this.state.messageOpen}
                type={this.state.messageType}
                message={this.state.message}
                handleClose={this.handlePopupMessageClose}
              />
              <Grid item sx={webStyles.TasksNavBarItem}>
                <NavigationSidebar
                    data-test-id={"vendorId"}
                    activeItem={this.state.activeVendorItem}
                    sidebarItems={[
                      { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                      { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                      { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                      { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                      { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
                    ]}
                    onClickSidebar={this.onVendoritemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openVendorDrawer}
                    onClickDrawer={this.onVendorDrawer}
                />
                 <Box className="MobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
                 <CustomNavbar navigation={this.props.navigation} id="Navbar" showChatButton={true}   showPropertiesButton={false} /></Box>
              </Grid>
              <Grid item sx={webStyles.tasksMainContent}>
              <Container maxWidth={false} sx={webStyles.tasksMainContainer}
                >
                  <Box className="Navbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>
                  <CustomNavbar 
                    {...this.screenProps}
                    showChatButton={true}   
                    showPropertiesButton={false} 
                  />
                  </Box><Box className="tasksContainer" sx={{paddingRight: "30px", "@media (max-width: 992px)": {paddingRight: 0,},}}>
                    <Typography sx={webStyles.vendor_profile_header} paragraph>
                        Projects
                    </Typography>

                      <Box
                        sx={webStyles.searchRow}
                      >
                        <SearchIcon sx={{ color: "#BAB1AB" }} />
                        <InputBase
                          name="searchInput"
                          id="searchInput"
                          data-test-id="search_block"
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          size="small"
                          sx={{
                            width: "100%",
                            marginLeft: "5px",
                            marginTop: "3px",
                          }}
                          value={this.state.searchInput}
                          onChange={this.handleInputChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      </Box>
                      <Box mt={2} sx={webStyles.filterRow}>
                        <Box sx={webStyles.filterChips}>
                          <FilterList />
                          <Typography sx={webStyles.filtertext}>Filters</Typography>
                          <span style={{marginLeft: "20px"}}>
                            {this.state.searchArray.map((search, index) => (
                              <Chip
                                key={index}
                                label={search}
                                variant="outlined"
                                icon={
                                  <IconButton
                                    aria-label="delete"
                                    data-test-id="deleteIconButton"
                                    size="small"
                                    onClick={() => this.handleDelete(search)}
                                    sx={{ mr: -1 }}
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                }
                                sx={{ mr: 1, border: 2, borderRadius: 1 }}
                              />
                            ))}
                          </span>
                        </Box>
                        <Box>
                          <Button
                            id="clearAll"
                            sx={webStyles.clearAllText}
                            onClick={this.handleClearAll}
                          >
                            Clear all
                          </Button>
                        </Box>
                      </Box>

                      <Box mt={4} mb={4}>
                      <ClickAwayListener onClickAway={() => this.setState({ startDateOpen: false,endDateOpen:false })}  mouseEvent="onMouseDown">
                          <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
                              <Grid container spacing={2} sx={{ '@media (min-width: 992px)': {width: '100%'}, alignItems: 'center' }}>
                                <Grid item xs={6} sm={6} md={2}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box >
                                      <DesktopDatePicker
                                          data-test-id="desktopDatePicker"
                                          value={this.state.startDate}
                                          onChange={this.handleDateChange('startDate')}
                                          onClose={() => this.setState({ startDateOpen: false })}
                                          open={this.state.startDateOpen} 
                                          renderInput={(params) =>
                                            <TextField
                                            data-test-id="startDateDatePicker"
                                            {...params}
                                            label="Start date"
                                            onClick={()=>{this.handleOpen("startDate")}}
                                            sx={{
                                              '& .MuiSvgIcon-root': {
                                                color: '#BAB1AB',
                                              },
                                              '& .MuiInputBase-root': {
                                                paddingRight: '8px',
                                              },
                                            }}
                                            InputLabelProps={{
                                              style: {
                                                lineHeight: '19px', 
                                                fontSize: '16px',
                                                color: '#A3978F',
                                                fontWeight: 400, 
                                                fontFamily: 'Outfit',
                                              },
                                            }}
                                            
                                            InputProps={{
                                              ...params.InputProps,
                                              style: {
                                                fontSize: '16px',
                                                padding: '1.5rem 1rem',  
                                                fontWeight: 400, 
                                                fontFamily: 'Outfit, sans-serif', 
                                                borderRadius: '8px',
                                                lineHeight: '18px', 
                                                width:"100%",
                                                height: '44px',
                                              },
                                            }}
                                          />
                                            }
                                          label={"start date"}
                                        />
                                      </Box>
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box >
                                      <DesktopDatePicker
                                          data-test-id="endDesktopDatePicker"
                                          value={this.state.endDate}
                                          onChange={this.handleDateChange('endDate')}
                                          onClose={() => this.setState({ endDateOpen: false })}
                                          open={this.state.endDateOpen}
                                          renderInput={(params) =>
                                            <TextField
                                            data-test-id="endDesktopDatePickerField"
                                            {...params}
                                            label="End date"
                                            onClick={()=>{this.handleOpen("endDate")}}
                                            InputProps={{
                                              ...params.InputProps,
                                              style: {
                                                width: '100%', 
                                                borderRadius: '8px',
                                                padding: '1.5rem 1rem', 
                                                fontFamily: 'Outfit, sans-serif', 
                                                fontSize: '16px', 
                                                fontWeight: 400, 
                                                lineHeight: '18px', 
                                                height: '44px',
                                              },
                                            }}
                                            InputLabelProps={{
                                              style: {
                                                fontFamily: 'Outfit', 
                                                fontSize: '16px',
                                                fontWeight: 400, 
                                                lineHeight: '19px', 
                                                color: '#A3978F',
                                              },
                                            }}
                                            sx={{
                                              '& .MuiInputBase-root': {
                                                paddingRight: '8px',
                                              },
                                              '& .MuiSvgIcon-root': {
                                                color: '#BAB1AB',
                                              },
                                            }}
                                          />
                                            }
                                        />
                                    </Box>
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4} sm={4} md="auto">
                                  <Button
                                    data-test-id="apply-btn"
                                    variant="contained"
                                    sx={webStyles.apply_button} 
                                    onClick={this.handleFilter}
                                  >
                                    Apply
                                </Button>
                                </Grid>
                                
                              </Grid>
                          </Box>
                        </ClickAwayListener>
                      </Box>
                    
                    <Paper elevation={3} sx={webStyles.contentPaper}>
                      <Box mt={1} mb={2} sx={{display: "flex", flexDirection: "row"}}>
                        <Typography data-test-id="word-output" sx={webStyles.selected_text}>
                          {this.convertNumberToWords(this.state.selectedOrders.length)} ({this.state.selectedOrders.length}) project selected 
                        </Typography>
                        <Button
                          id="sendInvoiceButton"
                          sx={webStyles.text_button}
                          onClick={()=>this.handleSendInvoiceClick(this.state.selectedOrders)}
                        >
                          <Send sx={webStyles.text_button_icon}/>
                          Send invoice
                        </Button>
                        <Button
                          id="scheduleDateButton"
                          sx={webStyles.text_button}
                          onClick={()=>this.handleScheduleDateClick(this.state.selectedOrders)}
                        >
                          <Today sx={webStyles.text_button_icon}/>
                          Scheduled date
                        </Button>
                        <Modal
                          id="send-invoice-modal"
                          open={this.state.sendInvoiceModal}
                          onClose={() => this.handleModalClose('sendInvoice')}
                          aria-labelledby="send-invoice-modal"
                          aria-describedby="send-invoice-modal"
                        >
                          <Box sx={webStyles.modal_style}>
                            <form id="sendInvoiceForm" onSubmit={this.onSendInvoiceSubmit}>
                              <Box sx={webStyles.modal_header}>
                                <Typography sx={webStyles.vendor_profile_header}>
                                  Invoice #{this.state.currentModalWorkOrder.id}
                                </Typography>
                                <IconButton
                                  id="sendInvoiceClose"
                                  onClick={() => this.handleModalClose('sendInvoice')}
                                >
                                  <Close />
                                </IconButton>
                              </Box>
                              <Box id="sendInvoiceContent" sx={webStyles.modal_content}>
                                <FormLabel id="sendInvoiceLabel" htmlFor="phone" sx={webStyles.input_label}>
                                  Project information
                                </FormLabel>
                                <Grid id="sendInvoiceInfoContainer" container sx={{ marginBottom: "28px" }}>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoicePropertyText" sx={webStyles.regularText}>Property</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceProperty" style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.property}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceCategoryText" sx={webStyles.regularText}>
                                      Category
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceCategory" style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>{this.state.currentModalWorkOrder.category}</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceWorkNeededText" sx={webStyles.regularText}>
                                      Work needed
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceWorkNeeded" style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.work_needed}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceWorkRequestDateText" sx={webStyles.regularText}>
                                      Request date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography id="sendInvoiceWorkRequestDate" style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.date}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <FormGroup sx={{ marginBottom: "28px" }}>
                                  <FormLabel htmlFor="invoiceDate" sx={webStyles.input_label}>
                                    Invoice date
                                  </FormLabel>
                                  <CustomDatePicker
                                    value={this.state.invoiceDate}
                                    required={true}
                                    onChange={this.handleDateChange(
                                      "invoiceDate"
                                    )} />
                                </FormGroup>
                                <FormGroup sx={{ marginBottom: "28px" }}>
                                  <FormLabel htmlFor="dueDate" sx={webStyles.input_label}>
                                    Due date
                                  </FormLabel>
                                  <CustomDatePicker
                                    value={this.state.dueDate}
                                    required={true}
                                    onChange={this.handleDateChange(
                                      "dueDate"
                                    )} />
                                </FormGroup>

                                <Grid container spacing={2} sx={{marginBottom: "20px"}}>
                                  <Grid item xs={6}>
                                    <FormLabel htmlFor="price" sx={webStyles.input_label}>
                                      Price
                                    </FormLabel>
                                    <TextField
                                      required
                                      id="price"
                                      name="price"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                      }}
                                      placeholder="0.00"
                                      error={this.state.errors.price}
                                      helperText={this.getHelperText("price")}
                                      value={this.state.price}
                                      onChange={this.handleInputChange}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormLabel htmlFor="taxes" sx={webStyles.input_label}>
                                      Taxes
                                    </FormLabel>
                                    <TextField
                                      required
                                      id="taxes"
                                      name="taxes"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                      }}
                                      placeholder="0.00"
                                      error={this.state.errors.taxes}
                                      helperText={this.getHelperText("taxes")}
                                      value={this.state.taxes}
                                      onChange={this.handleInputChange}
                                    />
                                  </Grid>
                                </Grid>

                                <FileUpload
                                  label="Upload receipts"
                                  name="receipts"
                                  files={this.state.receipts}
                                  onFilesChange={this.handleFilesChange}
                                />
                                {this.validateField("receipts") && (
                                  <Alert severity="error" sx={{ mt: 2 }}>
                                    Please attach a file
                                  </Alert>
                                )}
                              </Box>

                              <Box display={"flex"} justifyContent={"end"} sx={{ borderTop: "1px solid #A9A9A9", padding: "25px" }}>
                                <Button
                                  size="medium"
                                  id="cancelButton"
                                  sx={{...webStyles.form_back_button, mr: 2}}
                                  onClick={() => this.handleModalClose('sendInvoice')}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="medium"
                                  id="sendInvoiceSubmitBtn"
                                  type="submit"
                                  sx={webStyles.form_button}
                                >
                                  Send invoice
                                </Button>
                              </Box>
                            </form>
                          </Box>
                        </Modal>
                        <Modal
                          id="scheduled-date-modal"
                          open={this.state.scheduleDateModal}
                          onClose={() => this.handleModalClose('scheduleDate')}
                          aria-labelledby="scheduled-date-modal"
                          aria-describedby="scheduled-date-modal"
                        >
                          <Box sx={webStyles.modal_style}>
                            <form id="scheduleDateForm" onSubmit={this.onScheduleDateSubmit}>
                              <Box sx={webStyles.modal_header}>
                                <Typography sx={webStyles.vendor_profile_header}>
                                  Project ID #{this.state.currentModalWorkOrder.id}
                                </Typography>
                                <IconButton
                                  id="scheduleDateClose"
                                  onClick={() => this.handleModalClose('scheduleDate')}
                                >
                                  <Close />
                                </IconButton>
                              </Box>
                              <Box sx={webStyles.modal_content}>
                                <FormLabel htmlFor="phone" sx={webStyles.input_label}>
                                  Project information
                                </FormLabel>
                                <Grid container sx={{ marginBottom: "28px" }}>
                                  <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>Property</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.property}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>
                                      Category
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.category}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>
                                      Work needed
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.work_needed}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>
                                      Request date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.date}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography sx={webStyles.regularText}>
                                      Requested by
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography style={{ fontSize: "16px", fontFamily: "Outfit", wordBreak: 'break-word' }}>
                                      {this.state.currentModalWorkOrder.requested_by}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <FormGroup sx={{ marginBottom: "28px" }}>
                                  <FormLabel htmlFor="projectStartDate" sx={webStyles.input_label}>
                                    Project start date
                                  </FormLabel>
                                  <CustomDatePicker
                                    value={this.state.projectStartDate}
                                    required={true}
                                    onChange={this.handleDateChange(
                                      "projectStartDate"
                                    )} />
                                </FormGroup>
                                <FormGroup >
                                  <FormLabel htmlFor="projectCompletionDate" sx={webStyles.input_label}>
                                    Project completion date
                                  </FormLabel>
                                  <CustomDatePicker
                                    value={this.state.projectCompletionDate}
                                    required={true}
                                    onChange={this.handleDateChange(
                                      "projectCompletionDate"
                                    )} />
                                </FormGroup>
                              </Box>

                              <Box display={"flex"} justifyContent={"end"} sx={{ borderTop: "1px solid #A9A9A9", padding: "25px" }}>
                                <Button
                                  size="medium"
                                  id="cancelButton"
                                  sx={{...webStyles.form_back_button, mr: 2}}
                                  onClick={() => this.handleModalClose('scheduleDate')}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="medium"
                                  id="scheduleDateSubmitBtn"
                                  type="submit"
                                  sx={webStyles.form_button}
                                >
                                  Confirm date
                                </Button>
                              </Box>
                            </form>
                          </Box>
                        </Modal>
                      </Box>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox"></TableCell>
                              {this.renderSortableTableCell("id", "ID")}
                              {this.renderSortableTableCell("date", "Date")}
                              {this.renderSortableTableCell("requested_by", "Requested by")}
                              {this.renderSortableTableCell("property", "Property")}
                              {this.renderSortableTableCell("category", "Category")}
                              <CustomTableCell key="workNeeded" align="center">
                                Work needed
                              </CustomTableCell>
                              <CustomTableCell key="description" align="center">
                                Description
                              </CustomTableCell>
                              {this.renderSortableTableCell("cost", "Project amount")}
                              {this.renderSortableTableCell("status", "Current status")}
                              <CustomTableCell></CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedRows.length === 0 ? (
                              <TableRow data-test-id="noDataRow">
                                <TableCell colSpan={10} align="center" style={{ fontSize: "14px", color: "#A3978F" }}>
                                  No work orders found
                                </TableCell>
                              </TableRow>
                            ) : (paginatedRows.map((row, index) => {
                              const isItemSelected = this.isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${row.id}`;

                              return (
                                <>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                  selected={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      id={`checkbox-${row.id}`}
                                      checked={isItemSelected}
                                      onChange={() => this.handleCheckBoxClick(row.id)}
                                      inputProps={{ "aria-labelledby": labelId }}
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#DAA520",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    <Typography 
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} 
                                      align="center"
                                    >#{row.id}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography 
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                      align="center"
                                    >{row.date}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                      align="center" color="textSecondary"
                                    >{row.requested_by}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      align="center"
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                    >{row.property}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                      align="center" color="textSecondary">
                                      {row.category}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                      align="center" color="textSecondary">
                                      {row.work_needed}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                      align="center" color="textSecondary">
                                      {row.description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }}
                                      align="center" color="#24A64E">
                                      ${row.cost}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center" >
                                    {this.renderStatusChip(row.status)}
                                  </TableCell>
                                  <TableCell>
                                    <Grid container>
                                      <Grid item xs={6}>
                                      <IconButton id="openDetailButton" onClick={() => this.handleRowClick(index)}>
                                        {this.state.openDetailRow === index ? (
                                          <KeyboardArrowUp />
                                        ) : (
                                          <KeyboardArrowDown />
                                        )}
                                      </IconButton>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton id="openMenuButton" onClick={(menus) => { this.handleMenuIconClick(menus, row.id) }}>
                                            <MoreVert />
                                          </IconButton>
                                          <Menu
                                            id={`menu-${row.id}`}
                                            anchorEl={this.state.anchorNewElement[row.id]}
                                            keepMounted
                                            open={Boolean(this.state.anchorNewElement[row.id])}
                                            onClose={() => this.handleMenuClose(row.id)}
                                            elevation={0}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right"
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "right"
                                            }}
                                          >
                                            <CustomNewMenuItem
                                             id="sendInvoiceItem"
                                             onClick={()=>{
                                              this.handleSendInvoiceClick([row.id]);
                                              this.handleMenuClose(row.id)
                                            }}>
                                              <ListItemIcon>
                                                <Send />
                                              </ListItemIcon>
                                              <ListItemText
                                                primaryTypographyProps={{
                                                  sx: { fontSize: "12px", fontFamily: "Outfit" }
                                                }}
                                                primary="Send invoice" />
                                            </CustomNewMenuItem>
                                            <CustomNewMenuItem 
                                             id="scheduleDateItem"
                                             onClick={()=>{
                                              this.handleScheduleDateClick([row.id]);
                                              this.handleMenuClose(row.id)
                                            }}>
                                              <ListItemIcon>
                                                <Today />
                                              </ListItemIcon>
                                              <ListItemText
                                                primaryTypographyProps={{
                                                  sx: { fontSize: "12px", fontFamily: "Outfit" }
                                                }}
                                                primary="Scheduled date" />
                                            </CustomNewMenuItem>
                                          </Menu>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                                {this.state.openDetailRow === index && (
                                  <AuditTrailDetail 
                                      navigation={this.props.navigation}
                                      id={""}
                                      seeProjectDetails={true}
                                      headers={['Date', 'Updated by', 'Details', 'Status Change']}
                                      dataProperties={["date", "updated_by", "description", "status"]}
                                      workOrderAudits={row.work_order_audits}
                                      documents={row.documents}
                                      projectDetailsRoute={"Task"} 
                                      orderId={String(row.id)}                                  />
                                )}
                                </>
                              );
                            }))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                        {paginatedRows.length > 0 && <Box sx={webStyles.paginationContainer}>
                          <Box></Box>
                          <Pagination
                              data-test-id="pagination"
                              count={Math.ceil(this.state.workOrders.length / rowsPerPage)}
                              page={currentPage}
                              onChange={this.handleChangePage}
                              renderItem={(item) => (
                              <PaginationItem
                                  {...item}
                                  sx={{
                                  "&.Mui-selected": {
                                      color: "#CC9200",
                                      backgroundColor: "unset",
                                  },
                                  }}
                              />
                              )}
                          />
                          <Typography sx={{ marginRight: "10%" }}>
                            {`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                              currentPage * rowsPerPage,
                              this.state.workOrders.length
                            )} of ${this.state.workOrders.length} results`}
                          </Typography>
                        </Box>
                        }
                    </Paper>
                    </Box><Box className="vendorProjectsBottomImg" data-test-id="vendorProjectsBottomImg" sx={{"@media (max-width:992px)": { display: "none",}}}>
                    <img
                      src={bottomImageIcon}
                      alt="vendorProjectsBottomImg"
                      style={{
                          position: "absolute",
                          bottom: "-15px",
                          right: "0"
                      }}
                    /></Box>
                </Container>
              </Grid>
            </Grid>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
const webStyles = {
  TasksNavBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000,
    "@media(max-width: 992px)": {
      display:"flex", position: "unset",
      height: "auto", paddingTop: "24px",
      overflowY: "hidden",
      border: "none",
    },
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  vendor_profile_header: {
    fontSize: "24px",
    fontFamily: "Outfit",
    fontWeight: 700,
  },
  contentPaper: {
    border: "2px solid #FFD466",
    borderRadius: "8px",
    borderBottomRightRadius: "30px",
    padding: "20px",
    boxShadow: "unset",
  },
  tasksMainContent: {
    flexGrow: 1,
    "@media (min-width: 992px)": {
        borderLeft: "1px solid rgb(208,203,199)",
        marginLeft: "120px"
    },
    paddingBottom: "20px",
    marginLeft: "0px"
  },
  tasksMainContainer: { paddingLeft: '30px !important', paddingRight: "0px !important","@media (max-width: 992px)": {paddingRight: "16px !important",  paddingLeft: '16px !important', },
    position: "relative",
    paddingBottom: "100px",
    minHeight: "100vh",
  },
  container: {
    padding: "20px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  section: {
    "@media (min-width: 600px)": {
      borderRight: "2px solid #BAB1AB",
    },
    minHeight: "100%",
    padding: "10px",
  },
  icon: {
    marginRight: "10px",
    color: "#CC9200",
  },
  boldText: {
    fontWeight: 600,
  },
  regularText: {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#BAB1AB",
    marginRight: "15px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    margin: "20px 0",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
  },
  downloadButton: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
  },
  tableHeader: {
    color: "#BAB1AB",
  },
  infoSection: {
    marginBottom: "10%",
  },
  editButton: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
    paddingTop: 0,
  },
  add_button: {
    backgroundColor: "#FFF1CC",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  apply_button: {
    height: "44px",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    textTransform: "none",
    cursor: "pointer",
    width: '76px',
    "&:hover": {
      backgroundColor: "#FFC123", 
    },
  },
  selected_text: {
    color: "#A19890",
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: "700",
    paddingTop: "8px"
  },
  text_button: {
    color: "#DAA520",
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: "700",
    marginLeft: "10px",
    textTransform: "capitalize",
  },
  text_button_icon: {
    color: "#DAA520",
    height: "18px",
    marginRight: "5px"
  },
  modal_style: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    overflow:'scroll',
    height:'100%',
    display:'block'
  },

  modal_header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #A9A9A9",
    p: 2,
  },
  
  modal_footer: {
    display: "flex",
    borderTop: "1px solid #A9A9A9",
    p: 2,
  },

  modal_content: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  input_label: {
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
  },
  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFF1CC",
    },
  },
  form_back_button: {
    backgroundColor: "#FFF1CC",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  "&.Mui-disabled": {
    backgroundColor: "#FFF1CC",
  },
  filtertext: {
    fontSize: "12px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#000000",
    marginLeft: "10px",
  },
  clearAllText: {
    fontSize: "12px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#CC9200",
    textTransform: "initial"
  },
  filterRow: {
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    height: "32px",
    "@media(max-width: 992px)": { width: "100%",}
  },
  searchRow: {
    border: "1px solid #BAB1AB",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    width: "60%", 
    "@media(max-width: 992px)": { 
      width: "calc(100% - 16px)",}
  },
  filterChips: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
};

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "Outfit",
  padding: "0px 10px 0px 10px !important",
  margin: "auto",
});

const CustomIconButtonUp = styled(IconButton)({
  cursor: "pointer",
  height: "12px",
  width: "12px",
});

const CustomIconButtonDown = styled(IconButton)({
  cursor: "pointer",
  height: "12px",
  width: "12px"
});

const CustomNewMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  "&.changeColor": {
    backgroundColor: "rgb(250,227,162)",
    borderRadius: "10px"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  }
});
// Customizable Area Start
