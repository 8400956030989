import React from 'react';
// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
  styled,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider
} from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, searchIcon, filterIcon, plusIcon, findKeys, waitingBill, scheduledDate, editIcon, workIcon, declineIcon, transferIcon, doneIcon, hexagon, halfHexagon, keys,serviceInactive,Service } from './assets';
import { WorkOrderDetails, PropertiesDetails, Updates, Units, WorkDescriptions, PreferredVendor } from "./AppointmentsController";
import { KeyboardArrowUp, KeyboardArrowDown, MoreVert, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import PopupMessage from "../../../components/src/PopupMessage.web";
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';
import Loader from "../../../components/src/Loader.web";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import {documentActive,documents } from '../../automaticreminders/src/assets';
// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from './AppointmentsController';
import { TimeSlot } from './types';

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };

  renderFilter = () => {
    return (
      <FilterContainer>
        {this.state.chips.map((chip, chipIndex) => (
          <FilterChips key={chipIndex}>
            <FilterClose data-test-id = {`removedChipId,${chipIndex}`} onClick={() => this.removeChip(chipIndex)} />
            <FilterDetails>
              {chip.propertyName} | {chip.unitName}
            </FilterDetails>
          </FilterChips>
        ))
        }
      </FilterContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <WorkOrderStyle>
        <NavArea className="landlordAppointmentsNavArea" sx={{"@media(max-width: 992px)": { display: "flex", "paddingTop": "24px",}}}>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeOrderItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardOrderLogo, activeIcon: activeOrderDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.serviceOrder, labelKey: configJSON.serviceOrderlabel, icon: serviceInactive, activeIcon:Service , pathName: configJSON.serviceOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.docuLandlordLabel, labelKey: configJSON.documentPath, icon: documents, activeIcon: documentActive, pathName: configJSON.documentPath },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
          ]}
          onClickSidebar={this.handleSideNav}
          keasylogo={keasIcon}
          openDrawer={this.state.openWorkOrder}
          onClickDrawer={this.handleWordDrawer}
        />
         <Box className="landlordAppointmentsMobileNavbar" sx={{display: 'none','@media (max-width: 992px)': { display: 'block', width: "calc(100% - 68px)", marginLeft: "12px", paddingLeft: "16px" },}}>
         <CustomNavbar navigation={this.props.navigation} id="landlordAppointmentsNavbar" showChatButton={true}   showPropertiesButton={false} /></Box>
        </NavArea>
        <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
          <MainWorkContainer>
            <WorkStyles>
            <Box className="landlordAppointmentsNavbar" sx={{display: 'block', '@media (max-width: 992px)': { display: 'none' },}}>
            <CustomNavbar
                {...this.props}
                showChatButton={true}
                showPropertiesButton={true}
              />
              </Box><WorkOrderHead data-test-id="seeTextId">
                {configJSON.workOrder}
              </WorkOrderHead>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Box className="search-contents">
                    <img
                      className="search-icons"
                      src={searchIcon} alt="location" />
                    <InputBase
                      data-test-id = "searchId"
                      className="search-inputs"
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.searchBar}
                      onChange={(searchEvent) => this.handleSearchBar(searchEvent)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} justifyContent="flex-start">
                  <Box className="search-main-content">
                    <Button data-test-id="propertyClickId" className="properties-dropdown" onClick={this.handleProperClick}>
                      <Typography data-test-id="propertyNameId" className="text-data">{configJSON.propertiesHead}</Typography>
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Box data-test-id="navigationId" className="new-property" onClick={() => this.navigateToAddAppointment()}>
                      <img
                        className="icon"
                        src={plusIcon} alt="plus" />
                      <Typography className="new-data">{configJSON.newWorkOrders}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box className="filter-content">
                    <Box className="filter">
                      <img
                        className="icon"
                        src={filterIcon} alt="filter" />
                      <Typography className="filter-data" data-test-id="headClickId">{configJSON.filtersHead}</Typography>
                    </Box>
                    <FilterWeb>
                      {this.renderFilter()}
                    </FilterWeb>
                    <Typography className="clear-data" data-test-id="clearOrderId" onClick={this.clearChips}>{configJSON.clearFilters}</Typography>
                  </Box>
                </Grid>
                <FilterMobile>
                  {this.renderFilter()}
                </FilterMobile>
              </Grid>
              <>
              <Box className={this.handleProperty(this.state.properties)} 
              sx={{
                paddingRight: "30px",
                "@media (max-width: 992px)": {
                  paddingRight: 0,
                },
              }}
              >
                {this.state.properties.map((propertyItems: PropertiesDetails, proIndex: number) => (
                  <React.Fragment key={proIndex}>
                    <Grid container spacing={2} mt={2}>
                      <GridItem item xl={6} lg={6} md={6} sm={12} display={"flex"} alignItems={"center"}>
                        <HeadItems>
                          <HeadName>{propertyItems.attributes.property_name}</HeadName>
                          <MainHeadings>{propertyItems.attributes.address}</MainHeadings>
                        </HeadItems>
                      </GridItem>
                      <GridItem item xl={6} lg={6} md={6} sm={12}>
                        <CompletedDetails>
                          <Box data-test-id="view-completed" className="view-completed" onClick={() => this.getPropertiesDetails(propertyItems.id, "view_completed")}>
                            <VisibilityOutlinedIcon />
                            <Typography className="text-data">{configJSON.viewCompleted}</Typography>
                          </Box>
                        </CompletedDetails>
                      </GridItem>
                    </Grid>
                    {propertyItems.attributes.units.map((unitDetails: Units, unitIndex: number) => (
                      <BorderContainer key={unitIndex}>
                        <OrderUnitContainer>
                          <OrderUnitName>{unitDetails.unit_name}</OrderUnitName>
                          <OrdersProgress sx={this.priorityStyleHandler(unitDetails.status)}>{unitDetails.status}</OrdersProgress>
                        </OrderUnitContainer>
                        <Grid container mt={2} spacing={2}>
                          <CustomApproveGrid item xl={6} lg={6} md={8} sm={12} xs={12}>
                            {this.state.selected[unitDetails.id]?.length > 0 ? (
                              <SelectedCounts>
                                <SelectedOrder>{this.numberToWords(this.state.selected[unitDetails.id].length)} ({this.state.selected[unitDetails.id]?.length})</SelectedOrder>
                                <SelectedOrder>&nbsp;{configJSON.selectedOrderName}</SelectedOrder>
                              </SelectedCounts>
                            ) : (
                              <SelectedCounts>
                                <SelectedOrder>{configJSON.selectedCount}</SelectedOrder>
                                <SelectedOrder>&nbsp;{configJSON.selectedOrderName}</SelectedOrder>
                              </SelectedCounts>
                            )}
                            <ApproveDetails data-test-id="approveId" onClick={(appEvent) => this.handleApproveClick(appEvent, unitDetails.id)}>
                              <ApproveName>{this.state.selectedApprove[unitDetails.id] || "Assign to Vendor"}</ApproveName>
                              <KeyboardArrowDownIcon />
                            </ApproveDetails>
                            <Menu
                              data-test-id="closeMenuId"
                              anchorEl={this.state.anchorElement[unitDetails.id]}
                              keepMounted
                              open={Boolean(this.state.anchorElement[unitDetails.id])}
                              onClose={() => this.handleApproveMenu(unitDetails.id)}
                              elevation={0}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}
                              slotProps={{
                                paper: {
                                  style: { marginLeft: "-8px" }
                                }
                              }}
                            >
                              {this.state.approveDetails.map((items: string, itemIndex: number) => (
                                <CustomNewMenuItem
                                  data-test-id="approveMenuId"
                                  key={`menu-${unitDetails.id}-${itemIndex}`}
                                  onClick={() => this.handleMenuClick(items, unitDetails.id)}
                                  className={this.handleChangeColor(itemIndex)}
                                >{items}
                                </CustomNewMenuItem>
                              ))}
                            </Menu>
                            <ScheduledNewWaiting data-test-id = "waitingBillId" onClick={() => this.handleWaitingBill(unitDetails.id)}>
                              <img
                                className="image-icon"
                                src={waitingBill} alt="bill" />
                              <ScheduledDate data-test-id="waitingId">{configJSON.waitingBill}</ScheduledDate>
                            </ScheduledNewWaiting>

                          </CustomApproveGrid>
                          <CustomGrid item xl={6} lg={6} md={4} sm={12} xs={12}>
                            <ScheduledFindWaiting onClick={() => this.handleWaitingBill(unitDetails.id)}>
                              <img
                                className="image-icon"
                                src={waitingBill} alt="bill" />
                              <ScheduledDate>{configJSON.waitingBill}</ScheduledDate>
                            </ScheduledFindWaiting>
                            <Box data-test-id = "findKeysId" display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1} onClick={() => this.handleModalOpen(unitDetails.id)}>
                              <img src={findKeys} alt="findKey" className="image-icon" />
                              <FindKeyName>{configJSON.findKeys}</FindKeyName>
                            </Box>
                          </CustomGrid>
                        </Grid>
                        <TableContainer sx={{ maxHeight: 300, zIndex: 1 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell padding="checkbox">
                                  <CustomCheckbox
                                    data-test-id="selectAllId"
                                    indeterminate={
                                      (this.state.selected[unitDetails.id]?.length || 0) > 0 &&
                                      this.state.selected[unitDetails.id]?.length < unitDetails.landlord_work_orders.length
                                    }
                                    checked={
                                      unitDetails.landlord_work_orders.length > 0 &&
                                      (this.state.selected[unitDetails.id]?.length || 0) === unitDetails.landlord_work_orders.length
                                    }
                                    onChange={(eventDetails) => this.handleSelectAllClick(eventDetails, unitDetails.id)}
                                  />
                                </TableCell>
                                {this.renderSortableTableCell("id", configJSON.idName, unitIndex)}
                                <CustomTableCell>{configJSON.createdName}</CustomTableCell>
                                {this.renderSortableTableCell("category", configJSON.categoryName, unitIndex)}
                                <CustomTableCell>{configJSON.workNeedName}</CustomTableCell>
                                {this.renderSortableTableCell("assigned_vendor", configJSON.assignVendorName, unitIndex)}
                                {this.renderSortableTableCell("cost", configJSON.estimatedCostName, unitIndex)}
                                {this.renderSortableTableCell("status", configJSON.statusName, unitIndex)}
                                <CustomTableCell>{configJSON.descriptionName}</CustomTableCell>
                                {this.renderSortableTableCell("due_date_date", configJSON.openedName, unitIndex)}
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.sortData(unitDetails.landlord_work_orders, unitIndex).map((rowOrder: WorkOrderDetails, workIndex: number) => {
                                const isItemSelected = this.isSelected(unitDetails.id,rowOrder.id);
                                return (
                                  <React.Fragment key={`workOrder-${rowOrder.id}`}>
                                    <TableRow className={this.handleDetailsContainer(unitDetails.landlord_work_orders)}>
                                      <TableCell padding="checkbox">
                                        <CustomCheckbox
                                          data-test-id="checkedClickTestId"
                                          checked={isItemSelected}
                                          onChange={() => this.handleSelectClick(unitDetails.id, rowOrder.id)}
                                        />
                                      </TableCell>
                                      <CustomDetailTableCellId data-test-id="rowId">{configJSON.hashTagName}{rowOrder.id}</CustomDetailTableCellId>
                                      <CustomDetailTableCell>{rowOrder.created_by}</CustomDetailTableCell>
                                      <CustomDetailTableCell data-test-id="createTestId">{rowOrder.sub_category}</CustomDetailTableCell>
                                      <CustomDetailTextCell>{rowOrder.work_needed}</CustomDetailTextCell>
                                      <CustomDetailTableCell>{rowOrder.assigned_vendor}</CustomDetailTableCell>
                                      <CustomTableCell>{configJSON.estimatedCost}</CustomTableCell>
                                      <TableCell>
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                        <OrderStatus data-test-id="statusId" sx={this.priorityStyleHandler(rowOrder.status)}>
                                          {rowOrder.status}
                                        </OrderStatus>
                                        </Box>
                                      </TableCell>
                                      <CustomDetailTableCell>
                                        <DescriptionDetails data-test-id="descTestId">{this.toggleDescription(rowOrder.description)}<ReadMoreDetails data-test-id = "descModalId" onClick={()=>this.handleDescription(rowOrder.description)}>{this.handleMoreDetails(rowOrder.description)}</ReadMoreDetails>
                                        </DescriptionDetails>
                                      </CustomDetailTableCell>
                                      <CustomDetailTableCell data-test-id="dueTestId">{new Date(rowOrder.created_at).toLocaleDateString('en-GB')}</CustomDetailTableCell>
                                      <TableCell>
                                        <MoreArrow>
                                          <IconButton data-test-id="expandClickTestId" onClick={() => this.handleExpandClick(rowOrder.id)}>
                                            {this.state.expanded === rowOrder.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                          </IconButton>
                                          <IconButton data-test-id="menuIconId" onClick={(menus) => { this.handleMenuIconClick(menus, rowOrder.id) }}>
                                            <MoreVert />
                                          </IconButton>
                                          <Menu
                                            data-test-id="menuIconCloseId"
                                            anchorEl={this.state.anchorNewElement[rowOrder.id]}
                                            keepMounted
                                            open={Boolean(this.state.anchorNewElement[rowOrder.id])}
                                            onClose={() => this.handleMenuClose(rowOrder.id)}
                                            elevation={0}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right"
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "right"
                                            }}
                                          >
                                            <CustomNewMenuItem data-test-id="completedId" onClick={() => this.handleCompleted(rowOrder.id, "completed")}>
                                              <ListItemIcon>
                                                <img src={doneIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.completed} />
                                            </CustomNewMenuItem>
                                            <CustomNewMenuItem data-test-id = "vendorStatusId" onClick={()=>this.handleVendorStatus(rowOrder.id)}>
                                              <ListItemIcon>
                                                <img src={transferIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.transfer} />
                                            </CustomNewMenuItem>
                                            <CustomNewMenuItem data-test-id="openWorkId" onClick={()=>this.handleOpenWork(rowOrder.id, "OpenWorkOrder")}>
                                              <ListItemIcon>
                                                <img src={workIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText data-test-id="openWorksId" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.orderOpen} />
                                            </CustomNewMenuItem>
                                            <Divider />
                                            <CustomNewMenuItem data-test-id="udateWorkId" onClick={()=>this.handleOpenWork(rowOrder.id, "UpdateWorkOrder")}>
                                              <ListItemIcon>
                                                <img src={editIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText data-test-id="updateWorksId" primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.editWork} />
                                            </CustomNewMenuItem>
                                            <CustomNewMenuItem data-test-id="declineStatusId" onClick={()=>this.handleCompleted(rowOrder.id, "declined")}>
                                              <ListItemIcon>
                                                <img src={declineIcon} alt="editorIcon" />
                                              </ListItemIcon>
                                              <ListItemText primaryTypographyProps={{fontSize: "14px"}} primary={configJSON.decline} />
                                            </CustomNewMenuItem>
                                          </Menu>
                                        </MoreArrow>
                                      </TableCell>
                                    </TableRow>
                                    {this.state.expanded === rowOrder.id && (
                                      <TableRow>
                                        <TableCell colSpan={11}>
                                          <InnerTableBg>
                                            <OngoingName>
                                              {configJSON.goingOnName}
                                            </OngoingName>
                                            <Grid container spacing={2}>
                                              <Grid item xl={8} lg={8} sm={12} xs={12} zIndex={0}>
                                                <TableContainer  sx={{ maxHeight: 290 }}>
                                                  <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                      <TableRow>
                                                        {this.renderSortableTableCell("date", configJSON.dateName, workIndex)}
                                                        {this.renderSortableTableCell("updated_by", configJSON.updatedName, workIndex)}
                                                        <DetailsTableCell>{configJSON.detailsName}</DetailsTableCell>
                                                        {this.renderSortableTableCell("lowCost", configJSON.costName, workIndex)}
                                                        {this.renderSortableTableCell("status", configJSON.statusChangeName, workIndex)}
                                                      </TableRow>
                                                    </TableHead>
                                                    <CustomTableContainer>
                                                      {this.sortAuditData(rowOrder.work_order_audits, workIndex).map((update: Updates, updateIndex: number) => {
                                                        return (
                                                          <React.Fragment key={updateIndex}>
                                                            <TableRow className={this.handleAuditContainer(rowOrder.work_order_audits)}>
                                                              <StatusTableCell>{update.date}</StatusTableCell>
                                                              <StatusTableCell>{update.updated_by}</StatusTableCell>
                                                              <StatusTableCells>{this.toggleDescription(update.description)}<ReadMoreDetails data-test-id="unitDetailsId" onClick={()=>this.handleDescription(update.description)}>{this.handleMoreDetails(update.description)}</ReadMoreDetails></StatusTableCells>
                                                              <StatusTableCell>{update.invoice_cost ? '$'+update.invoice_cost : configJSON.lowCost}</StatusTableCell>
                                                              <StatusTableCell>{update.status}</StatusTableCell>
                                                            </TableRow>
                                                          </React.Fragment>
                                                        );
                                                      })}
                                                    </CustomTableContainer>
                                                    <TableRow className={this.handleEmptyUpdates(rowOrder.work_order_audits)}>
                                                      <TableCell colSpan={11} align="center">
                                                        {configJSON.auditMissing}
                                                      </TableCell>
                                                    </TableRow>
                                                  </Table>
                                                </TableContainer>
                                              </Grid>
                                              <Grid item xl={4} lg={4} sm={12} xs={12}>
                                                <Images>{configJSON.imagesUpload}</Images>
                                                <Grid container alignItems={"center"} justifyContent={"center"} spacing={1}>
                                                    {rowOrder.documents.map((documentItems: WorkDescriptions, docIndex: number) => {
                                                      return (
                                                        <Grid item xl={6} lg={6} sm={6} xs={6} key={docIndex} className={this.handleUpdateContainer(rowOrder.documents)}>
                                                          <BulkUploadingFiles>
                                                            <ImagesVideosFiles>
                                                              <ImageFiles src={documentItems.file_url} alt={`Image ${docIndex}`} />
                                                            </ImagesVideosFiles>
                                                          </BulkUploadingFiles>
                                                        </Grid>
                                                      );
                                                    })}
                                                  <Typography textAlign={"center"} mt={8} className={this.handleWorkEmpty(rowOrder.documents)}>
                                                    {configJSON.noImages}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </InnerTableBg>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </TableBody>
                            <TableRow className={this.handleEmptyContainer(unitDetails.landlord_work_orders)}>
                                  <TableCell colSpan={11} align="center">
                                    {configJSON.workOrderMissing}
                                  </TableCell>
                                </TableRow>
                          </Table>
                        </TableContainer>
                      </BorderContainer>
                    ))}
                  </React.Fragment>
                ))}
                    <CustomModal
                      data-test-id="openModalId"
                      open={this.state.openFinKey}
                    >
                      <ParagraphContainer>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"}>
                          <CloseModal onClick={this.handleModalClose}>
                            <Close />
                          </CloseModal>
                          <KeyContainer>
                            {this.state.findKeyDetails.map((findKey: Units, findIndex: number) => {
                              return (
                                <Box display={"flex"} flexDirection={"column"} gap={"10px"} key={findIndex}>
                                  <KeyHeading>
                                    {configJSON.keyLocatedMessage}{findKey.code}{configJSON.doorClose}
                                  </KeyHeading>
                                  <KeyLocation>{configJSON.keyLocation}</KeyLocation>
                                  <KeyPara data-test-id="findKeyDetailsId">{configJSON.floorName} {this.handleEmptyFindKeys(findKey.floor)}</KeyPara>
                                  <KeyPara>{configJSON.entrance} {this.handleEmptyFindKeys(findKey.entrance)}</KeyPara>
                                  <KeyPara>{configJSON.codeName}{this.handleEmptyFindKeys(findKey.code)}</KeyPara>
                                </Box>
                              )
                            })}
                            <Box>
                              <img src={keys} alt="findKey" />
                            </Box>
                          </KeyContainer>
                        </Box>
                      </ParagraphContainer>
                    </CustomModal>
                    <Menu
                      data-test-id="menuId"
                      id="property-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                      elevation={0}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      slotProps={{
                        paper: {
                          style: { width: "200px", height: "200px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginLeft: "-2rem", marginTop: "0.5rem" }
                        }
                      }}
                    >
                      {this.handlePropertyData().map((property: PropertiesDetails, propIndex: number) => (
                        <CustomMenuItem
                          data-test-id="propertyMenuId"
                          key={property.attributes.property_name}
                          onClick={(propevent) => this.handlePropertyClick(propevent, property, propIndex)}
                          className={this.handleChangeColor(propIndex)}
                        >
                          <CustomListItemText primaryTypographyProps={{ fontSize: "14px", whiteSpace: "nowrap",
                          overflow: "hidden", textOverflow: "ellipsis", width: "135px" }} primary={property.attributes.property_name} />
                          <KeyboardArrowRightIcon />
                        </CustomMenuItem>
                      ))}

                      {this.handlePropertySlice() && (
                        <CustomMenuItem
                          data-test-id="seeAllId"
                          key="seeAll"
                          onClick={this.toggleShowAll}
                        >
                          <CustomListColor primaryTypographyProps={{ fontSize: "14px" }} primary="See all" />
                        </CustomMenuItem>
                      )}
                    </Menu>
                    {this.state.selectedProperty && (
                      <Menu
                        data-test-id="menuItemId"
                        anchorEl={this.state.subMenuAnchorEl}
                        keepMounted
                        open={Boolean(this.state.subMenuAnchorEl)}
                        onClose={this.handleCloseMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        slotProps={{
                          paper: {
                            style: { width: "120px", marginLeft: "10px" }
                          }
                        }}
                      >
                        {this.state.selectedProperty.attributes.units && this.state.selectedProperty.attributes.units.length > 0 && (
                          this.state.selectedProperty.attributes.units.map((unit: Units) => (
                            <UnitMenuItem
                              data-test-id="unitMenuId"
                              key={unit.id}
                              onClick={() => this.handleUnitClick(unit.unit_name, unit.id)}
                            >
                              <CustomListItemText primaryTypographyProps={{
                                fontSize: "14px", whiteSpace: "nowrap",
                                overflow: "hidden", width: "100px", textOverflow: "ellipsis",
                              }} primary={unit.unit_name} />
                            </UnitMenuItem>
                          ))
                        )}
                      </Menu>
                    )}
                  </Box>
                  <Box className={this.handleHideDetails()}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={10} className={this.handleEmptyProperty(this.state.properties)}>
                      {configJSON.noProperty}
                    </Box>
                    </Box>
              </> 
            </WorkStyles>
          </MainWorkContainer>
          <Box className="bgImgWrapper" data-test-id="bgImgId">
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-one" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-two" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-three" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
            </Box>
          </Box>
        </WorkArea>
        <PopupMessage
          data-test-id="popupId"
          open={this.state.messagePopup}
          type={this.state.messageType}
          message={this.state.messageForPopup}
          handleClose={this.handlePopupClose}
        />
        <CustomModal
          data-test-id="vendorModalId"
          open={this.state.vendorModal}
        >
          <ParagraphContainer>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"} width={"100%"}>
              <CloseModal onClick={this.handleCloseVendor}>
                <Close />
              </CloseModal>
              <KeyContainer>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"} alignItems={"center"} width={"100%"}>
                  <KeyHeading>
                    {configJSON.selectVendor}
                  </KeyHeading>
                  <Button data-test-id="preVendorId" className="properties-dropdown" onClick={this.handleVendorClick}>{configJSON.preferredVendors}</Button>
                  <Menu
                    data-test-id="statusFilterId"
                    id="property-menu"
                    keepMounted
                    open={this.state.openNewVendors}
                    elevation={0}
                    anchorEl={this.state.anchorVendorEl}
                    onClose={this.handleCloseVendors}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    slotProps={{
                      paper: {
                        style: { overflow: "auto", width: "230px",height: "35%", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginTop: "0.5rem" }
                      }
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    {this.state.vendorsShowAll.map((vendor: PreferredVendor, statusIndex: number) => {
                      return (
                        <CustomMenuItem
                          key={statusIndex}
                          data-test-id="vendorsItem"
                          onClick={() => this.handleSelectedVendor(vendor.id)}
                        >
                          <CustomListItemText primaryTypographyProps={{ fontSize: "14px" }} primary={`${vendor.vendor_type} | ${vendor.name}`} />
                        </CustomMenuItem>
                      )
                    })}
                  </Menu>
                </Box>
              </KeyContainer>
            </Box>
          </ParagraphContainer>
        </CustomModal>
         <Loader loading={this.state.openLoader}/>
        <CustomModal
          data-test-id="DescModalId"
          open={this.state.openDescription}
        >
          <ParagraphContainer>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"} width={"100%"}>
              <CloseModal onClick={this.handleModalDescription}>
                <Close />
              </CloseModal>
              <KeyContainer>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                      <KeyHeading>
                        {this.state.modalDescription}
                      </KeyHeading>
                    </Box>
              </KeyContainer>
            </Box>
          </ParagraphContainer>
        </CustomModal>
      </WorkOrderStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    paddingBottom: "1.5rem"

  }
});

const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    fontFamily: "Outfit",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Outfit",
  color: "#000000",
  margin: "0px 5px 0px 5px",
  "@media(max-width: 475px)": {
    fontSize: "10px",
  },
  "&.changeColor": {
    backgroundColor: "#f3f2f0",
    fontFamily: "Outfit",
    borderRadius: "10px"
  }
});

const CustomListItemText = styled(ListItemText)({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Outfit",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});

const UnitMenuItem = styled(MenuItem)({
  fontWeight: 400,
  fontFamily: "Outfit",
  fontSize: "14px",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});

const CustomNewMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  color: "#000000",
  "&.changeColor": {
    backgroundColor: "rgb(250,227,162)",
    fontFamily: "Outfit",
    borderRadius: "10px"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  },
  "@media(max-width: 475px)": {
    minHeight: "25px !important"
  }
});

const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});

const WorkStyles = styled(Box)({
  width: "calc(100% - 30px)",
  paddingLeft: "30px",
  
 "@media (max-width: 992px)": {
   
   paddingLeft: "16px",
   paddingRight: "16px",
   width: "calc(100% - 32px)",
 },
  fontFamily: "Outfit",
  "& .search-main-content": {
    display: "flex",
    fontFamily: "Outfit",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    fontFamily: "Outfit",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%",
    fontFamily: "Outfit",
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    fontFamily: "Outfit",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "fit-content",
    gap: "10px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    fontFamily: "Outfit",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    fontFamily: "Outfit",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
    "@media(max-width: 992px)": {
    paddingRight: "0px",
    }
  },
  "& .filter": {
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
    fontFamily: "Outfit",
    paddingRight: "10px",
    "@media(max-width: 992px)": {
      paddingRight: "0px"
    }
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Outfit",
    whiteSpace: "nowrap",
    color: "#CC9200",
    cursor: "pointer",
    paddingLeft: "10px",
    "@media(max-width: 992px)": {
      paddingLeft: "0px"
    }
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const HeadName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
   '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadings = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontFamily: "Outfit",
  fontWeight: 400,
   '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItems = styled(Box)({
  display: "flex",
  fontFamily: "Outfit",
  gap: "10px"
});

const CompletedDetails = styled(Box)({
  display: "flex",
  gap: "30px",
  fontFamily: "Outfit",
  justifyContent: "flex-end",
  "@media(max-width: 460px)": {
    gap: "5px",
    width: "100%"
  }
});

const GridItem = styled(Grid)({
  width: "100%"
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  fontFamily: "Outfit",
  display: "flex",
  flexDirection: "column",
  "& .scrollable-container": {
    height: "300px",
    fontFamily: "Outfit",
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "-ms-overflow-style": "none",
  },
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "-ms-overflow-style": "none",
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const OrdersProgress = styled(Typography)({
  borderRadius: "40px",
  color: "#059669",
  fontFamily: "Outfit",
  fontSize: "12px",
  padding: "0px 8px !important",
  fontWeight: 700,
  textAlign: "center",
  whiteSpace: "nowrap",
  width: "fit-content",
  textTransform: "uppercase"
});

const OrderUnitContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  fontFamily: "Outfit",
  alignItems: "center",
  gap: "20px"
});

const OrderUnitName = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#000000"
});

const FindKeyName = styled(Typography)({
  color: "#CC9200",
  fontSize: "14px",
  fontFamily: "Outfit",
  fontWeight: 700,
  cursor: "pointer"
});

const SelectedOrder = styled(Typography)({
  color: "#A3978F",
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: 700
});

const ScheduledDate = styled(Typography)({
  color: "#CC9200",
  fontFamily: "Outfit",
  fontSize: "14px",
  fontWeight: 700
});

const ScheduledWaiting = styled(Box)({
  display: "flex",
  gap: "5px",
  alignItems: "center",
  fontFamily: "Outfit",
  borderRadius: "8px",
  justifyContent: "space-around",
  paddingLeft: "5px",
  paddingRight: "5px",
  cursor: "pointer",
  "@media(max-width: 992px)": {
    paddingLeft: "60px"
  }
});

const ApproveDetails = styled(Button)({
  textTransform: "none",
  color: "#000000",
  fontFamily: "Outfit",
  height: "30px",
  backgroundColor: "rgb(253,242,208)",
  display: "flex",
  alignItems: "center",
  marginRight: "-50px",
  borderRadius: "8px",
  padding: "5px 10px 5px 15px",
  gap: "4px",
  "&:hover": {
    color: "#000000",
    backgroundColor: "rgb(253,242,208)"
  }
});

const ApproveName = styled(Box)({
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#000000",
  whiteSpace: "nowrap"
});

const SelectedCounts = styled(Box)({
  display: "flex",
  fontFamily: "Outfit",
  "@media(max-width: 900px)": {
    display: "none"
  }
});

const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  fontFamily: "Outfit",
  gap: "5px",
  alignItems: "center",
  borderRadius: "8px",
  cursor: "pointer",
  "@media(max-width: 900px)": {
    display: "none"
  }
});

const ScheduledFindWaiting = styled(Box)({
  display: "none",
  fontFamily: "Outfit",
  "@media(max-width: 900px)": {
    display: "flex",
    gap: "5px",
    cursor: "pointer",
    alignItems: "center",
    borderRadius: "8px"
  }
});

const CustomGrid = styled(Grid)({
  display: "flex",
  fontFamily: "Outfit",
  justifyContent: "flex-end",
  gap: 1,
  "@media(max-width: 900px)": {
    justifyContent: "flex-start",
    gap: "10px"
  }
});

const CustomApproveGrid = styled(Grid)({
  display: "flex",
  fontFamily: "Outfit",
  justifyContent: "space-between",
  alignItems: "center",
  "@media(max-width: 900px)": {
    gap: "10px",
    justifyContent: "flex-start"
  }
});

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap"
});

const DetailsTableCell = styled(TableCell)({
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "center",
  fontFamily: "Outfit",
  fontWeight: 700,
  whiteSpace: "nowrap"
});

const CustomDetailTableCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily: "Outfit",
  color: "#A3978F",
  fontWeight: 400,
  textAlign: "center"
});

const CustomDetailTableCellId = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  fontFamily: "Outfit",
  fontWeight: 400,
  textAlign: "left"
});

const CustomDetailTextCell = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  textAlign: "center",
  fontFamily: "Outfit",
  fontWeight: 400,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
});

const MoreArrow = styled(Box)({
  display: "flex"
});

const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  fontSize: "9px",
  textAlign: "center",
  whiteSpace: "nowrap",
  fontFamily: "Outfit",
  padding: "10px 20px",
  width: "fit-content",
  fontWeight: 700
});

const DescriptionDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#A3978F",
  fontFamily: "Outfit",
  wordBreak: "break-word",
  cursor: "pointer",
  "&::first-letter": {
    textTransform: "capitalize",
  },
});

const InnerTableBg = styled(Box)({
  border: "1px solid #F9FAFC",
  borderRadius: "10px",
  backgroundColor: "#F9FAFC",
  fontFamily: "Outfit",
  padding: "20px 0px 20px 20px"
});

const StatusTableCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Outfit",
  color: "#000000",
  textAlign: "center",
   "&::first-letter": {
    textTransform: "capitalize",
  },
});

const OngoingName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Outfit",
  color: "#1C1917"
});

const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  fontFamily: "Outfit",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});

const Images = styled(Typography)({
  textAlign: "left",
  fontSize: "16px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#1C1917"
});

const CustomListColor = styled(ListItemText)({
  color: "#CC9200",
  fontSize: "14px !important",
  fontFamily: "Outfit",
  fontWeight: 400
});

const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const BulkUploadingFiles = styled(Grid)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  flexDirection: "column",
  paddingTop: "20px",
  alignItems: "center",
  gap:"10px"
});

const ImagesVideosFiles = styled(Box)({
  height: "100px",
  width: "100px"
});

const ImageFiles = styled('img')({
  height: "100%",
  width: "100%",
  objectFit: "contain"
});

const CustomModal = styled(Modal)({
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});

const ParagraphContainer = styled(Box)({
  backgroundColor: "white",
  width: "30%",
  fontFamily: "Outfit",
  height: "250px",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  padding: "25px 20px 80px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
   "@media(max-width: 1260px)": {
    width: "50%"
  },
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px",
    height: "290px"
  },
  "@media(max-width: 475px)": {
    gap: "12px",
    height: "345px"
  },
  "@media(max-width: 466px)": {
    gap: "10px",
    height: "390px"
  },
  "@media(max-width: 400px)": {
    gap: "10px",
    height: "400px"
  }
});

const KeyHeading = styled(Typography)({
  color: "#CC9200",
  fontWeight: 700,
  fontFamily: "Outfit",
  fontSize: "20px",
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const KeyLocation = styled(Typography)({
  color: "#0F172A",
  fontFamily: "Outfit",
  fontSize: "18px",
  fontWeight: 700
});

const KeyPara = styled(Typography)({
  fontFamily: "Outfit",
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 400
});

const KeyContainer = styled(Box)({
  fontFamily: "Outfit",
  display: "flex",
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  },
  "& .properties-dropdown": {
    width: "142px",
    fontFamily: "Outfit",
    justifyContent: "space-around",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    cursor: "pointer",
    color: "#000000",
    display: "flex",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      width: "fit-content",
      height: "30px",
      fontSize: "12px",
    }
  },
});

const CloseModal = styled(Box)({
  fontFamily: "Outfit",
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer"
});

const StatusTableCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
  whiteSpace: "nowrap"
});

const FilterChips = styled(Box)({
width: "fit-content",
display: "flex",
border: "1px solid #000000",
fontFamily: "Outfit",
borderRadius: "4px",
padding: "1px 6px 1px 2px",
alignItems: "center",
gap: "5px",
height: "fit-content",
whiteSpace: "nowrap"
});

const FilterDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 700,
  color: "#544B45",
  fontFamily: "Outfit"
});

const FilterClose = styled(Close)({
  fontFamily: "Outfit",
  color: "#544B45",
  cursor: "pointer"
});

const FilterContainer = styled(Box)({
  fontFamily: "Outfit",
  width: "100%",
  padding: "10px 10px 10px 10px",
  overflow: "auto",
  display: "flex",
  gap: "1rem",
  "@media(max-width: 480px)": {
  flexWrap: "wrap"
  },
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
});

const FilterWeb = styled(Box)({
  width: "90%",
  fontFamily: "Outfit",
  paddingRight: "20px",
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const FilterMobile = styled(Box)({
  fontFamily: "Outfit",
  display: "none",
  "@media(max-width: 992px)": {
    display: "block",
    width: "93%",
    paddingLeft: "16px"
  }
});

const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});

const WorkArea = styled(Box)({
  marginLeft: "120px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "&::-webkit-scrollbar": {
    display: "none"
  },
   "scrollbar-width": "none",
   "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});

const ReadMoreDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 700,
  color: "#CC9200",
  fontFamily: "Outfit",
  textDecoration: "underline",
  wordBreak: "break-word",
  cursor: "pointer",
  "&::first-letter": {
    textTransform: "capitalize",
  },
});
// Customizable Area End
