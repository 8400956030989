import React from "react";

// Customizable Area Start
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';


// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { termsConds } = this.state;
    return (
      <TermsStyling>
        <Box className="imageBackground" />
        <Box className="logo-container">
            <img
              className="image_image_keasylogo_1426934368"
              src={require('../assets/1426934368KmTObgfmr1gx8VdhoxvtGF.svg')}
              alt="Keasy Logo"
            />
              <img
              className="image_image_keasyfont_1426934369"
              src={require('../assets/1426934369KmTObgfmr1gx8VdhoxvtGF.svg')}
              alt="Keasy Font"
            />
        </Box>
        <Box className='title-container'>
          <Typography className="title">{this.getTitle()}</Typography>
        </Box>

        <Box className='description' > 
        <div
          className="text_textlabel_description_1426934372"
          dangerouslySetInnerHTML={{
          __html: this.getDescription(termsConds?.data)
        }}
        />
        </Box>
        <Box className='close'>
         <Button sx={{marginBottom: "24px"}} onClick={()=>this.navigateBackToSignup()} className="close_button">Close</Button>
        </Box>
      </TermsStyling>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TermsStyling = styled(Box)({
  backgroundColor: '#ffffffff',
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  "@media(max-width: 768px)": {
    height: 'auto',
    padding: '1rem',
  },
  "& .imageBackground": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${require('../assets/1426934416KmTObgfmr1gx8VdhoxvtGF.svg')})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "@media(max-width: 1440px)": {
      width: "103%",
      height: "143%",
    },
    "@media(max-width: 768px)": {
      width: "142%",
      height: '49%',
      marginTop:"31rem"
    },
  },
  "& .logo-container": {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '1rem',
    alignItems: 'center',
    flex: 0.5,
    "@media(max-width: 768px)": {
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },
  "& .image_image_keasylogo_1426934368": {
    width: '2.6%',
    "@media(max-width: 768px)": {
      width: '10%',
    },
  },
  "& .image_image_keasyfont_1426934369": {
    width: '10.4%',
    "@media(max-width: 768px)": {
      width: '40%',
    },
  },
  "& .title": {
    fontFamily: 'Outfit',
    fontWeight: 700,
    fontSize: 24,
    color: '#000000',
    textAlign: "center",
    "@media(max-width: 768px)": {
      fontSize: 20,
      marginTop: "2rem",
    },
  },
  "& .description": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '2rem',
    zIndex:'10',
    "@media(max-width: 768px)": {
      marginTop: '1rem',
      width:"95%",
    },
  },
  "& .text_textlabel_description_1426934372": {
    width: 690,
    fontFamily: 'Outfit',
    fontWeight: 400,
    fontSize: 20,
    color: '#544b45',
    flex: 2,
    "@media(max-width: 768px)": {
      width: '100%',
      fontSize: 16,
    },
  },
  "& .close":{
    height:"10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"1.5rem",
    },
    "& .close_button":{
      backgroundColor: '#FFC123',
      borderRadious:"8",
      fontFamily: 'Outfit',
      fontWeight: 700,
      fontSize: 18,
      color:"#000000",
      height:"53%",
      width:"15%",
      '&:hover': {
        backgroundColor: '#FFC123', 
      },
      "@media(max-width: 768px)": {
        width:"55%",
        height:"90%",
        
      },
      }
});
// Customizable Area End
